<template>
  <div>
    <nav-bar-pref></nav-bar-pref>
    <b-container class="upload-publication-main">
      <div :class="loading ? 'show' : 'hide'">
        <loader
          object="#ff9633"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#cccccc"
          objectbg="#999793"
          opacity="80"
          name="dots"
        ></loader>
      </div>
      <link
        rel="stylesheet"
        href="../../node_modules/@fortawesome/fontawesome-free/css/all.css"
      />
      <div class="new-diribas-title col-md-12">
        <h1 v-if="diribasId">Edição De Publicação - DIRIBAS</h1>
        <h1 v-else>Envio De Publicações - DIRIBAS</h1>
      </div>
      <b-form @submit.prevent="send" class="form-diribas">
        <b-form-group label-for="file">
          <div class="form-wrapp">
            <div class="col-md-12" style="padding: 0">
              <span class="required-mark">* </span
              ><label class="form-label">Departamento</label>
              <b-form-select
                required
                @change="resetSub"
                class="diribas-form-field-upload diribas-form-field-shadow"
                v-model="selectedDep"
              >
                <b-form-select-option
                  v-for="department in departments"
                  :key="department.id"
                  :value="department"
                  >{{ department.name }}</b-form-select-option
                >
              </b-form-select>
            </div>
          </div>
          <div
            class="form-wrapp"
            v-if="
              selectedDep.sub_department &&
              selectedDep.sub_department.length > 0
            "
          >
            <div class="col-md-10" style="padding: 0">
              <label class="form-label">Subdepartamento (se necessário)</label>
              <b-form-select
                @change="setDisabled"
                class="diribas-form-field-upload diribas-form-field-shadow"
                id="subDepartment"
                v-model="selectedSubDep"
              >
                <b-form-select-option
                  v-for="subDepartment in selectedDep.sub_department"
                  :key="subDepartment.id"
                  :value="subDepartment"
                  >{{ subDepartment.name }}</b-form-select-option
                >
              </b-form-select>
            </div>
            <div class="unmark col-md-2" style="padding: 0">
              <b-button
                :disabled="buttonDisabled"
                @click="unmark"
                variant="outline-danger"
                >Desmarcar</b-button
              >
            </div>
          </div>
          <div class="form-wrapp">
            <div class="col-md-12" style="padding: 0">
              <span class="required-mark">* </span
              ><label class="form-label">Título da publicação</label>
              <b-form-input
                class="diribas-form-field-upload diribas-form-field-shadow"
                id="title"
                required
                v-model="publication.title"
              ></b-form-input>
            </div>
          </div>
          <div class="col-md-12 sticky-btn">
            <div class="editor-buttons">
              <div>
                <b-button
                  text
                  icon
                  @click="editor.chain().focus().toggleBold().run()"
                  :class="{ 'is-active': editor.isActive('bold') }"
                  v-b-tooltip.hover
                  title="Negrito"
                >
                  <i class="fas fa-bold"></i>
                </b-button>
                <b-button
                  text
                  icon
                  @click="editor.chain().focus().toggleItalic().run()"
                  :class="{ 'is-active': editor.isActive('italic') }"
                  v-b-tooltip.hover
                  title="Itálico"
                >
                  <i class="fas fa-italic"></i>
                </b-button>
                <b-button
                  text
                  icon
                  @click="editor.chain().focus().toggleUnderline().run()"
                  :class="{ 'is-active': editor.isActive('underline') }"
                  v-b-tooltip.hover
                  title="Sublinhado"
                >
                  <i class="fas fa-underline"></i>
                </b-button>
                <b-button
                  @click="editor.chain().focus().setTextAlign('left').run()"
                  :class="{
                    'is-active': editor.isActive({ textAlign: 'left' }),
                  }"
                  v-b-tooltip.hover
                  title="Alinhado à esquerda"
                >
                  <i class="fas fa-align-left"></i>
                </b-button>
                <b-button
                  @click="editor.chain().focus().setTextAlign('center').run()"
                  :class="{
                    'is-active': editor.isActive({ textAlign: 'center' }),
                  }"
                  v-b-tooltip.hover
                  title="Centralizado"
                >
                  <i class="fas fa-align-center"></i>
                </b-button>
                <b-button
                  @click="editor.chain().focus().setTextAlign('right').run()"
                  :class="{
                    'is-active': editor.isActive({ textAlign: 'right' }),
                  }"
                  v-b-tooltip.hover
                  title="Alinhado à direita"
                >
                  <i class="fas fa-align-right"></i>
                </b-button>
                <b-button
                  @click="editor.chain().focus().setTextAlign('justify').run()"
                  :class="{
                    'is-active': editor.isActive({ textAlign: 'justify' }),
                  }"
                  v-b-tooltip.hover
                  title="Justificado"
                >
                  <i class="fas fa-align-justify"></i>
                </b-button>
                <b-button
                  text
                  icon
                  @click="openImgModal()"
                  v-b-tooltip.hover
                  title="Inserir imagem"
                >
                  <i class="far fa-file-image"></i>
                </b-button>
                <b-button
                  class="menubar__button"
                  @click="editor.chain().focus().toggleBulletList().run()"
                  :class="{ 'is-active': editor.isActive('bulletList') }"
                  v-b-tooltip.hover
                  title="Lista não ordenada"
                >
                  <i class="fas fa-list-ul"></i>
                </b-button>
                <b-button
                  class="menubar__button"
                  @click="editor.chain().focus().toggleOrderedList().run()"
                  :class="{ 'is-active': editor.isActive('orderedList') }"
                  v-b-tooltip.hover
                  title="Lista ordenada"
                >
                  <i class="fas fa-list-ol"></i>
                </b-button>
                <b-button
                  class="menubar__button"
                  @click="editor.chain().focus().undo().run()"
                  v-b-tooltip.hover
                  title="Desfazer"
                >
                  <i class="fas fa-undo"></i>
                </b-button>

                <b-button
                  class="menubar__button"
                  @click="editor.chain().focus().redo().run()"
                  v-b-tooltip.hover
                  title="Refazer"
                >
                  <i class="fas fa-redo"></i>
                </b-button>

                <b-button
                  text
                  icon
                  @click="openUploadModal()"
                  v-b-tooltip.hover
                  title="Inserir PDF"
                >
                  <i class="far fa-file-pdf"></i>
                </b-button>
              </div>
            </div>
          </div>
          <div>
            <b-col class="diribas-form-field-shadow" style="padding: 0">
              <editor-content class="editor-box" :editor="editor" />
            </b-col>
          </div>
          <div class="upload-buttons-diribas col-md-12">
            <div>
              <b-button
                style="margin-left: 0"
                class="preview-button"
                @click="goBack"
                :disabled="loading"
                variant="outline-info"
                ><i class="fas fa-arrow-left"></i>Voltar</b-button
              >
            </div>
            <div>
              <b-button
                class="preview-button"
                @click="pagePreview = true"
                :disabled="loading"
                type="submit"
                variant="secondary"
                ><i class="fas fa-eye"></i>Prévia</b-button
              >
              <b-button
                @click="pagePreview = false"
                :disabled="loading"
                type="submit"
                variant="primary"
                ><i class="fas fa-paper-plane"></i>Enviar Publicação</b-button
              >
            </div>
          </div>
        </b-form-group>
      </b-form>
      <b-modal
        ref="response-modal"
        footer-bg-variant="light"
        ok-only
        title="Sessão Expirada"
        @hide="emitUnauthorized"
      >
        Efetue login novamente
      </b-modal>
      <b-modal
        ref="doc-modal"
        footer-bg-variant="light"
        ok-only
        title="Arraste um documento"
        @ok="addDoc"
      >
        <b-form-file
          v-model="file"
          placeholder="Arraste ou escolha o documento"
        >
        </b-form-file>
      </b-modal>
      <b-modal
        ref="img-modal"
        footer-bg-variant="light"
        ok-only
        title="Arraste uma imagem"
        @ok="addImage"
      >
        <b-form-file
          v-model="bodyImg"
          placeholder="Arraste ou escolha uma imagem"
        >
        </b-form-file>
      </b-modal>
      <b-modal
        ref="feedback-modal"
        footer-bg-variant="light"
        ok-only
        :title="modalTitle"
      >
        {{ modalText }}
      </b-modal>
    </b-container>
    <footer-pref></footer-pref>
    <b-modal
      scrollable
      size="xl"
      ref="preview-modal"
      footer-bg-variant="light"
      :hide-footer="true"
      title="Pré-visualização"
    >
      <render-preview :publication="previewPublication"></render-preview>
    </b-modal>
    <b-modal
      ref="confirm-modal"
      cancel-title="Cancelar"
      ok-title="Confirmar"
      ok-variant="primary"
      cancel-variant="outline-secondary"
      title="Confirme a operação"
      footer-bg-variant="light"
      @ok="publish"
    >
      <div class="modal-text">
        <span>Deseja enviar a publicação?</span>
      </div>
    </b-modal>
    <b-modal
      ref="response-modal"
      footer-bg-variant="light"
      ok-only
      title="Sessão Expirada"
      @hide="emitUnauthorized"
    >
      Efetue login novamente
    </b-modal>
    <b-modal
      centered
      ref="error-modal"
      footer-bg-variant="light"
      ok-only
      ok-variant="danger"
      title="Um problema ocorreu"
    >
      {{ errorMessage }}
    </b-modal>
  </div>
</template>
<script>
import "vue2-datepicker/locale/pt-br";
import "vue2-datepicker/index.css";
import FooterPref from "../../components/FooterPref.vue";
import NavBarPref from "../../components/NavBarPref.vue";
import RenderPreview from "../../components/RenderPreview.vue";
import Upload from "../../services/UploadService";
import { Editor, EditorContent } from "@tiptap/vue-2";
import Link from "@tiptap/extension-link";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import DepartmentService from "../../services/DepartmentService";
import UserService from "../../services/UserService";
import DiribasService from "../../services/DiribasService";
import UploadService from "../../services/UploadService";
import TextAlign from "@tiptap/extension-text-align";
import AuthenticationService from "../../services/AuthenticationService";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";

const pdfjsLib = require("pdfjs-dist/legacy/build/pdf.js");
const pdfjsWorker = require("pdfjs-dist/build/pdf.worker.entry");
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
    };
  },
});

export default {
  data() {
    return {
      pages: "",
      text: "",
      file: "",
      base: "",
      editor: "",
      departments: [],
      selectedDep: "",
      selectedSubDep: "",
      user: "",
      publication: {
        title: "",
        text: "",
        department_id: "",
        sub_department_id: null,
        user_id: "",
        supplement: false,
        published: false,
      },
      previewPublication: {
        title: "",
        text: "",
        department: "",
        sub_department_id: null,
        user_id: "",
      },
      modalTitle: "",
      modalText: "",
      bodyImg: "",
      bodyImgs: [],
      attachs: [],
      loading: false,
      pagePreview: false,
      errorMessage: "",
      diribasId: "",
      buttonDisabled: true,
    };
  },
  components: {
    NavBarPref,
    FooterPref,
    EditorContent,
    RenderPreview,
  },
  async beforeCreate() {
    try {
      const validLogin = await AuthenticationService.validateLogin();
      console.log(validLogin);
    } catch (error) {
      if (error.response.status == 401) {
        this.$refs["response-modal"].show();
      }
    }
  },
  created() {
    this.diribasId = this.$route.params.id;
    UserService.listById(localStorage.getItem("userId")).then((user) => {
      this.user = user.data;
      if (this.diribasId) {
        DiribasService.listPublicationById(this.diribasId).then((res) => {
          this.publication = res.data;
          this.previewPublication = this.publication;
          this.selectedDep = this.publication.department;
          this.selectedSubDep = this.publication.sub_department;
          this.editor.commands.setContent(res.data.text);
        });
      }
      DepartmentService.listByUserId(null).then((res) => {
        this.departments = res.data;
        if (this.departments.length == 1)
          this.selectedDep = this.departments[0];
      });
    });
  },
  mounted() {
    this.editor = new Editor({
      content: ``,
      extensions: [
        StarterKit,
        Link.configure({
          openOnClick: false,
        }),
        Image,
        Underline,
        Placeholder.configure({
          placeholder: "Insira a publicação",
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        Table,
        TableRow,
        TableHeader,
        CustomTableCell,
      ],
    });
  },
  methods: {
    renderPDF(url) {
      pdfjsLib.disableWorker = true;
      const task = pdfjsLib.getDocument(url);
      task.promise.then((doc) => {
        this.renderPages(doc);
      });
    },
    async renderPages(pdfDoc) {
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        let page = await pdfDoc.getPage(i);
        await this.renderPage(page);
      }
    },
    async renderPage(page) {
      let key = this.file.name + page.pageNumber;
      let viewport = page.getViewport({ scale: 4 });
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };

      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render(renderContext).promise;
      let res = await UploadService.storeDiribasBase64(canvas.toDataURL(), key);

      this.editor.chain().focus().setImage({ src: res.data.url }).run();
      this.editor.commands.createParagraphNear();
      this.bodyImgs.push(res.data.id);
    },
    send() {
      this.pagePreview ? this.preview() : this.confirmPublish();
    },
    async publish() {
      try {
        this.loading = true;
        this.publication.user_id = this.user.id;
        this.publication.department_id = this.selectedDep.id;
        this.publication.sub_department_id = this.selectedSubDep
          ? this.selectedSubDep.id
          : null;
        this.publication.text = this.editor.getHTML();
        const dup = this.diribasId ? '' : await DiribasService.verifyDup(this.publication);
        if (dup.data) {
          this.modalTitle = "Ops, a publicação não pode ser salva";
          this.modalText = "Uma publicação com este título já foi cadastrada";
          this.$refs["feedback-modal"].show();
          this.loading = false
        }
        if (!dup.data) {
          const storedPublication = this.diribasId
            ? await DiribasService.updatePublication(this.publication)
            : await DiribasService.storePublication(this.publication);
          this.modalTitle = "Sucesso";
          this.modalText = "Sua publicação foi salva com sucesso.";
          for await (const id of this.bodyImgs) {
            await DiribasService.updateAttachId(id, storedPublication.data.id);
          }
          this.loading = false;
          if (!this.diribasId) this.clearForm();
          this.$refs["feedback-modal"].show();
        }
      } catch (error) {
        this.loading = false;
        this.modalTitle = "Erro ao salvar publicação";
        this.modalText = "Um problema ocorreu ao salvar sua publicação.";
        this.$refs["feedback-modal"].show();
        console.log(error);
      }
    },
    preview(e) {
      if (!this.selectedDep.id) {
        e.preventDefault();
      }
      this.previewPublication = this.publication;
      this.previewPublication.department = this.selectedDep;
      this.previewPublication.sub_department = this.selectedSubDep;
      this.previewPublication.text = this.editor.getHTML();
      this.$refs["preview-modal"].show();
    },
    confirmPublish() {
      this.$refs["confirm-modal"].show();
    },
    addImage() {
      if (!this.bodyImg) return;
      const formData = new FormData();
      formData.append("file", this.bodyImg);
      Upload.storeDiribasAttach(formData).then((res) => {
        const src = res.data.url;
        this.bodyImgs.push(res.data.id);
        if (src !== null) {
          this.editor.chain().focus().setImage({ src }).run();
        }
      });
    },
    updateAttachId(id) {
      return new Promise((resolve, reject) => {
        DiribasService.updateAttachId(id)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    clearForm() {
      this.selectedDep = "";
      this.selectedSubDep = "";
      this.publication.title = "";
      this.editor.commands.setContent("");
    },
    openUploadModal() {
      this.$refs["doc-modal"].show();
    },
    openImgModal() {
      this.$refs["img-modal"].show();
    },
    addDoc() {
      if (!this.file) return;
      this.loading = true;
      const formData = new FormData();
      formData.append("file", this.file);
      Upload.storeDiribasAttach(formData)
        .then((res) => {
          this.attachs.push(res.data.id);
          this.renderPDF(res.data.url);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.message);
          this.loading = false;
          this.errorMessage = "Não foi possível realizar o upload do arquivo";
          this.$refs["error-modal"].show();
        });
    },
    emitUnauthorized() {
      this.$root.$emit("unauthorized");
    },
    goBack() {
      this.$router.go(-1);
    },
    resetSub() {
      this.selectedSubDep = null;
    },
    setDisabled() {
      this.buttonDisabled = false;
    },
    unmark() {
      this.buttonDisabled = true;
      this.selectedSubDep = "";
    },
  },
};
</script>
<style lang="scss">
.upload-publication-main {
  h2,
  h3,
  h4 {
    font-size: 16px;
  }
  .new-diribas-title {
    padding-left: 0;
    text-align: initial;
    font-family: Ubuntu;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
  min-height: 100vh;
  :disabled {
    cursor: not-allowed;
  }
  .sticky-btn {
    position: sticky;
    top: 5px;
    z-index: 1;
    text-align: initial;
    padding: 0;
    .editor-buttons {
      margin-top: 20px;
      margin-bottom: 3px;
      button {
        margin-right: 1px;
      }
    }
  }
  .form-diribas {
    .diribas-form-field-upload {
      margin-top: 0px;
    }
    .unmark {
      text-align: end;
    }
    .diribas-form-field-shadow {
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
    .form-wrapp {
      display: flex;
      margin-bottom: 20px;
      align-items: flex-end;
      text-align: initial;
      .required-mark {
        color: #fc2c03;
      }
      .form-label {
        font-weight: bold;
        margin-bottom: 0;
        font-size: 15px;
        color: #929292;
      }
    }

    .editor-box > * {
      border-color: #ced4da;
      border-style: solid;
      border-width: 1px;
      text-align: initial;
      word-break: break-word;
      padding-left: 10px;
      padding-right: 10px;
      min-height: 300px;
      border-radius: 5px;
      line-height: normal;
      text-align: justify;
      p.is-editor-empty:first-child::before {
        color: #adb5bd;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
      }
      p {
        margin-bottom: 0;
        // height: 18px;
      }
      .ProseMirror {
        padding: 5px;
      }
      img {
        display: block;
        margin-bottom: 30px;
        // max-height: 600px;
        margin: auto;
        max-width: 100%;
        margin-bottom: 5px;
        width: inherit;
        @media (max-width: 767px) {
          max-height: 300px;
        }
      }
      table {
        border-collapse: collapse;
        width: 100%;
        margin: 0;
        overflow: hidden;
        td,
        th {
          min-width: 1em;
          border: 2px solid #ced4da;
          padding: 3px 5px;
          vertical-align: top;
          box-sizing: border-box;
          position: relative;

          > * {
            margin-bottom: 0;
          }
        }
        th {
          font-weight: bold;
          text-align: left;
          background-color: #f1f3f5;
        }
        .selectedCell:after {
          z-index: 2;
          position: absolute;
          content: "";
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(200, 200, 255, 0.4);
          pointer-events: none;
        }
        .column-resize-handle {
          position: absolute;
          right: -2px;
          top: 0;
          bottom: -2px;
          width: 4px;
          background-color: #adf;
          pointer-events: none;
        }
        p {
          margin: 0;
        }
      }
    }
    .tableWrapper {
      overflow-x: auto;
    }
    .upload-buttons-diribas {
      padding: 0;
      text-align: right;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>