import 'core-js';
import { http } from './config'

export default {
    store: (log) => {
        return http.post('edict-logs', {
            ...log
        });
    },

    list: () => {
        return http.get('edict-logs');
    },

    countAll: () => {
        return http.get('edict-logs-count');
    },

    listForTable: () => {
        return http.get('edict-logs-report');
    },

    exportReport: (publicationId) => {
        return http.get(`edict-logs-export-report/${publicationId}`);
    },

    exportGeneralReport: () => {
        return http.get(`edict-logs-export-report-general`);
    },

    update: () => {

    },

    delete: () => {

    }

}