import 'core-js';
import { http } from './config'

export default {
    store:(type) => {
        return http.post('user-types', {
            type
        });
    },

    list:() => {
        return http.get('user-types');
    },

    update:() => {

    },

    delete:() => {
        
    }

}