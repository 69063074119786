<template>
  <div>
    <sidebar-menu
      @toggle-collapse="onToggleCollapse"
      :collapsed="true"
      :menu="menu"
      @item-click="onItemClick"
    />
  </div>
</template>
<script>
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import UserService from "../services/UserService";
export default {
  name: "SidebarDashboard",
  data() {
    return {
      user: "",
      menu: [
        {
          header: true,
          title: "Menu de Navegação",
          hiddenOnCollapse: true,
        },
        {
          link: "/dashboard",
          title: "Dashboard",
          icon: "fas fa-home",
          home: true,
        },
        {
          link: "http://45.174.220.245:8056/sipweb/trabalhador/login/login.xhtml",
          title: "Holerite Web",
          icon: "fas fa-receipt",
        },
        {
          link: "/ouvidoria",
          title: "Ouvidoria",
          icon: "fas fa-bullhorn",
        },
        {
          link: "http://45.174.220.245:8079/transparencia",
          title: "Transparência",
          icon: "fas fa-search-dollar",
        },
        {
          link: "/diribas",
          title: "Diário Oficial",
          icon: "far fa-newspaper",
        },
        {
          link: "/",
          title: "Site Prefeitura",
          icon: "fas fa-globe",
        },
        {
          link: "/dashboard/galerias",
          title: "Galerias",
          icon: "far fa-image",
          home: true,
          hidden: true,
        },
        {
          link: "/dashboard/sugestoes",
          title: "Sugestões",
          icon: "fas fa-comment",
          home: true,
          hidden: true,
        },
        {
          link: "/",
          sub: true,
          title: "Relatórios",
          icon: "fas fa-folder-open",
          hidden: true,
          child: [
            {
              hidden: true,
              href: "/dashboard/relatorios/editais",
              title: "Editais",
            },
          ],
        },
        {
          link: "/",
          sub: true,
          title: "Configuração",
          icon: "fas fa-cogs",
          hidden: true,
          child: [
            {
              hidden: true,
              href: "/dashboard/configuracao/noticias",
              title: "Notícias",
            },
            {
              hidden: true,
              href: "/dashboard/tags",
              title: "Tags",
            },
            {
              hidden: true,
              href: "/dashboard/users",
              title: "Usuários",
            },
            {
              hidden: true,
              href: "/dashboard/departments",
              title: "Departamentos",
            },
            {
              hidden: true,
              href: "/dashboard/sub-departments",
              title: "Subdepartamentos",
            },
            {
              hidden: true,
              href: "/dashboard/permissions",
              title: "Permissões",
            },
            {
              hidden: true,
              href: "/dashboard/configuracao/diribas",
              title: "Avisos Diribas",
            },
            {
              hidden: true,
              href: "/dashboard/configuracao/datas-diribas",
              title: "Feriados Diribas",
            }
          ],
        },
      ],
    };
  },
  components: {
    SidebarMenu,
  },
  created() {
    UserService.listById(localStorage.getItem("userId")).then((res) => {
      this.user = res.data;
      this.verifyPermissions("Relatório-edital", "Relatórios", "Editais");
      this.verifyPermissions(
        "Configuração-notícia",
        "Configuração",
        "Notícias"
      );
      this.verifyPermissions(
        "Configuração-notícia",
        "Configuração",
        "Notícias"
      );
      this.verifyPermissions("Configuração-tag", "Configuração", "Tags");
      this.verifyPermissions(
        "Configuração-usuário",
        "Configuração",
        "Usuários"
      );
      this.verifyPermissions(
        "Configuração-departamento",
        "Configuração",
        "Departamentos"
      );
      this.verifyPermissions(
        "Configuração-subdepartamento",
        "Configuração",
        "Subdepartamentos"
      );
      this.verifyPermissions(
        "Configuração-permissao",
        "Configuração",
        "Permissões"
      );
      this.verifyPermissions("Galerias", "Galerias");
      this.verifyPermissions("Sugestoes", "Sugestões");
      this.verifyPermissions(
        "Avisos Diribas",
        "Configuração",
        "Avisos Diribas"
      );
      this.verifyPermissions(
        "Feriados Diribas",
        "Configuração",
        "Feriados Diribas"
      );
    });
  },
  methods: {
    onToggleCollapse() {
      this.$root.$emit("collapse", {});
    },
    verifyPermissions(permission, mainTitle, childTitle = null) {
      const edictReport = this.user.permissions.findIndex(
        (perm) => perm.name == permission
      );
      if (this.user.role.name == "Admin" || edictReport != -1) {
        const menuIndex = this.menu.findIndex(
          (item) => item.title == mainTitle
        );
        if (childTitle) {
          const childIndex = this.menu[menuIndex].child.findIndex(
            (item) => item.title == childTitle
          );
          this.menu[menuIndex].child[childIndex].hidden = false;
        }
        this.menu[menuIndex].hidden = false;
      }
    },
    onItemClick(event, item) {
      if (item.sub) return;
      if (!item.home) {
        window.open(item.link, "_blank");
      } else {
        this.$router.push({ path: item.link });
      }
    },
  },
};
</script>
<style lang="scss">
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1:hover .vsm--icon {
  background-color: transparent;
}
.v-sidebar-menu {
  background-color: #204991;
  top: 80px;
  .vsm--item {
    a {
      &:hover {
        background-color: #001a79;
      }
    }
    .vsm--icon {
      font-size: 20px;
      background-color: transparent;
    }
    .vsm--title {
      font-size: 20px;
    }
    .vsm--list {
      background-color: #245bbb;
    }
  }
  .vsm--mobile-bg {
    background-color: #001a79;
  }
}
</style>