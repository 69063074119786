import 'core-js';
import { http } from './config'

export default {

    list:() => {
        return http.get('publication-config');
    },

    listByCardNumber:(number) => {
        return http.get(`publication-config-number/${number}`);
    },

    update:(cardConfig) => {
        return http.put(`publication-config/${cardConfig.id}`, {
            ...cardConfig
        });
    },

    delete:() => {
        
    }

}