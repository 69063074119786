<template>
  <div id="ombudsman">
    <nav-bar-pref></nav-bar-pref>
    <b-container-fluid>
      <b-row class="title">
        <b-col md="7" class="content-col">
          <h1>Ouvidoria</h1>
        </b-col>
      </b-row>
      <br-row>
        <b-col md="7" class="content-col">
          <b-row style="margin: auto"> </b-row>
          <div style="display: grid">
            <a
              target="_blank"
              href="https://falabr.cgu.gov.br/web/MS/Ribas%20do%20Rio%20Pardo"
            >
              <img src="../assets/ouvidoria/acesso-informacao.png" />
            </a>
            <small>Clique para acessar e-SIC/e-OUV</small>
          </div>
          <p>
            Clicando no botão ACIMA <strong>“ACESSO À INFORMAÇÃO”</strong> você é direcionado a plataforma FALA BR com os seguintes serviços ao cidadão:
            
            <br><strong>Reclamação:</strong> Manifesta a insatisfação com o serviço público;
            <br><strong>Elogio:</strong> Expressar se está satisfeito com um atendimento público;
            <br><strong>Solicitação:</strong> Solicitar a adoção de providências por parte de uma Ouvidoria;
            <br><strong>Sugestão:</strong> Enviar uma ideia ou proposta de melhoria para os serviços públicos
            <br><strong>Denúncia:</strong> Comunicar uma irregularidade, um ato ilícito ou uma violação de direitos na administração pública e
            <br><strong>Simplifique:</strong> Sugerir alguma ideia para desburocratizar o serviço público</p>
          <p>
            A Ouvidoria Municipal e o Serviço de Informações e Atendimento ao Cidadão - SIC
            é um canal de relacionamento entre a população e o Poder Público Municipal na 
            solução de problemas, encaminhando a manifestação aos órgãos municipais e buscando 
            melhorias na sociedade e no poder público.

          </p>
          <p>
            
            A Ouvidoria disponibiliza um e-mail para envio de solicitações: 
            <strong><a href="mailto:ouvidoria@ribasdoriopardo.ms.gov.br">ouvidoria@ribasdoriopardo.ms.gov.br</a></strong>. O cidadão também poderá entrar 
            em contato via WhatsApp através do número: <strong>
              <a href="https://api.whatsapp.com/send?phone=%2B5567996061175&data=ARAg2bInsQpGJ4bgzuWuRZ8CwNKCw6_nSiA4x1UdginNmA8ZxZBO-7QyhDps3cvsXgVf8vMx_5NbAIGpxcEDQtFu-AXdCIWjpFXHsqNnXarS8PWRDA3fZTDLKQ4KUE8qY0cC-FjSr11iOl62rBEmZVLOCA&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR0qxz-T3mti5CUePKBWidq2rmSK4Y_a9qZ-aaZEwFEwTMHiuVaaK-Hyd5Q">
              (67) 9 9606-1175</a></strong> e fazer 
            sua solicitação.
          </p>
          <p>
            O cidadão pode ainda baixar o aplicativo Ribas digital disponível para aparelhos android e IOS onde pode, 
            além de fazer Reclamação, Elogio, Solicitação de serviços, Sugestão ou Denúncia.
          </p>
          <p>Para baixar o aplicativo Ribas Digital na versão <strong>Android</strong>, clique <strong><a href="https://play.google.com/store/apps/details?id=com.f5webnet.ribasdigital&pli=1">
            AQUI</a></strong>
            <br>Para baixar o aplicativo Ribas Digital na versão <strong>IOS</strong>, clique <strong><a href="https://apps.apple.com/br/app/ribas-digital/id1669794394">
            AQUI</a></strong></p>
          <p>
            A Ouvidoria e o SIC estão situados dentro do prédio da Prefeitura Municipal de Ribas do 
            Rio Pardo-Ms, que fica localizada na Rua Conceição do Rio Pardo, 1725 CEP: 79180-000 - Centro. Ribas do Rio Pardo - MS. 
            Horário de atendimento: Segunda à Sexta: <strong>7h/11h - 13h/17h</strong>.
          </p>
          <p>
            O acesso à informação está previsto na Lei Federal nº. 12.527, de 18 de novembro de 2011; 
            Lei Municipal n°. 1.011, de 26 de Novembro de 2013; e Decreto Municipal nº. 134, de dezembro 
            de 2015, e o Decreto Municipal 42 de abril de 2019  que regula a Ouvidoria e o SIC no âmbito do Poder Executivo do Município de Ribas do Rio Pardo/MS.
          </p>
          <p>
            <i class="fas fa-file"></i><strong><a href="https://www.planalto.gov.br/ccivil_03/_ato2011-2014/2011/lei/l12527.htm"> Lei Federal nº. 12.527, de 18 de novembro de 2011</a></strong>
          </p>
          <p>
            <i class="fas fa-file"></i><strong><a href="https://diribas.s3.sa-east-1.amazonaws.com/03f0a90726044b932d9d2624108db4b9-LEI%20PROMULGADA%20N%C2%BA%201.011%20-%20REGULA%20O%20ACESSO%20%C3%80%20INFORMA%C3%87%C3%83O.pdf"> Lei Municipal n°. 1.011, de 26 de Novembro de 2013</a></strong>
          </p>
          <p>
            <i class="fas fa-file"></i><strong><a href="https://diribas.s3.sa-east-1.amazonaws.com/ef10487942464804f2afec2be9a45e44-Decreto%20N%20134-2015.pdf"> Decreto Municipal nº. 134, de dezembro de 2015</a></strong>
          </p>
          <p>
            <i class="fas fa-file"></i><strong><a href="https://diribas.s3.sa-east-1.amazonaws.com/DECRETO+042+-+2019.pdf"> Decreto Municipal nº. 42, de abril de 2019</a></strong>
          </p>
        </b-col>
      </br-row>
    </b-container-fluid>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../components/FooterPref";
import NavBarPref from "../components/NavBarPref";

export default {
  
  components: {
    FooterPref,
    NavBarPref,
  },
};
</script>
<style lang="scss">
#ombudsman {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  .title {
    margin: 30px auto 30px auto;
  }
  .content-col {
    margin: auto;
    text-align: initial;

    img {
      max-width: 330px;
      margin-right: 20px;
    }
    small {
      margin-bottom: 30px;
      font-weight: bold;
    }
    p,
    h1 {
      text-align: justify;
      font-family: Raleway;
    }
    h1 {
      font-weight: bold;
    }
  }
}
</style>