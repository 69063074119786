var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('nav-bar-pref'),_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"main-col"},[_c('b-row',{staticClass:"header"},[_c('h1',[_vm._v("Últimas Notícias")]),_c('b-form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.search($event)}}},[_c('b-form-input',{attrs:{"placeholder":"Busque por título ou conteúdo"},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_c('i',{staticClass:"fas fa-search"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"main-col"},_vm._l((_vm.publications),function(publication){return _c('b-card',{key:publication.id,staticClass:"mb-3 hvr-shadow",attrs:{"img-src":publication.PublicationImgs[0] &&
            publication.PublicationImgs[0].url
              ? publication.PublicationImgs[0].url
              : require('../.././assets/noimg.png'),"img-width":"270","img-height":_vm.isMobile ? 270 : 200,"img-left":_vm.imgLeft},on:{"click":function($event){return _vm.goTo('publication-view', publication.id, publication.title)}}},[_c('b-col',{attrs:{"md":"11"}},[_c('div',{staticClass:"tag-wrapper"},[(publication.tags && publication.tags.length > 0)?_c('small',{staticClass:"tag"},[_vm._v(_vm._s(publication.tags[0].name))]):_vm._e(),_c('small',{staticClass:"publication-date"},[_vm._v(_vm._s(_vm.formatDate(publication.createdAt, publication.date)))])]),_c('b-card-title',[_vm._v(" "+_vm._s(publication.title)+" ")]),(_vm.showBody)?_c('b-card-text',[(publication.body.match(/<\/?[a-z][\s\S]*>/i))?_c('div',{domProps:{"innerHTML":_vm._s(
                  publication.body.length <= 180
                    ? publication.body
                    : publication.body.substring(0, 180) + '...'
                )}}):_c('p',[_vm._v(" "+_vm._s(publication.body || publication.meta_description)+" ")])]):_vm._e()],1),(!_vm.isMobile)?_c('b-col',{staticClass:"icon-right hvr-icon-forward",attrs:{"md":"1"}},[_c('i',{staticClass:"fas fa-angle-right fa-2x hvr-icon"})]):_vm._e()],1)}),1)],1),_c('b-row',[_c('b-pagination',{staticStyle:{"margin":"15px auto 15px auto"},attrs:{"total-rows":_vm.count,"per-page":_vm.pageSize,"align":"center"},on:{"change":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('footer-pref')],1)}
var staticRenderFns = []

export { render, staticRenderFns }