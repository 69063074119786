import 'core-js';
import { http } from './config'

export default {
    store: (diribasDate) => {
        return http.post('diribas-dates', {
            ...diribasDate
        });
    },

    list: () => {
        return http.get('diribas-dates');
    },

    listById: (id) => {
        return http.get(`diribas-dates/${id}`);
    },

    listPaginate: (params) => {
        return http.get('diribas-dates-paginate', {
            params
        });
    },

    update: (diribasDate) => {
        return http.put(`diribas-dates/${diribasDate.id}`, {
            ...diribasDate
        });
    },

    delete: (id) => {
        return http.delete(`diribas-dates/${id}`);
    }

}