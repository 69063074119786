<template>
  <div id="history-ribas">
    <nav-bar-pref></nav-bar-pref>
    <b-container class="history-container">
      <b-col>
        <b-row>
          <h1>História</h1>
        </b-row>
        <b-row>
          <hr class="history-separator" />
        </b-row>
        <b-row>
          <span><b>RIBAS DO RIO PARDO - MS</b></span>
          <p>
            As terras que atualmente compreendem o Município de Ribas do Rio
            Pardo, foram devassadas, nos meados do primeiro terço do século
            XVII, pelos bandeirantes paulistas, que, partindo de São Paulo,
            seguiam os Rios Tietê e Paraná, subiam o Rio Pardo, venciam o
            varadouro para Camapuã, daí partindo em busca das terras do norte e
            das minas de Pascola Moreira e Sutil.
          </p>
          <p>
            As terras de Ribas do Rio Pardo não seduziam os sertanistas, cujo
            objetivo era o ouro ou a prea de Indígenas para os trabalhos que se
            desenvolviam nas lavouras de Piratininga ou no litoral.
          </p>
          <p>
            No período compreendido entre 1822 e 1840, com a abertura da estrada
            de Piquiri e consequente abandono da rota do Rio Pardo, os Garcias
            deram início ao povoamento de Santana de Paranaíba. Em sua esteira
            segue o mineiro Joaquim Francisco Lopes, sertanista audaz e
            irriquieto que inicialmente se instala, nas margens do Rio Paraná,
            com fazenda de criação de gado. Abandona a propriedade e dá largos a
            seu espírito de aventuras, percorrendo todo o extremo sul do Estado,
            inclusive parte do Paraná e São Paulo; para logo a seguir, se achar
            em Cuiabá, acertando com o Governador a abertura da estrada de
            Piracicaba.
          </p>
          <p>
            Em 1835, arranchado nas barrancas do Rio Paraná, encontra o cuiabano
            Eleutero Nunes que lhe relata a existência dos campos e aguadas do
            Rio Pardo, com excelentes perspectivas para a criação de bovinos. No
            ano seguinte, parte o sertanista em direção ao Rio Pardo, demarcando
            novas posses e distribuindo-as a companheiros seus vindos de Santana
            do Paranaíba; dando assim início à povoação da região de Ribas do
            Rio Pardo.
          </p>
          <div class="text-image">
            <b-col md="4" sm="12">
              <figure>
                <img src="../../assets/ribas/ferrovia.jpg" />
              </figure>
            </b-col>
            <b-col md="8" sm="12">
              <p>
                Apesar do registro de vestígios das monções jesuíticas e da
                passagem ou mesmo curta permanência de expedições exploratórias,
                a formação do povoado se deu somente por volta do ano de 1900,
                quando se registrou concretamento a fixação dos primeiros
                moradores; os irmãos João e José dos Santos, mineiros de Uberaba
                que fixaram residência e comércio próximo à confluência dos Rios
                Bota e Pardo. Outros moradores para ali se deslocaram, oriúndos
                de Santana do Paranaíba, em companhia do capitão Manoel Garcia
                Tosta.
              </p>
              <p>
                Posteriormente, afluíram ao pequeno povoado os baianos Vitorino
                Pereira da Silva, Agrícola Sancho da Silva, Antônio Aparecido,
                José Alves, Francisco Alves de Araújo e Estevam Pereira de
                Almeida; o paulista Justino Rangel e o mineiro Modesto Luiz de
                oliveira, pioneiros que muito contribuíram para o seu
                desenvolvimento.
              </p>
            </b-col>
          </div>
          <p>
            Um dos fatores mais importantes para o progresso de nova povoação
            foi a chegada dos trilhos da atual Estrada de Ferro Noroeste do
            Brasil e a inauguração da Estação local, no dia 23 de julho de 1914,
            ligando Ribas do Rio Pardo aos grandes centros urbanos.
          </p>
          <p>
            Em 1915 foi criado o Distrito Policial, sendo nomeado seu primeiro
            sub-delegado Antônio Aparecido. Em 1918 é criado a primeira escola,
            tendo como professor José Coleto Garcia. Em 1919 foram instaladas a
            Coletoria Estadual, sendo nomeado Coletor Arnaldo de Oliveira Palma
            e a Agência do Correio, sendo titular D. Mercedes.
          </p>
          <p>
            Pela Resolução 856, de 7 de novembro de 1921, foi elevado à
            categoria de Distrito de Paz, com a denominação de Conceição do Rio
            Pardo, sendo nomeado Juiz de Paz titular Estêvão Pereira de
            Almeida.<br />
            O topônimo atual adveio do Rio do mesmo nome que banha as terras do
            município.
          </p>
          <b-col md="12" class="carousel-container-history">
            <b-carousel
              id="carousel-1"
              v-model="slide"
              :interval="3000"
              controls
              indicators
              background="#ababab"
              img-width="1024"
              img-height="200"
              style="text-shadow: 1px 1px 2px #333"
              @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd"
            >
              <b-carousel-slide
                v-for="img in imgs"
                :key="img.src"
              >
                <img
                  slot="img"
                  class="d-block img-fluid w-100"
                  width="480"
                  height="480"
                  :src="img.src"
                  alt="image slot"
                />
              </b-carousel-slide>
            </b-carousel>
          </b-col>
          <p style="margin-top: 10px;"><b>GENTÍLICO</b>: Rio-pardense</p>
          <p>
            <span><br /><b>FORMAÇÃO ADMINISTRATIVA</b></span> <br />* Em
            divisões territoriais datadas de 31-12-1936 e 31-12-1937, figura no
            Município de Campo Grande o Distrito de Rio Pardo. <br />* No quadro
            fixado para vigorar no período de 1939/1943, o distrito permanece no
            Município de Cjampo Grande. <br />* Elevado à categoria de município
            com a denominação de Ribas do Rio Pardo, por Decreto-Lei Estadual nº
            545, de 31-12-1943, desmembrado de Campo Grande e Três Lagoas. Sede
            na Localidade de Ribas do Rio Pardo. Constituído do Distrito Sede.
            Instalado em 19-03-1944. <br />* No quadro fixado para vigorar no
            período de 1944/1958, o município é constituído do Distrito Sede.
            <br />* Pela Lei Estadual nº 1123, de 17-11-1958, é criado o
            Distrito de Bálsamo e incorporado ao Município de Ribas do Rio
            Pardo. <br />* Em divisão territorial datada de 1-07-1960, o
            município é constituído de 2 Distritos: Ribas do Rio Pardo e
            Bálsamo. <br />* Assim permanecendo em divisão territorial datada de
            15-07-1999. <br /><span>Fonte: IBGE</span>
          </p>
          <h2>A Cidade</h2>
          <p>
            Ribas do Rio Pardo é um município brasileiro da região Centro-Oeste,
            situado no estado de Mato Grosso do Sul. Dista 102 quilômetros de
            Campo Grande, capital de Mato Grosso do Sul, e a sede do município
            localiza-se às margens da rodovia BR-262. Às margens dessa rodovia
            também encontram-se grandes áreas destinadas para instalação de
            empresas e indústrias, onde encontram-se instaladas empresas de
            reflorestamento, serrarias, frigoríficos entre outras empresas.
          </p>
          <p>
            <span><b>CLIMA, TEMPERATURA E PLUVIOSIDADE</b></span
            ><br />
            Está sob influência do clima tropical (AW), sendo as temperaturas
            médias do mês mais frio menores que 20°C e maiores que 18°C.
          </p>
          <p>
            Ao Norte do município, o clima se apresenta sub-úmido, com índice de
            umidade com valores anuais variando de 0 a 20%. A precipitação
            pluviométrica anual varia entre 1.200 a 1.500mm. Excedente hídrico
            de 400 a 800mm durante três a quatro meses e deficiência hídrica de
            500 a 650mm durante cinco meses. Ao sul, o clima predominante é
            úmido a sub-úmido, com índice de umidade com valores anuais variando
            de 20 a 40%. A precipitação pluviométrica anual varia entre 1.500 a
            1.750mm. Excedente hídrico de 800 a 1.200mm durante cinco a seis
            meses e deficiência hídrica de 350 a 500mm durante quatro meses.
          </p>
          <p>
            <span><b>VEGETAÇÃO</b></span
            ><br />Se localiza na região de influência do Cerrado, preservando o
            aspecto pseudo-xeromórfico oligotrófico (falsa aparência de ausência
            de água, porém, pobre em nutrientes). No restante da área, a
            cobertura se distribui em pastagem plantada, reflorestamento, várzea
            e lavoura.
          </p>
          <p>
            <span><b>ECONOMIA</b></span
            ><br />Por sua imensa extensão territorial, sua economia é
            basicamente sustentada pelo setor de agropecuária. Predomina a
            criação de gado, havendo também o extrativismo de resina, carvão,
            com uma indústria siderúrgica, frigorífico, e diversas serrarias,
            além de outras pequenas indústrias.
          </p>
        </b-row>
      </b-col>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../../components/FooterPref.vue";
import NavBarPref from "../../components/NavBarPref.vue";
export default {
  data() {
    return {
      imgs: [
        { src: require('../../assets/ribas/slide1.jpg') },
        { src: require('../../assets/ribas/slide2.jpg') },
        { src: require('../../assets/ribas/slide3.jpg') },
        { src: require('../../assets/ribas/slide4.jpg') },
        { src: require('../../assets/ribas/slide5.jpg') },
        { src: require('../../assets/ribas/slide6.jpg') },
      ],
      slide: 0,
      sliding: null,
    }
  },
  components: {
    NavBarPref,
    FooterPref,
  },
  methods: {
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      }
    }
};
</script>
<style lang="scss">
#history-ribas {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Raleway;
  .footer {
    margin-top: 20px;
  }
  .history-container {
    h1 {
      color: black;
      font-weight: bold;
      margin-top: 50px;
      text-align: justify;
    }
    h2 {
      color: black;
      font-weight: bold;
      margin-top: 20px;
      text-align: justify;
    }
    p {
      text-align: justify;
    }
    .text-image {
      display: flex;
      @media (max-width: 1020px) {
        display: grid;
      }
      div {
        padding: unset;
      }
      img {
        max-width: 300px;
      }
    }
    .carousel-container-history {
      .carousel-item {
        img {
          min-height: 450px;
          max-height: 530px;
        }
      }
    }
    .history-separator {
      border: 0;
      height: 1px;
      background: #ccc;
      width: 100%;
    }
  }
}
</style>