import 'core-js';
import { http } from './config'

export default {
    store:(formData) => {
        return http.post('publication-images', formData);
    },

    storeMainImg:(formData) => {
        return http.post('publication-images-main', formData);
    },

    storeAttachment:(formData) => {
        return http.post('publication-attachments', formData);
    },

    downloadAttachment:(id) => {
        return http.get(`publication-attachments/${id}`);
    },
    
    generateDownloadLink:(name) => {
        return http.get(`download-attachments/${name}`);
    },

    storeDiribas:(formData) => {
        return http.post('diribas', formData);
    },

    storeDiribasAttach:(formData) => {
        return http.post('diribas-attach', formData);
    },

    storeDiribasBase64:(base, key) => {
        return http.post('diribas-base', {
            base,
            key
        });
    },

    storeAudio:(formData) => {
        return http.post('publication-audios', formData);
    },

    storeGalleryImgs:(formData) => {
        return http.post('gallery-images', formData);
    }
}