import 'core-js';
import { http } from './config'

export default {

    listRandom:(limit) => {
        return http.get(`gallery-images-random/${limit}`);
    },

    update:(galleryImg) => {
        return http.put(`gallery-images/${galleryImg.id}`, {
            galleryImg
        });
    },

    delete:(id) => {
        return http.delete(`gallery-images/${id}`);
    },

}