import Vue from 'vue'
import Router from 'vue-router'
import VueGtag from "vue-gtag";

import Home from '../views/Home'
import Edicts from '../views/Edicts'
import Transparency from '../views/Transparency'
import News from '../views/News'
import About from '../views/About'
import Attendance from '../views/Attendance'
import Secretariats from '../views/Secretariats'
import Issqn from '../views/Issqn'
import Maintenance from '../views/Maintenance'
import TerraNua from '../views/TerraNua'
import Expenses from '../views/coronaVirus/Expenses'
import Updates from '../views/coronaVirus/Updates'
import List from '../views/publications/List'
import PublicationView from '../views/publications/View'
import PublicationEdit from '../views/publications/Edit'
import DiribasList from '../views/diribas/List'
import NewDiribasList from '../views/diribas/NewList'
import DiribasUpload from '../views/diribas/Upload'
import RibasHistory from '../views/ribas/History'
import PublicSurvey from '../views/ribas/Decreto862024'
import PublicSurveyCassems from '../views/ribas/Decreto1582022'
import SaudeTransparente from '../views/ribas/SaudeTransparente'
import orcamento2024 from '../views/ribas/orcamento2024'
import Concurso from '../views/ribas/Concurso'
import Gallery from '../views/ribas/Gallery'
import Galleries from '../views/dashboard/gallery/Galleries'
import GalleryUpload from '../views/dashboard/gallery/GalleryUpload'
import GalleryView from '../views/dashboard/gallery/View'
import GalleryList from '../views/gallery/List'
import TagList from '../views/dashboard/tags/List'
import Tags from '../views/dashboard/tags/Tags'
import CovidMain from '../views/coronaVirus/Main'
import Law from '../views/Law'
import Login from '../views/authenticate/Login'
import Register from '../views/authenticate/Register'
import DashHome from '../views/dashboard/Home'
import EdictReport from '../views/dashboard/reports/EdictReports'
import NewsConfig from '../views/dashboard/configs/NewsConfig'
import Secretaries from '../views/Secretaries'
import Evacine from '../views/Evacine'
import UserList from '../views/dashboard/users/List'
import DepartmentList from '../views/dashboard/departments/List'
import SubDepartmentList from '../views/dashboard/sub-departments/List'
import Users from '../views/dashboard/users/Users'
import Departments from '../views/dashboard/departments/Departments'
import SubDepartments from '../views/dashboard/sub-departments/SubDepartments'
import NewDiribasUpload from '../views/diribas/NewUpload'
import PermissionList from '../views/dashboard/permissions/List'
import Permissions from '../views/dashboard/permissions/Permissions'
import RenderDiribas from '../views/RenderDiribas'
import Survey from '../views/survey/Survey'
import SurveyList from '../views/survey/SurveyList'
import Services from '../views/Services'
import OfficialSymbols from '../views/ribas/OfficialSymbols'
import DiribasSupplement from '../views/diribas/UploadSupplement'
import DiribasNotice from '../views/dashboard/configs/DiribasNotice'
import RenderDiribasPublication from '../views/RenderDiribasPublication'
import DiribasDateList from '../views/dashboard/diribas-dates/List'
import DiribasDates from '../views/dashboard/diribas-dates/DiribasDates'

import Payslip from '../views/Payslip'
import Request from '../views/Request'
import Supply from '../views/Supply'
import Ombudsman from '../views/Ombudsman'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
    if (!localStorage.getItem('token')) {
        next()
        return
    }
    next('/')
}

const ifAuthenticated = (to, from, next) => {
    if (localStorage.getItem('token')) {
        next()
        return
    }
    next('/login')
}

const routes = [
    {
        path: '*',
        redirect: '/'
    },
    {
        name: 'home',
        path: '/',
        component: Home,
    },
    {
        name: 'login',
        path: '/login',
        component: Login,
        beforeEnter: ifNotAuthenticated,
    },
    {
        name: 'register',
        path: '/create-user',
        component: Register
    },
    {
        name: 'maintenance',
        path: '/maintenance',
        component: Maintenance
    },
    {
        name: 'list',
        path: '/publicacoes/listagem/:tag?/:type?/:page?/:size?/:text?',
        component: List,
        props: true
    },
    {
        name: 'publication-view',
        path: '/publicacoes/:id/:title',
        component: PublicationView,
        props: true,
    },
    {
        name: 'publication-edit',
        path: '/publicacoes/salvar/:id?/:title?',
        component: PublicationEdit,
        props: true,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'expenses',
        path: '/corona-virus/expenses',
        component: Expenses
    },
    {
        name: 'updates',
        path: '/corona-virus/updates',
        component: Updates
    },
    {
        name: 'terranua',
        path: '/terra-nua',
        component: TerraNua
    },
    {
        name: 'edicts',
        path: '/editais/:page?/:size?/:text?',
        component: Edicts
    },
    {
        name: 'transparency',
        path: '/transparency',
        component: Transparency
    },
    {
        name: 'news',
        path: '/news',
        component: News
    },
    {
        name: 'about',
        path: '/about',
        component: About
    },
    {
        name: 'attendance',
        path: '/attendance',
        component: Attendance
    },
    {
        name: 'secretariats',
        path: '/secretariats',
        component: Secretariats
    },
    {
        name: 'issqn',
        path: '/issqn',
        component: Issqn
    },
    {
        name: 'payslip',
        path: '/payslip',
        component: Payslip
    },
    {
        name: 'supply',
        path: '/supply',
        component: Supply
    },
    {
        name: 'request',
        path: '/request',
        component: Request
    },
    {
        name: 'ombudsman',
        path: '/ouvidoria',
        component: Ombudsman
    },
    {
        name: 'diribasupload',
        path: '/diribas-upload',
        component: DiribasUpload,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'newdiribasupload',
        path: '/new-diribas-upload/:id?',
        component: NewDiribasUpload,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'diribaslist',
        path: '/new-diribas',
        component: DiribasList
    },
    {
        name: 'newdiribaslist',
        path: '/diribas',
        component: NewDiribasList
    },
    {
        name: 'supplement',
        path: '/diribas-suplemento',
        component: DiribasSupplement,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'ribashistory',
        path: '/ribas/historia',
        component: RibasHistory
    },
    {
        name: 'publicsurvey',
        path: '/consulta-publica-logradouros-2024',
        component: PublicSurvey
    },
    {
        name: 'publicsurveycassems',
        path: '/consulta-publica-decreto-158-2022',
        component: PublicSurveyCassems
    },
    {
        name: 'saudetransparente',
        path: '/saudetransparente',
        component: SaudeTransparente
    },
    {
        name: 'orcamento2024',
        path: '/orcamento-2024',
        component: orcamento2024
    },
    {
        name: 'concurso',
        path: '/concurso-2024',
        component: Concurso
    },
    {
        name: 'gallery',
        path: '/ribas/galeria',
        component: Gallery
    },
    {
        name: 'covid',
        path: '/coronavirus',
        component: CovidMain
    },
    {
        name: 'law-view',
        path: '/legislacao/:type/:tag?/:page?/:size?/:text?',
        component: Law,
        props: true,
    },
    {
        name: 'dashboard-home',
        path: '/dashboard',
        component: DashHome,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'edict-report',
        path: '/dashboard/relatorios/editais',
        component: EdictReport,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'news-config',
        path: '/dashboard/configuracao/noticias',
        component: NewsConfig,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'gallery-list',
        path: '/dashboard/galerias',
        component: Galleries,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'gallery-upload',
        path: '/galerias/salvar/:id?/:title?',
        component: GalleryUpload,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'gallery-view',
        path: '/galerias/:id/:title',
        component: GalleryView,
    },
    {
        name: 'gallery-list-home',
        path: '/galerias/listagem/:page?/:size?/:text?',
        component: GalleryList,
    },
    {
        name: 'tag-list',
        path: '/dashboard/tags',
        component: TagList,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'tags',
        path: '/dashboard/tags-upload/:id?/:title?',
        component: Tags,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'diribas-date-list',
        path: '/dashboard/configuracao/datas-diribas',
        component: DiribasDateList,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'diribas-dates',
        path: '/dashboard/dates-upload/:id?',
        component: DiribasDates,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'user-list',
        path: '/dashboard/users',
        component: UserList,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'department-list',
        path: '/dashboard/departments',
        component: DepartmentList,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'sub-department-list',
        path: '/dashboard/sub-departments',
        component: SubDepartmentList,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'users',
        path: '/dashboard/user-create/:id?/:title?',
        component: Users,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'departments',
        path: '/dashboard/department-create/:id?',
        component: Departments,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'sub-departments',
        path: '/dashboard/sub-department-create/:id?',
        component: SubDepartments,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'permission-list',
        path: '/dashboard/permissions',
        component: PermissionList,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'permissions',
        path: '/dashboard/permission-create/:id?',
        component: Permissions,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'secretaries',
        path: '/secretarias',
        component: Secretaries,
    },
    {
        name: 'evacine',
        path: '/vacinometro',
        component: Evacine,
    },
    {
        name: 'render-diribas',
        path: '/render/:id',
        component: RenderDiribas,
    },
    {
        name: 'render-diribas-publication',
        path: '/render-publication/:id',
        component: RenderDiribasPublication,
    },
    {
        name: 'survey',
        path: '/sugestao',
        component: Survey,
    },
    {
        name: 'surveys',
        path: '/dashboard/sugestoes',
        component: SurveyList,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'services',
        path: '/servicos',
        component: Services,
    },
    {
        name: 'official-symbols',
        path: '/ribas/simbolos-oficiais',
        component: OfficialSymbols,
    },
    {
        name: 'diribas-notice',
        path: '/dashboard/configuracao/diribas',
        component: DiribasNotice,
        beforeEnter: ifAuthenticated,
    },
];

const router = new Router({
    mode: 'history',
    routes
})

Vue.use(VueGtag, {
    config: { id: "G-DQ2Z1DN4YX" }
}, router);

export default router