<template>
  <div>
    <nav-bar-pref></nav-bar-pref>
    <b-container class="upload-publication-main">
      <link
        rel="stylesheet"
        href="../../node_modules/@fortawesome/fontawesome-free/css/all.css"
      />
      <div class="publication-edit-title col-md-12">
        <h1 v-if="!editMode">NOVO DEPARTAMENTO</h1>
        <h1 v-else>EDIÇÃO DO DEPARTAMENTO</h1>
      </div>
      <b-form @submit.prevent="send" class="form-publication">
        <b-form-group label-for="file">
          <div class="edit-wrap">
            <div class="col-md-6">
              <span class="required-mark">* </span
              ><label class="form-label" for="title">Nome</label>
              <b-form-input
                id="title"
                placeholder="Nome"
                required
                v-model="department.name"
              ></b-form-input>
            </div>
            <div class="col-md-6">
              <span class="required-mark">* </span
              ><label class="form-label" for="title">Posição</label>
              <b-form-input
                type="number"
                id="title"
                placeholder="Posição"
                required
                v-model="department.position"
              ></b-form-input>
            </div>
          </div>
          <div class="edit-wrap">
            <div class="col-md-6">
              <span class="required-mark">* </span
              ><label class="form-label" for="title">Responsável</label>
              <b-form-input
                id="title"
                placeholder="Responsável"
                required
                v-model="department.responsible"
              ></b-form-input>
            </div>
          </div>
          <div class="date-upload"></div>
          <div class="upload-buttons col-md-12">
            <b-button type="submit" variant="primary">Enviar</b-button>
          </div>
        </b-form-group>
      </b-form>
      <b-modal
        ref="response-modal"
        footer-bg-variant="light"
        ok-only
        title="Sessão Expirada"
        @hide="emitUnauthorized"
      >
        Efetue login novamente
      </b-modal>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import AuthenticationService from "../../../services/AuthenticationService";
import FooterPref from "../../../components/FooterPref.vue";
import NavBarPref from "../../../components/NavBarPref.vue";
import DepartmentService from "../../../services/DepartmentService";

export default {
  data() {
    return {
      department: {
        name: "",
        position: "",
        responsible: '',
      },
      editMode: false,
      buttonDisabled: false,
    };
  },
  components: {
    NavBarPref,
    FooterPref,
  },
  created() {
    if (this.$route.params.id != "nova") {
      this.editMode = true;
      DepartmentService.listById(this.$route.params.id).then((res) => {
        this.department = res.data;
      });
    }
  },
  async mounted() {
    try {
      const validLogin = await AuthenticationService.validateLogin();
      console.log(validLogin.status);
    } catch (error) {
      if (error.response.status == 401) {
        this.$refs["response-modal"].show();
      }
    }
  },
  methods: {
    send() {
      try {
        if (this.editMode) {
          DepartmentService.update(this.department)
            .then((updated) => {
              window.alert("Alterado com sucesso");
              setTimeout(() => {
                this.goToDashboard(updated.data);
              }, 600);
            })
            .catch((err) => {
              if (err.response && err.response.status == 401) {
                this.$refs["response-modal"].show();
              } else {
                console.log(err);
                window.alert("Erro ao salvar");
              }
            });
        } else {
          DepartmentService.store(this.department)
            .then(() => {
              window.alert("Cadastrado com sucesso");
              setTimeout(() => {
                this.goToDashboard();
              }, 600);
            })
            .catch((err) => {
              if (err.response && err.response.status == 401) {
                this.$refs["response-modal"].show();
              } else {
                console.log(err);
                window.alert("Erro ao salvar publicação");
              }
            });
        }
      } catch (e) {
        console.log(e);
        window.alert("Erro ao salvar publicação");
      }
    },
    goToDashboard() {
      this.$router.go(-1);
    },
    emitUnauthorized() {
      this.$root.$emit("unauthorized");
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.upload-publication-main {
  min-height: 100vh;
  :disabled {
    cursor: not-allowed;
  }
  .publication-edit-header {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 85%;
    .back-button {
      i {
        margin-right: 5px;
      }
    }
  }
  .publication-edit-title {
    text-align: initial;
    font-family: Ubuntu;
    font-weight: bold;
    color: #707070;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .sortable-drag {
    opacity: 0;
  }
  .sortable {
    cursor: move;
    margin-bottom: 10px;
    background-color: #fbfbfb;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    span {
      font-weight: bold;
    }
    i {
      margin-right: 5px;
    }
    .remove-icon {
      margin-right: 0;
    }
    .drag-wrap {
      display: flex;
      align-items: center;
    }
    img {
      height: 150px;
      width: 180px;
      margin-right: 10px;
      object-fit: cover;
      @media (max-width: 769px) {
        height: 100px;
        width: 130px;
      }
    }
    button {
      margin-right: 10px;
    }
  }
  .attach-move-warp {
    display: flex;
    align-items: center;
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .ghost {
    border-left: 6px solid #204991;
    box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.14);
    opacity: 0.7;
  }
  .form-publication {
    .form-label {
      font-weight: bold;
      margin-bottom: 0;
      font-size: 15px;
      color: #929292;
    }
    .edit-wrap {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      text-align: initial;
      @media (max-width: 768px) {
        display: grid;
        margin-bottom: 10px;
        .form-label {
          margin-top: 10px;
        }
      }
      .required-mark {
        color: #fc2c03;
      }
      .upload-radio {
        display: flex;
        div:first-of-type {
          margin-right: 15px;
        }
      }
    }
    .editor-buttons {
      margin-top: 20px;
      margin-bottom: 3px;
      button {
        margin-right: 1px;
      }
    }
    .col {
      margin-bottom: 10px;
    }
    .editor-box > * {
      border-color: grey;
      border-style: solid;
      border-width: 1px;
      min-height: 100px;
      text-align: initial;
      word-break: break-word;
      padding-left: 10px;
      padding-right: 10px;
      .ProseMirror {
        padding: 5px;
      }
    }
    .is-active {
      border-color: grey;
      border-style: solid;
      border-width: 1px;
    }
    .custom-checkbox {
      text-align: initial;
    }
    .upload-buttons {
      text-align: initial;
      margin-top: 30px;
      .btn {
        margin-right: 10px;
      }
    }
    .date-upload {
      text-align: initial;
      display: grid;
      .mx-datepicker {
        width: 100%;
      }
    }
    .mx-datepicker {
      width: 100%;
    }
    blockquote {
      border-left: 5px solid #ccc;
      margin: 1.5em 10px;
      padding: 0.5em 10px;
      quotes: "\201C""\201D""\2018""\2019";
    }
    blockquote p {
      display: inline;
    }
    .is-active {
      background-color: #545b62;
    }
  }
}
</style>