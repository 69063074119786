import 'core-js';
import { http } from './config'

export default {
    store:(publication) => {
        return http.post('publications', {
            publication
        });
    },

    listById:(id) => {
        return http.get(`publications/${id}`);
    },

    listByType:(typeId, tagId, id, limit = 10) => {
        return http.get(`publications-type/${typeId}/${limit}/${tagId}/${id}`);
    },

    list:() => {
        return http.get('publications');
    },

    listTwo:() => {
        return http.get('publications-two');
    },

    listFour:(firstCard, secondCard) => {
        return http.get(`publications-four/${firstCard}/${secondCard}`);
    },

    listCards:() => {
        return http.get('publications-config');
    },

    listFeatured:() => {
        return http.get('publications-featured');
    },

    listBoletins:() => {
        return http.get('publications-boletins');
    },

    listLastBoletim:() => {
        return http.get('publications-boletins-last');
    },

    listPaginate:(params) => {
        return http.get('publications-paginate',{
            params
        });
    },

    listPaginateByType:(params) => {
        return http.get('publications-paginate-type',{
            params
        });
    },

    update:(publication) => {
        return http.put(`publications/${publication.id}`, {
            publication
        });
    },

    delete:(id) => {
        return http.delete(`publications/${id}`);
    }

}