<template>
  <div class="main-symbols">
    <nav-bar-pref></nav-bar-pref>
    <b-container>
      <div>
        <h1>Símbolos Oficiais</h1>
      </div>
      <div class="symbols-wrap">
        <h3>Hino De Ribas Do Rio Pardo</h3>
        <b-col md="12" class="attachment-list">
          <ul class="doc-container-edict">
            <li class="edict-item" v-for="hino in hinos" :key="hino.url">
              <div class="file">
                <i :class="hino.icon"></i>
                <div class="file-information">
                  <p>{{ hino.name }}</p>
                  <div class="file-buttons">
                    <b-button
                      @click="download(hino.key)"
                      variant="outline-primary"
                      ><i class="fas fa-download"></i>Download</b-button
                    >
                    <b-button
                      @click="openFile(hino.url)"
                      variant="outline-secondary"
                      ><i class="fas fa-eye"></i>Abrir</b-button
                    >
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </b-col>
      </div>
      <div class="symbols-wrap" style="margin-top: 55px">
        <h3>Marcas Institucionais</h3>
        <b-col md="12" class="attachment-list">
          <ul class="doc-container-edict">
            <li class="edict-item" v-for="symbol in symbols" :key="symbol.url">
              <div class="file">
                <i :class="symbol.icon"></i>
                <div class="file-information">
                  <p>{{ symbol.name }}</p>
                  <div class="file-buttons">
                    <b-button
                      @click="download(symbol.key)"
                      variant="outline-primary"
                      ><i class="fas fa-download"></i>Download</b-button
                    >
                    <b-button
                      @click="openFile(symbol.url)"
                      variant="outline-secondary"
                      ><i class="fas fa-eye"></i>Abrir</b-button
                    >
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </b-col>
      </div>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../../components/FooterPref.vue";
import NavBarPref from "../../components/NavBarPref.vue";
import UploadService from "../../services/UploadService";
export default {
  data() {
    return {
      symbols: [
        // {
        //   name: "ribas-logo-azul.png",
        //   url: "https://ribas-publications.s3.sa-east-1.amazonaws.com/ribas-logo-azul.png",
        //   icon: "far fa-image fa-2x",
        //   key: "ribas-logo-azul.png",
        // },
        // {
        //   name: "ribas-logo-branco.png",
        //   url: "https://ribas-publications.s3.sa-east-1.amazonaws.com/ribas-logo-branco.png",
        //   icon: "far fa-image fa-2x",
        //   key: "ribas-logo-branco.png",
        // },
        {
          name: "bandeira-ribas.png",
          url: "https://ribas-publications.s3.sa-east-1.amazonaws.com/bandeira-ribas.png",
          icon: "far fa-image fa-2x",
          key: "bandeira-ribas.png",
        },
      ],
      hinos: [
        {
          name: "hino-ribas-do-rio-pardo.mp3",
          url: "https://ribas-publications.s3.sa-east-1.amazonaws.com/Hino+Oficial+-+Ribas+do+Rio+Pardo+MS.mp3",
          icon: "fas fa-music fa-2x",
          key: "Hino Oficial - Ribas do Rio Pardo MS.mp3",
        },
        {
          name: "hino-ribas-do-rio-pardo-letra.pdf",
          url: "https://ribas-publications.s3.sa-east-1.amazonaws.com/Hino+Oficial+de+Ribas+do+Rio+Pardo.pdf",
          icon: "fas fa-file fa-2x",
          key: "Hino Oficial de Ribas do Rio Pardo.pdf",
        },
      ],
    };
  },
  methods: {
    download(name) {
      UploadService.generateDownloadLink(name).then((res) => {
        window.open(res.data);
      });
    },
    openFile(url) {
      window.open(url, "_blank");
    },
  },
  components: {
    FooterPref,
    NavBarPref,
  },
};
</script>

<style lang="scss">
.main-symbols {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Raleway;
  text-align: initial;
  h1 {
    color: black;
    font-weight: bold;
    margin-top: 50px;
    text-align: initial;
    margin-bottom: 30px;
  }
  h3 {
    font-weight: bold;
  }
  .attachment-list {
    .doc-container-edict {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      padding: unset;
      li {
        &:hover .file-information p {
          color: #204991;
        }
      }
      .edict-item {
        text-align: justify;
        margin-bottom: 10px;
        @media (max-width: 990px) {
          width: calc(100% * (1 / 2) - 10px - 1px);
        }
        @media (max-width: 768px) {
          width: calc(100% * (1 / 1) - 10px - 1px);
        }
        width: calc(100% * (1 / 2) - 10px - 1px);
        small {
          font-weight: bold;
        }
        .file {
          display: flex;
          i {
            color: red;
            margin: auto;
          }
          p {
            margin-bottom: unset;
          }
          .file-information {
            width: 100%;
            margin-left: 5%;
            p {
              transition: all 0.4s ease;
              word-break: break-word;
              text-align: initial;
            }
            .file-buttons {
              margin-top: 5px;
              button {
                &:first-child {
                  margin-right: 5px;
                }
              }
              i {
                margin-right: 5px;
                color: inherit;
              }
            }
          }
        }
      }
    }
  }
}
</style>