<template>
  <div id="Issqn">
    <link
      rel="stylesheet"
      href="node_modules/@fortawesome/fontawesome-free/css/all.css"
    />
    <nav-bar-pref></nav-bar-pref>
    <div class="main-container">
      <div
        style="width: 100%; height: 80%; overflow: hidden;"
      >
        <iframe
          src="http://portal.issqn.net/tecnosis/Portal.html#versao=30"
          width="100%"
          height="100%"
          style="border: none"
        >
        </iframe>
      </div>
    </div>
    <footer-pref></footer-pref>
  </div>
</template>

<script>
import FooterPref from "../components/FooterPref";
import NavBarPref from "../components/NavBarPref";

export default {
  name: "Issqn",
  data() {
    return {};
  },

  components: {
    FooterPref,
    NavBarPref,
  },

  mounted() {},
};
</script>

<style lang="scss">
#Issqn {
  width: 100%;
  height: 100%;

  .main-container {
    width: 100%;
    height: 100%;
  } 
}
</style>