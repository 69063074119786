<template>
  <div class="main-consulta-publica">
    <nav-bar-pref></nav-bar-pref>
    <b-container>
      <iframe
        src="https://forms.gle/u34PsZ6NzqRSP2Nz6"
        width="1140"
        height="1715"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        >Carregando…</iframe
      >
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../../components/FooterPref.vue";
import NavBarPref from "../../components/NavBarPref.vue";

export default {
  components: {
    NavBarPref,
    FooterPref,
  },
};
</script>

<style lang="scss">
.main-consulta-publica {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Raleway;
  text-align: initial;
}
</style>