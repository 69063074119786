<template>
  <div>
    <nav-bar-pref></nav-bar-pref>
    <b-container class="upload-publication-main">
      <link
        rel="stylesheet"
        href="../../node_modules/@fortawesome/fontawesome-free/css/all.css"
      />
      <div class="publication-edit-title col-md-12">
        <h1 class="initial">CONSULTA PÚBLICA DO DIAGNÓSTICO</h1>
        <span class="initial">Escolha um Tema e Nos Envie Uma Sugestão, Crítica</span>
      </div>
      <b-form @submit.prevent="send" class="form-publication">
        <b-form-group label-for="file">
          <div class="edit-wrap">
            <div class="col-md-6 col-sm-12">
              <span class="required-mark">* </span
              ><label class="form-label" for="type">Temas (Clique e Escolha)</label>
              <b-form-select
                @change="resetSub"
                required
                id="type"
                v-model="tagSelected"
              >
                <b-form-select-option
                  v-for="tag in surveyTags"
                  :key="tag.id"
                  :value="tag"
                  >{{ tag.name }}</b-form-select-option
                >
              </b-form-select>
            </div>
            <div
              v-if="tagSelected.subTag && tagSelected.subTag.length > 0"
              class="col-md-6 col-sm-12"
            >
              <span class="required-mark">* </span
              ><label class="form-label" for="user">Escolha</label>
              <b-form-select
                required
                id="user"
                v-model="survey.survey_sub_tag_id"
              >
                <b-form-select-option
                  v-for="subTag in tagSelected.subTag"
                  :key="subTag.id"
                  :value="subTag.id"
                  >{{ subTag.name }}</b-form-select-option
                >
              </b-form-select>
            </div>
          </div>
          <div class="edit-wrap">
            <div class="col-md-12">
              <span class="required-mark">* </span
              ><label class="form-label" for="title">Sugestão</label>
              <b-form-textarea
                id="title"
                placeholder="Escreva aqui"
                required
                v-model="survey.text"
              ></b-form-textarea>
            </div>
          </div>
          <div class="date-upload"></div>
          <div class="upload-buttons col-md-12">
            <b-button type="submit" variant="primary">Enviar</b-button>
          </div>
        </b-form-group>
      </b-form>
      <b-modal
        ref="response-modal"
        footer-bg-variant="light"
        ok-only
        :title="modalTitle"
        @ok="goBack()"
      >
        {{ modalText }}
      </b-modal>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../../components/FooterPref.vue";
import NavBarPref from "../../components/NavBarPref.vue";
import SurveyTagService from "../../services/SurveyTagService";
import SurveyService from "../../services/SurveyService";
export default {
  data() {
    return {
      tagSelected: {},
      subTag: {},
      survey: {
        text: "",
        survey_tag_id: "",
        survey_sub_tag_id: null,
      },
      surveyTags: [],
      modalTitle: "",
      modalText: ""
    };
  },
  components: {
    NavBarPref,
    FooterPref,
  },
  created() {
    SurveyTagService.list().then((res) => {
      this.surveyTags = res.data;
    });
  },
  methods: {
    send() {
      this.survey.survey_tag_id = this.tagSelected.id;
      SurveyService.store(this.survey)
        .then(() => {
          this.modalTitle = 'Enviado com sucesso'
          this.modalText=  'Sua sugestão foi enviada, obrigado'
          this.$refs["response-modal"].show();
        })
        .catch((err) => {
          this.modalTitle = 'Não foi possível enviar'
          this.modalText=  'Um problema ocorreu e não ecebemos sua sujestão'
          this.$refs["response-modal"].show();
          console.log(err);
        });
    },
    resetSub() {
      this.survey.survey_sub_tag_id = "";
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
</style>