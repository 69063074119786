import 'core-js';
import { http } from './config'

export default {

    update:(publicationImg) => {
        return http.put(`publication-images/${publicationImg.id}`, {
            publicationImg
        });
    },

    delete:(id) => {
        return http.delete(`publication-images/${id}`);
    },

}