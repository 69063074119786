<template>
  <div class="itr-container">
    <nav-bar-pref></nav-bar-pref>
    <b-container>
      <h1>Valor da Terra Nua</h1>
      <h3>Pauta de Imposto Territorial Rural (ITR)</h3>
      <b-col md="8" lg="8" sm="12">
        <ul class="doc-container">
          <li
            class="diribas-item hvr-shadow"
            v-for="itr in itrList"
            :key="itr.name"
            @click="openFile(itr.url)"
          >
            <p>Clique para visualizar o documento</p>
            <div class="file">
              <i class="far fa-file-pdf fa-3x"></i>
              <div class="file-information">
                <p>{{ itr.name }}</p>
              </div>
            </div>
          </li>
        </ul>
      </b-col>
      <h3>Pauta de Imposto Sobre a Transmissão de Bens Imóveis (ITBI)</h3>
      <b-col md="8" lg="8" sm="12">
        <ul class="doc-container">
          <li
            class="diribas-item hvr-shadow"
            v-for="itbi in itbiList"
            :key="itbi.name"
            @click="openFile(itbi.url)"
          >
            <p>Clique para visualizar o documento</p>
            <div class="file">
              <i class="far fa-file-pdf fa-3x"></i>
              <div class="file-information">
                <p>{{ itbi.name }}</p>
              </div>
            </div>
          </li>
        </ul>
      </b-col>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../components/FooterPref.vue";
import NavBarPref from "../components/NavBarPref.vue";
export default {
  data() {
    return {
      itrList: [
        {
          name: "Pauta de ITR 2012 2013 2014.pdf",
          url:
            "https://ribas-publications.s3-sa-east-1.amazonaws.com/5ce566831cbf644c652e3f67645206717714934f818f5_pauta-de-itr-2012-2013-2014.pdf",
        },
        {
          name: "Pauta de ITR 2015.pdf",
          url:
            "https://ribas-publications.s3-sa-east-1.amazonaws.com/5ce566948292cf4dad7889012c487caabbf9073ec44b0_pauta-de-itr-2015.pdf",
        },
        {
          name: "Pauta de ITR 2016.pdf",
          url:
            "https://ribas-publications.s3-sa-east-1.amazonaws.com/5ce566a0d6495a83b4089b281f17fdf99c9c39dbe5bbe_pauta-de-itr-2016.pdf",
        },
        {
          name: "Pauta de ITR 2017.pdf",
          url:
            "https://ribas-publications.s3-sa-east-1.amazonaws.com/5ce564eb29ab9924f193f9c6eda962c045e8a84d1443f_pauta-de-itr-2017.pdf",
        },
        {
          name: "Pauta de ITR 2018.pdf",
          url:
            "https://ribas-publications.s3-sa-east-1.amazonaws.com/5ce566adc92d30f90f7ba3ef41d4db981f45799ae8040_pauta-de-itr-2018.pdf",
        },
        {
          name: "Pauta de ITR 2019.pdf",
          url:
            "https://ribas-publications.s3-sa-east-1.amazonaws.com/5ce6994f566dc354e7bbe3f466c8e4a6296486a65a3fd_pauta-itr-2019.pdf",
        },
        {
          name: "Pauta de ITR 2020.pdf",
          url:
            "https://ribas-publications.s3-sa-east-1.amazonaws.com/5e95f06c92c1a57fcfd0afffe31a3ffa266a8d482ab0c_pauta-itr-2020.pdf",
        },
        {
          name: "Pauta de ITR 2021.pdf",
          url:
            "https://ribas-publications.s3-sa-east-1.amazonaws.com/Pauta+de+ITR+2021.pdf",
        },
        {
          name: "Pauta de ITR 2022.pdf",
          url:
            "https://ribas-publications.s3.sa-east-1.amazonaws.com/141-2022+-+VTN+-+Receita.pdf",
        },
        {
          name: "Pauta de ITR 2023.pdf",
          url:
            "https://ribas-publications.s3.sa-east-1.amazonaws.com/120-2023+-+VTN+-+Receita.pdf",
        },
        {
          name: "Pauta de ITR 2024.pdf",
          url:
            "https://ribas-publications.s3.sa-east-1.amazonaws.com/VTN_R.doR.Pardo-MS_2024.jpg",
        },
      ],
      itbiList: [
        {
          name: "Pauta ITBI 2019.pdf",
          url:
            "https://ribas-publications.s3-sa-east-1.amazonaws.com/5ce695c7e7dd58dbef281594dd900af1a0dc8bf455188_pauta-itbi-2019.pdf",
        },
        {
          name: "Pauta ITBI 2020.pdf",
          url:
            "https://ribas-publications.s3-sa-east-1.amazonaws.com/PAUTA+ITBI+2020.pdf",
        },
        {
          name: "Pauta ITBI 2022.pdf",
          url:
            "https://ribas-publications.s3.sa-east-1.amazonaws.com/Pauta+ITBI+2022.pdf",
        },
      ],
    };
  },
  components: {
    NavBarPref,
    FooterPref,
  },
  methods: {
    openFile(url) {
      window.open(url, "_blank");
    },
  }
};
</script>
<style lang="scss">
.itr-container {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  h1 {
    margin-top: 20px;
  }
  h1,
  h3 {
    text-align: initial;
    color: black;
    font-family: Raleway;
    font-weight: bold;
  }
  h3 {
    margin-top: 30px;
  }
  .doc-container {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: unset;
    li {
      &:hover {
        cursor: pointer;
      }
      &:hover .file-information p {
        color: #204991;
      }
    }
    .diribas-item {
      flex-grow: 1;
      text-align: justify;
      margin-top: 25px;
      border-top: 1px solid rgb(185, 185, 185);
      margin-right: 1%;
      @media (max-width: 425px) {
        width: calc(100% * (1 / 1) - 10px - 1px);
      }
      width: calc(100% * (1 / 2) - 10px - 1px);
      small {
        font-weight: bold;
      }
      p {
        text-align: initial;
      }
      .file {
        display: flex;
        i {
          color: red;
        }
        p {
          margin-bottom: unset;
          text-align: initial;
        }
        .file-information {
          width: 100%;
          margin: auto auto auto 5%;
          p {
            transition: all 0.4s ease;
          }
        }
      }
    }
  }
  .footer {
    margin-top: 30px;
  }
}
</style>