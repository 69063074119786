<template>
  <div class="publication-view-container">
    <nav-bar-pref></nav-bar-pref>
    <b-container class="publication-container">
      <b-col>
        <b-col>
          <h1 class="initial">{{ publication.title }}</h1>
          <h2 class="initial">{{ publication.meta_description }}</h2>
        </b-col>
        <b-col class="author-row d-flex">
          <div class="col name-date">
            <p>
              Publicado por: <b>{{ publication.user.name }}</b>
            </p>
            <small>{{ dateFormated }}</small>
          </div>
          <div
            class="player-wrapper"
            v-if="publication.PublicationAudios.length > 0"
          >
            <audio-player
              :file="publication.PublicationAudios[0].url"
            ></audio-player>
          </div>
        </b-col>
        <b-col v-if="publication.body" style="margin-bottom: 20px">
          <hr class="publication-separator" />
        </b-col>
        <b-col class="publication-img" v-if="mainImg && !mainImg.video_url">
          <expandable-image
            :close-on-background-click="true"
            :src="mainImg.url"
          />
          <small>{{ mainImg.description }}</small>
        </b-col>
        <b-col class="publication-img" v-if="mainImg && mainImg.video_url">
          <youtube :video-id="getVideoId(mainImg.video_url)" ref="youtube">
          </youtube>
        </b-col>
        <b-col md="12" class="publication-body">
          <div
            v-if="publication.body.match(/<\/?[a-z][\s\S]*>/i)"
            v-html="publication.body"
          ></div>
          <p v-else>{{ publication.body }}</p>
        </b-col>
        <b-col class="view-carousel">
          <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            img-width="1024"
            img-height="200"
            style="text-shadow: 1px 1px 2px #333"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide
              v-for="img in publication.carouselImgs"
              :key="img.key"
            >
              <expandable-image
                :close-on-background-click="true"
                slot="img"
                :src="img.url"
                alt="image slot"
              />
            </b-carousel-slide>
          </b-carousel>
        </b-col>
        <b-col
          v-if="(isEdict && userLogged) || !userLogged || !logCreated"
          md="12"
          class="attachment-list"
        >
          <ul class="doc-container-edict">
            <li
              class="edict-item"
              v-for="edict in publication.PublicationAttachments"
              :key="edict.id"
            >
              <p>Clique para visualizar o documento</p>
              <div class="file">
                <i :class="findIcon(getFileExt(edict.name))"></i>
                <div class="file-information">
                  <p>{{ edict.name }}</p>
                  <small> {{ formatFileSize(edict.size) }} </small>
                  <div class="file-buttons">
                    <b-button
                      @click="download(edict.id)"
                      variant="outline-primary"
                      ><i class="fas fa-download"></i>Download</b-button
                    >
                    <b-button
                      @click="openFile(edict.url)"
                      variant="outline-secondary"
                      ><i class="fas fa-eye"></i>Abrir</b-button
                    >
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </b-col>
        <!-- <b-col v-if="isEdict && edictLogged && !logCreated && !userLogged">
          <div class="edict-buttons">
            <b-button class="primary" @click="createLog"
              >Clique para visualizar os documentos</b-button
            >
          </div>
        </b-col>
        <b-col v-if="isEdict && !edictLogged && !userLogged">
          <div class="edict-info">
            <b-alert show class="alert-edict">
              <i class="fas fa-info-circle fa-2x"></i>
              <span
                >Para visualizar os arquivos clique no botão abaixo e preencha
                os dados</span
              >
              <div class="edict-buttons">
                <b-button class="primary" @click="showModal"
                  >Clique aqui</b-button
                >
                <b-button :to="{ path: '/login' }">Servidor</b-button>
              </div>
            </b-alert>
          </div>
        </b-col> -->
        <b-modal
          id="modal-prevent-closing"
          ref="modal"
          title="Informe seus dados"
          footer-bg-variant="light"
          cancel-variant="outline-danger"
          cancel-title="Cancelar"
          @ok="handleOk"
          @show="resetModal"
        >
          <b-form
            class="edict-form"
            ref="form"
            @submit.stop.prevent="handleSubmit"
          >
            <div class="edict-wrap">
              <div class="col-md-12">
                <span class="required-mark">* </span
                ><label class="form-label-edict" for="type">Escolha</label>
                <b-form-select
                  @change="setPhysical"
                  required
                  id="type"
                  v-model="edictLog.userType"
                >
                  <b-form-select-option
                    v-for="type in userTypes"
                    :key="type.id"
                    :value="type"
                    >{{ type.name }}</b-form-select-option
                  >
                </b-form-select>
                <b-form-invalid-feedback :state="typeState">
                  Selecione uma das opções
                </b-form-invalid-feedback>
              </div>
            </div>
            <div v-if="edictLog.userType.name" class="edict-wrap">
              <div class="col-md-12">
                <span class="required-mark">* </span
                ><label class="form-label-edict" for="name">{{
                  isPhysical ? "Nome completo" : "Razão Social"
                }}</label>
                <b-form-input
                  id="name"
                  :placeholder="
                    isPhysical
                      ? 'Informe seu nome completo'
                      : 'Informe a razão social'
                  "
                  required
                  v-model="edictLog.name"
                ></b-form-input>
                <b-form-invalid-feedback :state="nameState">
                  Você deve preencher este campo
                </b-form-invalid-feedback>
              </div>
            </div>
            <div v-if="edictLog.userType.name" class="edict-wrap">
              <div class="col-md-12">
                <span class="required-mark">* </span
                ><label class="form-label-edict" for="cpf">{{
                  isPhysical ? "CPF" : "CNPJ"
                }}</label>
                <b-form-input
                  id="cpf"
                  :placeholder="isPhysical ? 'Nº CPF' : 'Nº CNPJ'"
                  v-model="edictLog.document"
                  required
                  v-mask="isPhysical ? '###.###.###-##' : '##.###.###/####-##'"
                ></b-form-input>
                <b-form-invalid-feedback :state="documentState">
                  Nº do documento inválido
                </b-form-invalid-feedback>
              </div>
            </div>
          </b-form>
        </b-modal>
        <b-col>
          <hr class="publication-separator" />
        </b-col>
        <b-col
          class="related-label"
          v-if="
            publication.publication_type.name == 'Notícia' && related.length > 0
          "
        >
          <span class="related">Veja Mais Artigos Relacionados:</span>
        </b-col>
        <b-col
          v-if="publication.publication_type.name == 'Notícia'"
          class="related-card"
        >
          <b-card
            v-for="publication in related"
            :key="publication.id"
            :img-src="
              publication.PublicationImgs[0] &&
              publication.PublicationImgs[0].url
                ? publication.PublicationImgs[0].url
                : require('../.././assets/noimg.png')
            "
            img-width="165"
            :img-height="isMobile ? 250 : 165"
            :img-left="imgLeft"
            class="mb-3 hvr-shadow"
            @click="
              refresh('publication-view', publication.id, publication.title)
            "
          >
            <b-col md="11">
              <div class="tag-wrapper">
                <small
                  v-if="publication.tags && publication.tags.length > 0"
                  class="tag"
                  >{{ publication.tags[0].name }}</small
                >
                <small class="publication-date">{{
                  formatDate(publication.createdAt, publication.date)
                }}</small>
              </div>
              <b-card-title>
                {{ publication.title }}
              </b-card-title>
              <b-card-text v-if="showBody">
                {{ publication.meta_description || "Saiba mais clicando aqui" }}
              </b-card-text>
            </b-col>
            <b-col v-if="!isMobile" md="1" class="icon-right hvr-icon-forward">
              <i class="fas fa-angle-right hvr-icon fa-2x"></i>
            </b-col>
          </b-card>
        </b-col>
      </b-col>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../../components/FooterPref";
import NavBarPref from "../../components/NavBarPref";
import AudioPlayer from "../../components/AudioPlayer";
import PublicationService from "../../services/PublicationService";
import UserTypeService from "../../services/UserTypeService";
import EdictLogService from "../../services/EdictLogService";
import moment from "moment";
import { isMobile } from "mobile-device-detect";
import filesize from "filesize";
import { mask } from "vue-the-mask";
import { cpf, cnpj } from "cpf-cnpj-validator";
import UploadService from "../../services/UploadService";
import _ from "lodash";

export default {
  data() {
    return {
      publication: {},
      related: [],
      dateFormated: "",
      imgLeft: true,
      showBody: true,
      tagId: "",
      mainImg: null,
      sliding: null,
      slide: 0,
      isEdict: false,
      isPhysical: false,
      userLogged: false,
      edictLogged: false,
      logCreated: false,
      nameState: "",
      documentState: "",
      typeState: "",
      userTypes: [],
      edictLog: {
        name: "",
        userType: "",
        user_type_id: "",
        document: "",
        publication_id: "",
      },
      iconList: [
        {
          extension: "jpg",
          icon: "far fa-image fa-3x",
        },
        {
          extension: "png",
          icon: "far fa-image fa-3x",
        },
        {
          extension: "jpeg",
          icon: "far fa-image fa-3x",
        },
        {
          extension: "heif",
          icon: "far fa-image fa-3x",
        },
        {
          extension: "heic",
          icon: "far fa-image fa-3x",
        },
        {
          extension: "doc",
          icon: "far fa-file-word fa-3x",
        },
        {
          extension: "docx",
          icon: "far fa-file-word fa-3x",
        },
        {
          extension: "xls",
          icon: "far fa-file-excel fa-3x",
        },
        {
          extension: "xlsx",
          icon: "far fa-file-excel fa-3x",
        },
        {
          extension: "rar",
          icon: "far fa-file-archive fa-3x",
        },
        {
          extension: "zip",
          icon: "far fa-file-archive fa-3x",
        },
        {
          extension: "spe",
          icon: "far fa-file-archive fa-3x",
        },
      ],
      ogImg: "",
    };
  },
  metaInfo() {
    return {
      title: this.publication.title,
      htmlAttrs: {
        lang: "pt-BR",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          property: "og:description",
          content: this.publication.meta_description,
        },
        { property: "og:image", content: this.ogImg },
      ],
    };
  },
  components: {
    NavBarPref,
    FooterPref,
    AudioPlayer,
  },
  directives: { mask },
  created() {
    PublicationService.listById(this.$route.params.id).then((res) => {
      this.publication = res.data;
      this.edictLog.publication_id = this.publication.id;
      this.isEdict = this.publication.publication_type.name == "Edital";
      this.userLogged = localStorage.getItem("userId");
      this.edictLogged = localStorage.getItem("edictUser");
      this.publication.PublicationAttachments = _.orderBy(
        this.publication.PublicationAttachments,
        ["position", "desc"]
      );
      if (this.isEdict && !this.userLogged) {
        UserTypeService.list().then((res) => {
          this.userTypes = res.data;
        });
      }
      this.dateFormated = moment(res.data.date).format("DD/MM/YYYY");

      let img = this.publication.PublicationImgs.filter((img) => {
        return img.main;
      });
      this.ogImg = img.length > 0 ? img[0].url : "";
      if (img.length > 0) this.mainImg = img[0];

      this.publication.carouselImgs = [];
      this.publication.PublicationImgs.forEach((img) => {
        if (!img.main) this.publication.carouselImgs.push(img);
        return;
      });
      this.publication.carouselImgs = _.orderBy(this.publication.carouselImgs, [
        "position",
        "desc",
      ]);
      this.tagId =
        this.publication.tags && this.publication.tags.length > 0
          ? this.publication.tags[0].id
          : null;
      PublicationService.listByType(
        this.publication.PublicationTypeId,
        this.tagId,
        this.publication.id
      ).then((res) => {
        this.related = res.data;
        this.related.forEach((publication) => {
          publication.PublicationImgs = publication.PublicationImgs.filter(
            (img) => {
              return img.main;
            }
          );
        });
      });
    });
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    refresh(name, id, title) {
      title = title.replace(/[\s$-/:-?{-~!"^_`[\]]/g, "-").toLowerCase();
      title = title
        .normalize("NFD")
        .replace(/[\u0300-\u036f\u00aa\u00ba]/g, "");
      this.$router.push({
        name,
        params: {
          id,
          title,
        },
      });
    },
    resetModal() {
      this.edictLog.name = "";
      this.edictLog.userType = "";
      this.edictLog.document = "";
    },
    getVideoId(url) {
      return this.$youtube.getIdFromUrl(url);
    },
    setPhysical() {
      this.isPhysical = this.edictLog.userType.name == "Pessoa Física";
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.edictLog.user_type_id = this.edictLog.userType.id;
      this.edictLog.name = this.edictLog.name.toUpperCase();
      EdictLogService.store(this.edictLog).then(() => {
        localStorage.setItem("edictUser", this.edictLog.name);
        localStorage.setItem("edictDocument", this.edictLog.document);
        localStorage.setItem("user_type_id", this.edictLog.user_type_id);
        this.userLogged = true;
      });

      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    checkFormValidity() {
      this.typeState = this.edictLog.userType.name ? true : false;
      this.nameState = this.edictLog.name != "" ? true : false;
      this.documentState = this.isPhysical
        ? cpf.isValid(this.edictLog.document)
        : cnpj.isValid(this.edictLog.document);

      return this.typeState && this.nameState && this.documentState;
    },
    createLog() {
      const log = {
        name: localStorage.getItem("edictUser"),
        document: localStorage.getItem("edictDocument"),
        user_type_id: localStorage.getItem("user_type_id"),
        publication_id: this.publication.id,
      };
      EdictLogService.store(log).then(() => {
        this.logCreated = true;
      });
    },
    showModal() {
      this.$refs["modal"].show();
    },
    handleResize() {
      window.innerWidth > 768 ? (this.imgLeft = true) : (this.imgLeft = false);
      window.innerWidth > 991
        ? (this.showBody = true)
        : (this.showBody = false);
    },
    formatDate(createdAt, date) {
      if (date) return moment(date).format("DD/MM/YYYY");

      return moment(createdAt).format("DD/MM/YYYY");
    },
    formatFileSize(size) {
      return filesize(size);
    },
    openFile(url) {
      window.open(url, "_blank");
    },
    download(id) {
      UploadService.downloadAttachment(id).then((res) => {
        window.open(res.data);
      });
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    findIcon(extension) {
      let foundIcon = this.iconList.find((item) => item.extension == extension);

      if (foundIcon) return foundIcon.icon;

      return "far fa-file-pdf fa-3x";
    },
    getFileExt(name) {
      const lastDot = name.lastIndexOf(".");

      const ext = name.substring(lastDot + 1);
      return ext;
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        location.reload();
      }
    },
  },
  computed: {
    isMobile() {
      return isMobile;
    },
  },
};
</script>
<style lang="scss">
.col {
  @media (max-width: 992px) {
    padding-left: 5px;
    padding-right: 5px;
  }
}
table {
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  overflow: hidden;

  td,
  th {
    min-width: 1em;
    border: 2px solid #ced4da;
    padding: 3px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;

    > * {
      margin-bottom: 0;
    }
  }

  th {
    font-weight: bold;
    text-align: left;
    background-color: #f1f3f5;
  }

  .selectedCell:after {
    z-index: 2;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(200, 200, 255, 0.4);
    pointer-events: none;
  }

  .column-resize-handle {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: -2px;
    width: 4px;
    background-color: #adf;
    pointer-events: none;
  }

  p {
    margin: 0;
  }
}
.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
.edict-form {
  .form-label-edict {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 15px;
    color: #929292;
  }
  .edict-wrap {
    display: grid;
    margin-bottom: 20px;
    align-items: center;
    text-align: initial;
    input {
      text-transform: uppercase;
    }
    ::-webkit-input-placeholder {
      text-transform: none;
    }
    :-moz-placeholder {
      text-transform: none;
    }
    ::-moz-placeholder {
      text-transform: none;
    }
    :-ms-input-placeholder {
      text-transform: none;
    }
    ::placeholder {
      text-transform: none;
    }
    @media (max-width: 768px) {
      display: grid;
      margin-bottom: 10px;
      .form-label {
        margin-top: 10px;
      }
    }
    .required-mark {
      color: #fc2c03;
    }
  }
}
.publication-view-container {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;

  .publication-container {
    .initial {
      text-align: initial;
    }
    .related-label {
      margin-bottom: 20px;
      text-align: initial;
    }
    .author-row {
      text-align: justify;
      margin-top: 2rem;
      padding-left: 0;
      p {
        margin: unset;
      }
      @media (max-width: 992px) {
        flex-direction: column;
      }
      .player-wrapper {
        @media (max-width: 992px) {
          margin-top: 10px;
        }
      }
    }
    h1 {
      color: black;
      font-family: Raleway;
      font-weight: bold;
      margin-top: 50px;
      @media (max-width: 768px) {
        font-size: 30px;
      }
    }
    h2 {
      margin-top: 1.5rem;
      font-size: 1.25rem;
      color: #555;
    }
    .publication-separator {
      border: 0;
      height: 1px;
      background: #ccc;
      width: 100%;
    }
    .related {
      font-weight: bold;
      margin-bottom: 15px;
      font-size: 20px;
      font-family: Raleway;
    }
    .publication-img {
      margin-bottom: 20px;
      // text-align: initial;
      small {
        word-break: break-word;
      }
      .expandable-image {
        img {
          margin-bottom: 30px;
          max-height: 600px;
          margin: auto;
          max-width: 100%;
          margin-bottom: 5px;
          width: inherit;
          @media (max-width: 767px) {
            max-height: 300px;
          }
        }
      }
      iframe {
        width: 90%;
        height: 530px;
        @media (max-width: 1200px) {
          width: 100%;
        }
        @media (max-width: 995px) {
          height: 360px;
        }
        @media (max-width: 768px) {
          height: 220px;
        }
      }
    }
    .publication-body {
      text-align: justify;
      @media (max-width: 992px) {
        padding-left: 5px;
        padding-right: 5px;
      }
      img {
        display: block;
        margin-bottom: 30px;
        max-height: 600px;
        margin: auto;
        max-width: 100%;
        margin-bottom: 5px;
        width: inherit;
        @media (max-width: 767px) {
          max-height: 300px;
        }
      }
      p {
        font-size: 20px;
        text-align: justify;
        word-break: break-word;
        img {
          margin-bottom: 30px;
          max-height: 600px;
          margin: auto;
          max-width: 100%;
          margin-bottom: 5px;
          width: inherit;
          @media (max-width: 767px) {
            max-height: 300px;
          }
        }
      }
    }
    .related-card {
      .card {
        .card-body {
          .card-title {
            text-align: initial;
          }
        }
      }
    }
    .view-carousel {
      .carousel-item {
        img {
          max-height: 600px;
          min-height: 600px;
          margin: auto;
          max-width: 100%;
          @media (max-width: 767px) {
            max-height: 300px;
            min-height: 300px;
          }
          object-fit: cover;
        }
      }
    }
    .card {
      min-width: 100%;
      img {
        min-height: 165px;
        object-fit: cover;
        min-width: 180px;
        @media (max-width: 768px) {
          min-height: 330px;
        }
      }
      .card-body {
        text-align: justify;
        display: flex;
        &:hover {
          cursor: pointer;
        }
        .icon-right {
          display: block;
          margin: auto;
          text-align: center;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
      .tag-wrapper {
        margin-bottom: 10px;
        .tag {
          background-color: #3265bf;
          color: white;
          font-weight: bold;
          padding: 2px;
          border-radius: 5px;
          margin-right: 10px;
        }
        .publication-date {
          font-weight: bold;
        }
      }
    }
    .edict-buttons {
      margin-top: 20px;
      margin-bottom: 40px;
      .primary {
        background-color: #204991;
      }
    }
    .attachment-list {
      .doc-container-edict {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        padding: unset;
        li {
          &:hover .file-information p {
            color: #204991;
          }
        }
        .edict-item {
          flex-grow: 1;
          text-align: justify;
          margin-top: 25px;
          border-top: 1px solid rgb(185, 185, 185);
          margin-right: 1%;
          @media (max-width: 990px) {
            width: calc(100% * (1 / 2) - 10px - 1px);
          }
          @media (max-width: 768px) {
            width: calc(100% * (1 / 1) - 10px - 1px);
          }
          width: calc(100% * (1 / 2) - 10px - 1px);
          small {
            font-weight: bold;
          }
          .file {
            display: flex;
            i {
              color: red;
            }
            p {
              margin-bottom: unset;
            }
            .file-information {
              width: 100%;
              margin-left: 5%;
              p {
                transition: all 0.4s ease;
                word-break: break-word;
                text-align: initial;
              }
              .file-buttons {
                margin-top: 5px;
                button {
                  &:first-child {
                    margin-right: 5px;
                  }
                }
                i {
                  margin-right: 5px;
                  color: inherit;
                }
              }
            }
          }
        }
      }
    }
    .edict-info {
      margin-top: 20px;
      margin-bottom: 40px;
      .alert-edict {
        display: grid;
        .edict-buttons {
          margin-top: 5px;
          margin-bottom: 0px;
        }
      }
    }
    .hvr-icon-forward {
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(3px) translateZ(0);
      transform: perspective(3px) translateZ(0);
      box-shadow: 0 0 3px rgba(0, 0, 0, 0);
      -webkit-transition-duration: 0.1s;
      transition-duration: 0.1s;
    }
    .hvr-icon-forward .hvr-icon {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-transition-duration: 0.1s;
      transition-duration: 0.1s;
      -webkit-transition-property: transform;
      transition-property: transform;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    .hvr-icon-forward:hover .hvr-icon,
    .hvr-icon-forward:focus .hvr-icon,
    .hvr-icon-forward:active .hvr-icon,
    .card-body:hover .hvr-icon {
      -webkit-transform: translateX(10px);
      transform: translateX(10px);
    }
    .hvr-shadow {
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: box-shadow;
      transition-property: box-shadow;
    }
    .hvr-shadow:hover,
    .hvr-shadow:focus,
    .hvr-shadow:active {
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    }
    blockquote {
      border-left: 5px solid #ccc;
      margin: 1.5em 10px;
      padding: 0.5em 10px;
      quotes: "\201C""\201D""\2018""\2019";
    }
    blockquote p {
      display: inline;
    }
  }
  .footer {
    margin-top: 30px;
  }
}
</style>