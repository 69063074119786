<template>
  <div class="news-config" :class="collapsed ? 'collapsed' : 'non-collapsed'">
    <nav-bar-dashboard></nav-bar-dashboard>
    <sidebar-dashboard></sidebar-dashboard>
    <b-container fluid>
      <div class="dash-title">
        <h1>CONFIGURAÇÕES - NOTÍCIAS</h1>
      </div>
      <div class="col">
        <h3>
          As notícias nos cards da página inicial serão mostradas de acordo com
          as tags selecionadas
        </h3>
      </div>
      <div class="news-config-form-wrap">
        <b-form @submit.prevent="send" class="news-config-form">
          <div class="news-form-wrap">
            <div class="col-md-6 col-sm-12">
              <label class="form-label" for="firstTags"
                >Tags para o primeiro card</label
              >
              <multiselect
                id="firstTags"
                v-model="firstCard.tags"
                :options="tags"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Palavras-chave da publicação"
                label="name"
                track-by="name"
                :preselect-first="true"
              >
              </multiselect>
            </div>
          </div>
          <div class="news-form-wrap">
            <div class="col-md-6 col-sm-12">
              <label class="form-label" for="secondTags"
                >Tags para o segundo card</label
              >
              <multiselect
                id="secondTags"
                v-model="secondCard.tags"
                :options="tags"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Palavras-chave da publicação"
                label="name"
                track-by="name"
                :preselect-first="true"
              >
              </multiselect>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 config-save-button">
            <b-button type="submit" variant="primary">Salvar</b-button>
          </div>
        </b-form>
      </div>
      <b-modal
        ref="response-modal"
        footer-bg-variant="light"
        ok-only
        :title="modalTitle"
      >
        <div>
          <span>{{ modalText }}</span>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import SidebarDashboard from "../../../components/SidebarDashboard";
import "vue-good-table/dist/vue-good-table.css";
import NavBarDashboard from "../../../components/NavBarDashboard.vue";
import TagService from "../../../services/TagService";
import PublicationCardConfigService from "../../../services/PublicationCardConfigService";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      collapsed: true,
      firstCard: "",
      secondCard: "",
      tags: [],
      modalTitle: "",
      modalText: "",
    };
  },
  created() {
    TagService.list().then((res) => {
      this.tags = res.data;
    });
    PublicationCardConfigService.listByCardNumber(1).then((res) => {
      this.firstCard = res.data;
    });
    PublicationCardConfigService.listByCardNumber(2).then((res) => {
      this.secondCard = res.data;
    });
  },
  mounted() {
    this.$root.$on("collapse", this.collapse);
  },
  components: {
    SidebarDashboard,
    NavBarDashboard,
    Multiselect,
  },
  methods: {
    collapse() {
      this.collapsed = !this.collapsed;
    },
    showModal() {
      this.$refs["response-modal"].show();
    },
    hideModal() {
      this.$refs["response-modal"].hide();
    },
    send() {
      Promise.all([
        PublicationCardConfigService.update(this.firstCard),
        PublicationCardConfigService.update(this.secondCard),
      ])
        .then(() => {
          this.modalTitle = "Sucesso";
          this.modalText = 'As configurações foram salvas';
          this.showModal();
        })
        .catch(() => {
          this.modalTitle = "Ops";
          this.modalText = 'Um erro ocorreu e as configurações não foram salvas'
          this.showModal();
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.collapsed {
  padding-left: 50px;
  @media (max-width: 1400px) {
    padding-left: 0;
  }
}
.non-collapsed {
  padding-left: 350px;
  @media (max-width: 1400px) {
    padding-left: 0;
  }
}
.news-config {
  text-align: initial;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Raleway;
  transition: 0.3s ease;
  margin-right: 80px;
  margin-left: 80px;
  z-index: 1;
  @media (max-width: 700px) {
    margin-right: 0;
    margin-left: 60px;
  }

  .dash-title {
    margin-top: 100px;
    margin-left: 0;
    text-align: initial;
    font-family: Ubuntu;
    font-weight: bold;
    color: #707070;
    margin-bottom: 30px;
    @media (max-width: 700px) {
      font-size: 2rem;
    }
  }

  h3 {
    font-family: Raleway;
    font-size: 1.5rem;
  }

  .news-config-form {
    text-align: initial;
    .form-label {
      font-weight: bold;
      margin-bottom: 0;
      font-size: 15px;
      color: #929292;
    }
    .news-form-wrap {
      margin-bottom: 40px;
    }
    .config-save-button {
      text-align: end;
    }
  }
  .modal-dialog {
    max-width: 1000px;
  }
}
</style>