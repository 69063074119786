import 'core-js';
import { http } from './config'

export default {
    store:(tag) => {
        return http.post('survey-tags', {
            ...tag
        });
    },

    list:() => {
        return http.get('survey-tags');
    },

    listById:(id) => {
        return http.get(`survey-tags/${id}`);
    },

    listPaginate:(params) => {
        return http.get('survey-tags-paginate', {
            params
        });
    },

    update:(tag) => {
        return http.put(`survey-tags/${tag.id}`, {
            ...tag
        });
    },

    delete:(id) => {
        return http.delete(`survey-tags/${id}`);
    }

}