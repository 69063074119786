<template>
  <div class="diribas-notice">
    <nav-bar-pref></nav-bar-pref>
    <b-container>
      <b-col>
        <h1>Avisos Diário Oficial</h1>
      </b-col>
      <div :class="loading ? 'show' : 'hide'">
          <loader
            object="#ff9633"
            color1="#ffffff"
            color2="#17fd3d"
            size="5"
            speed="2"
            bg="#cccccc"
            objectbg="#999793"
            opacity="80"
            name="dots"
          ></loader>
        </div>
      <div class="edit-wrap">
        <div class="col-md-12">
          <label class="form-label" for="carrosel"
            >Imagens para avisos Diribas</label
          >
          <b-form-file
            id="carrosel"
            multiple
            placeholder="Arraste as imagens ou clique para selecionar"
            v-model="imgs"
            @change="imgsUpload"
          >
          </b-form-file>
        </div>
      </div>
      <div class="edit-wrap" style="margin-bottom: 0">
        <div class="col-md-12">
          <small><b>Clique e arraste para alterar a posição</b></small>
        </div>
      </div>
      <div class="edit-wrap">
        <div class="col-md-12">
          <draggable
            v-model="notices"
            ghost-class="ghost"
            @end="onEnd"
            :disabled="buttonDisabled"
          >
            <transition-group type="transition" name="flip-list">
              <div class="sortable" v-for="img in notices" :key="img.url">
                <div class="drag-wrap">
                  <img :src="img.url" /><span>{{ img.name }}</span>
                </div>
                <div>
                  <b-button
                    @click="removeImg(img)"
                    title="Remover imagem"
                    variant="outline-danger"
                    ><i class="far fa-trash-alt remove-icon"></i
                  ></b-button>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import DiribasService from "../../../services/DiribasService";
import draggable from "vuedraggable";
import FooterPref from "../../../components/FooterPref.vue";
import NavBarPref from "../../../components/NavBarPref.vue";

export default {
  data() {
    return {
      notices: [],
      imgs: [],
      imgsPosition: [],
      imgIndex: 0,
      oldIndex: "",
      newIndex: "",
      buttonDisabled: false,
      loading: false,
    };
  },
  components: {
    draggable,
    NavBarPref,
    FooterPref,
  },
  created() {
    DiribasService.findAllNotice().then((res) => {
      this.notices = res.data;
      this.imgsPosition = res.data;
    });
  },
  methods: {
    removeImg(img) {
      this.buttonDisabled = true;
      DiribasService.removeNotice(img.id).then(() => {
        this.buttonDisabled = false;
        let indexPosition = this.imgsPosition.indexOf(img);
        if (indexPosition !== -1) {
          this.imgsPosition.splice(indexPosition, 1);
        }
        let index = this.imgs.indexOf(img);
        if (index !== -1) {
          this.imgs.splice(index, 1);
        }
        this.imgs.map((img, index) => {
          img.position = index;
          DiribasService.updateNotice(img).then(() => {});
        });
      });
    },
    onEnd(evt) {
      this.oldIndex = evt.oldIndex;
      this.newIndex = evt.newIndex;
      let next = this.imgsPosition;
      let moved = next.splice(this.oldIndex, 1);
      next.splice(this.newIndex, 0, moved[0]);
      this.setPositions(next);
    },
    setPositions(arr) {
      arr.map((img, index) => {
        img.position = index;
        DiribasService.updateNotice(img).then(() => {});
      });
    },
    imgsUpload(e) {
      this.loading = true;
      let fileList = Array.from(e.target.files);
      for (const img of fileList) {
        this.uploadFiles(img);
      }
      this.loading = false
    },
    uploadFiles(file) {
      const formData = new FormData();
      formData.append("file", file);
      this.imgIndex = this.imgsPosition.length;
      formData.append("position", this.imgs.length);
      DiribasService.storeNotice(formData).then((res) => {
        this.imgs.push(res.data);
        this.imgsPosition.push(res.data);
        res.data.position = this.imgIndex;
        DiribasService.updateNotice(res.data).then(() => {});
        this.imgIndex++;
      });
    },
  },
};
</script>
<style lang="scss">
.diribas-notice {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  h1 {
    color: black;
    font-family: Raleway;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }
  .edit-wrap {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      text-align: initial;
      @media (max-width: 768px) {
        display: grid;
        margin-bottom: 10px;
        .form-label {
          margin-top: 10px;
        }
      }
    }
  .sortable-drag {
    opacity: 0;
  }
  .sortable {
    cursor: move;
    margin-bottom: 10px;
    background-color: #fbfbfb;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    span {
      font-weight: bold;
    }
    i {
      margin-right: 5px;
    }
    .remove-icon {
      margin-right: 0;
    }
    .drag-wrap {
      display: flex;
      align-items: center;
    }
    img {
      height: 150px;
      width: 180px;
      margin-right: 10px;
      object-fit: cover;
      @media (max-width: 769px) {
        height: 100px;
        width: 130px;
      }
    }
    button {
      margin-right: 10px;
    }
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .ghost {
    border-left: 6px solid #204991;
    box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.14);
    opacity: 0.7;
  }
}
</style>