<template>
  <div class="main-services">
    <nav-bar-pref></nav-bar-pref>
    <b-container>
      <div>
        <h1>Portal de Serviços</h1>
      </div>
      <hr />
      <div
        class="service-list-container"
        v-for="service in services"
        :key="service.title"
      >
        <span class="service-title">{{ service.title }}</span>
        <a @click="goTo(service.link)"> {{ service.link }} </a>
      </div>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../components/FooterPref.vue";
import NavBarPref from "../components/NavBarPref.vue";
export default {
  data() {
    return {
      services: [
        {
          title: "Holerite Web",
          link: "http://45.174.220.242:8056/sipweb/trabalhador/login/login.xhtml",
        },
        {
          title: "Portal Da Transparência",
          link: "http://45.174.220.242:8079/transparencia",
        },
        {
          title: "RH",
          link: "http://45.174.220.242:8087/sip/",
        },
      ],
    };
  },
  methods: {
    goTo(link) {
      window.open(link, "_blank");
    },
  },
  components: {
    FooterPref,
    NavBarPref,
  },
};
</script>

<style lang="scss">
.main-services {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Raleway;
  text-align: initial;
  h1 {
    color: black;
    font-family: Raleway;
    font-weight: bold;
    margin-top: 50px;
    text-align: initial;
  }
  .service-list-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    a {
      color: #007bff;
    }
    a:hover {
      cursor: pointer;
      color: #2f00ff;
    }
    .service-title {
      font-weight: bold;
    }
  }
}
</style>