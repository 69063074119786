<template>
  <div id="Updates">
    <nav-bar-pref></nav-bar-pref>
    <div class="main-container">
    </div>
    <footer-pref></footer-pref>
  </div>
</template>

<script>
import FooterPref from "../../components/FooterPref";
import NavBarPref from "../../components/NavBarPref";

export default {
  name: "Updates",
  data() {
    return {};
  },

  components: {
    FooterPref,
    NavBarPref,
  },

  mounted() {},
};
</script>

<style lang="scss">
#Updates {
  width: 100%;
  height: 95%;

  .main-container {
    width: 100%;
    height: 100%;
  } 
}
</style>