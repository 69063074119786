<template>
  <div>
    <nav-bar-pref></nav-bar-pref>
    <b-container class="main-covid">
      <link
        rel="stylesheet"
        href="../../node_modules/@fortawesome/fontawesome-free/css/all.css"
      />
      <h1>COVID-19</h1>
      <p>
        A doença do coronavírus (COVID-19) é uma doença infecciosa causada por
        um coronavírus recém-descoberto. A maioria das pessoas que adoece em
        decorrência da COVID-19 apresentará sintomas leves a moderados e se
        recuperará sem tratamento especial.
      </p>
      <div class="boletins">
        <h3>Boletins Covid-19 - Últimos Três Dias</h3>
        <p>
          Acompanhe aqui os últimos três boletins referentes à COVID-19, para
          visualizar todos você pode acessá-los através do botão "Boletins" abaixo
        </p>
        <div class="boletim-list">
          <div
            class="boletim-item"
            v-for="boletin in boletins"
            :key="boletin.id"
          >
            <expandable-image
              :close-on-background-click="true"
              :src="boletin.PublicationImgs[0].url"
            />
          </div>
        </div>
      </div>
      <div class="covid-info">
        <b-alert show class="alert-covid">
          <i class="fas fa-info-circle fa-2x"></i>
          <span
            >Você pode conferir mais informações referentes as ações de combate
            e prevenção à COVID-19 nos links abaixo</span
          >
          <div class="buttons">
            <b-button variant="outline-info" @click="goToPublications('list')"
              >Boletins</b-button
            >
            <b-button
              variant="outline-info"
              @click="goToLaw('/legislacao/decretos')"
              >Decretos</b-button
            >
            <b-button
              variant="outline-info"
              @click="goToLaw('/legislacao/controle')"
              >Controle</b-button
            >
            <b-button
              variant="outline-info"
              @click="goToLaw('/legislacao/leis-e-legislacoes')"
              >Legislação</b-button
            >
            <b-button
              variant="outline-info"
              href="https://web.neainformatica.com.br/transparencia/publico/linkList.xhtml?cliente=pmrrp&grupo=1748"
              target="_blank"
              >Gastos</b-button
            >
          </div>
        </b-alert>
      </div>
      <h3>COMO ELA SE ESPALHA</h3>
      <p>
        O vírus que causa a COVID-19 é transmitido principalmente por meio de
        gotículas geradas quando uma pessoa infectada tosse, espirra ou exala.
        Essas gotículas são muito pesadas para permanecerem no ar e são
        rapidamente depositadas em pisos ou superfícies.
      </p>
      <p>
        Você pode ser infectado ao inalar o vírus se estiver próximo de alguém
        que tenha COVID-19 ou ao tocar em uma superfície contaminada e, em
        seguida, passar as mãos nos olhos, no nariz ou na boca.
      </p>
      <h3>SINTOMAS</h3>
      <p>
        A COVID-19 afeta diferentes pessoas de diferentes maneiras. A maioria
        das pessoas infectadas apresentará sintomas leves a moderados da doença
        e não precisarão ser hospitalizadas.
      </p>
      <span>Sintomas mais comuns:</span>
      <ul>
        <li>febre</li>
        <li>tosse seca</li>
        <li>cansaço</li>
      </ul>
      <span>Sintomas menos comuns:</span>
      <ul>
        <li>dores e desconfortos</li>
        <li>dor de garganta</li>
        <li>diarreia</li>
        <li>conjuntivite</li>
        <li>dor de cabeça</li>
        <li>perda de paladar ou olfato</li>
        <li>
          erupção cutânea na pele ou descoloração dos dedos das mãos ou dos pés
        </li>
      </ul>
      <h3>PREVENÇÃO</h3>
      <p>
        Proteja a si mesmo e as pessoas ao seu redor conhecendo os fatos e
        tomando as precauções apropriadas. Siga os conselhos da autoridade local
        de saúde.
      </p>
      <span>Para evitar a propagação da COVID-19, faça o seguinte:</span>
      <ul>
        <li>
          Lave suas mãos com frequência. Use sabão e água ou álcool em gel.
        </li>
        <li>
          Mantenha uma distância segura de pessoas que estiverem tossindo ou
          espirrando.
        </li>
        <li>
          Use máscara quando não for possível manter o distanciamento físico.
        </li>
        <li>Não toque nos olhos, no nariz ou na boca.</li>
        <li>
          Cubra seu nariz e boca com o braço dobrado ou um lenço ao tossir ou
          expirar.
        </li>
        <li>Fique em casa se você se sentir indisposto.</li>
        <li>
          Procure atendimento médico se tiver febre, tosse e dificuldade para
          respirar.
        </li>
      </ul>
      <p>
        Ligue com antecedência para o plano ou órgão de saúde e peça
        direcionamento à unidade mais adequada. Isso protege você e evita a
        propagação de vírus e outras infecções.
      </p>
      <p>
        Quem usa máscara pode ajudar a prevenir a propagação do vírus para
        outras pessoas. Isoladamente, as máscaras não são uma proteção contra a
        COVID-19, e o uso delas deve ser combinado com o distanciamento físico e
        a limpeza das mãos. Siga as orientações da autoridade local de saúde.
      </p>
      <h3>TRATAMENTOS</h3>
      <p>Autocuidados:</p>
      <span
        >Após a exposição a uma pessoa com COVID-19, siga estas
        orientações:</span
      >
      <ul>
        <li>
          Ligue para seu prestador de cuidados de saúde ou para uma central de
          informações sobre a doença para descobrir onde e quando você pode
          fazer um teste.
        </li>
        <li>
          Informe outras pessoas com quem você teve contato para impedir a
          propagação do vírus.
        </li>
        <li>
          Caso não seja possível fazer o teste, fique em casa e pratique o
          distanciamento social por 14 dias.
        </li>
        <li>
          Enquanto estiver em quarentena, não vá ao trabalho, à escola ou a
          espaços públicos. Peça para alguém levar itens essenciais até você.
        </li>
        <li>
          Mantenha pelo menos um metro de distância de outras pessoas, incluindo
          membros da sua família.
        </li>
        <li>
          Use uma máscara para proteger os outros, até mesmo se/quando você
          precisar procurar atendimento médico.
        </li>
        <li>Higienize as mãos com frequência.</li>
        <li>
          Fique em um cômodo separado dos outros membros da sua família. Se isso
          não for possível, use máscara.
        </li>
        <li>Mantenha o ambiente bem ventilado.</li>
        <li>
          Se você divide o quarto com alguém, deixe um metro de distância entre
          as camas.
        </li>
        <li>Preste atenção por 14 dias para ver se você apresenta sintomas.</li>
        <li>
          Ligue para o prestador de cuidados de saúde imediatamente se você
          apresentar qualquer um destes sintomas: dificuldade para respirar,
          perda da fala ou da mobilidade, confusão ou dores no peito.
        </li>
        <li>
          Mantenha contato com seus entes queridos por telefone ou on-line e
          faça exercícios para manter o pensamento positivo.
        </li>
      </ul>
      <p>Tratamentos Méddicos:</p>
      <span
        >Cientistas do mundo todo estão trabalhando para encontrar e desenvolver
        tratamentos para a COVID-19.</span
      >
      <ul>
        <li>
          Os tratamentos paliativos mais indicados incluem oxigênio, para casos
          mais graves e pacientes de alto risco devido a outras doenças, e
          suportes respiratórios, como ventilação, para pacientes em estado
          crítico.
        </li>
        <li>
          A Dexametasona é um corticosteroide que pode ajudar a reduzir o tempo
          de ventilação e salvar as vidas de pacientes em situação mais grave.
        </li>
        <p>
          A OMS não recomenda a automedicação, incluindo ingestão de
          antibióticos, como prevenção ou cura da COVID-19.
        </p>
      </ul>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../../components/FooterPref.vue";
import NavBarPref from "../../components/NavBarPref.vue";
import PublicationService from "../../services/PublicationService";
export default {
  data() {
    return {
      boletins: [],
      tagId: "",
    };
  },
  created() {
    PublicationService.listBoletins().then((res) => {
      this.boletins = res.data.publications;
      this.tagId = res.data.tagId;
    });
  },
  methods: {
    goToPublications(name) {
      let tag = this.tagId;
      this.$router.push({
        name,
        params: {
          tag,
        },
      });
    },
    goToLaw(path) {
      path = path + `/${this.tagId}`;
      this.$router.push({
        path,
      });
    },
  },
  components: {
    FooterPref,
    NavBarPref,
  },
};
</script>

  
<style lang="scss">
.main-covid {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  .covid-info {
    display: grid;
    width: 70%;
    margin: 25px auto 25px auto;
    .alert-covid {
      display: grid;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin: auto;
      @media (max-width: 1200px) {
        width: 100%;
      }
      @media (max-width: 998px) {
        display: inherit;
        width: inherit;
        justify-content: inherit;
        margin: 0;
      }
      .btn-outline-info {
        // color: #204991;
        color: #fff;
        border-color: #204991;
        background-color: #204991;
        @media (max-width: 998px) {
          width: 100%;
          margin: 5px;
        }
        &:hover {
          color: #fff;
          background-color: #204991;
        }
      }
    }
    .btn {
      font-size: 1.4rem;
    }
    span {
      text-align: center;
    }
  }
  .boletins {
    margin-top: 10px;
    .boletim-list {
      display: flex;
      @media (max-width: 998px) {
        display: grid;
      }
      .boletim-item {
        .expandable-image {
          &:hover {
            cursor: pointer;
          }
          img {
            max-width: 95%;
            @media (max-width: 998px) {
              max-width: 70%;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
  .publication-separator {
    border: 0;
    height: 1px;
    background: #ccc;
    width: 100%;
  }
  h1 {
    margin-top: 20px;
  }
  h1,
  h3 {
    text-align: justify;
    color: black;
    font-family: Raleway;
    font-weight: bold;
  }
  p,
  span,
  li {
    text-align: initial;
    color: black;
    font-family: Raleway;
  }
}
</style>