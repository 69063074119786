<template>
  <div class="content">
    <nav-bar-pref></nav-bar-pref>
    <b-container>
      <b-row>
        <b-col class="main-col">
          <b-row class="header">
            <h1>Últimas Notícias</h1>
            <b-form @submit.prevent="search" class="form">
              <b-form-input
                v-model="text"
                placeholder="Busque por título ou conteúdo"
              ></b-form-input>
              <b-button type="submit" variant="primary">
                <i class="fas fa-search"></i>
              </b-button>
            </b-form>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="main-col">
          <b-card
            v-for="publication in publications"
            :key="publication.id"
            :img-src="
              publication.PublicationImgs[0] &&
              publication.PublicationImgs[0].url
                ? publication.PublicationImgs[0].url
                : require('../.././assets/noimg.png')
            "
            img-width="270"
            :img-height="isMobile ? 270 : 200"
            :img-left="imgLeft"
            class="mb-3 hvr-shadow"
            @click="goTo('publication-view', publication.id, publication.title)"
          >
            <b-col md="11">
              <div class="tag-wrapper">
                <small
                  v-if="publication.tags && publication.tags.length > 0"
                  class="tag"
                  >{{ publication.tags[0].name }}</small
                >
                <small class="publication-date">{{
                  formatDate(publication.createdAt, publication.date)
                }}</small>
              </div>
              <b-card-title>
                {{ publication.title }}
              </b-card-title>
              <b-card-text v-if="showBody">
                <div
                  v-if="publication.body.match(/<\/?[a-z][\s\S]*>/i)"
                  v-html="
                    publication.body.length <= 180
                      ? publication.body
                      : publication.body.substring(0, 180) + '...'
                  "
                ></div>
                <p v-else>
                  {{ publication.body || publication.meta_description }}
                </p>
              </b-card-text>
            </b-col>
            <b-col v-if="!isMobile" md="1" class="icon-right hvr-icon-forward">
              <i class="fas fa-angle-right fa-2x hvr-icon"></i>
            </b-col>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="pageSize"
          align="center"
          @change="handlePageChange"
          style="margin: 15px auto 15px auto"
        ></b-pagination>
      </b-row>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../../components/FooterPref.vue";
import NavBarPref from "../../components/NavBarPref.vue";
import PublicationService from "../../services/PublicationService";
// import PublicationTypeService from "../../services/PublicationTypeService";
import { isMobile } from "mobile-device-detect";
import moment from "moment";

export default {
  data() {
    return {
      publications: [],
      page: null,
      count: 0,
      pageSize: 10,
      text: null,
      imgLeft: true,
      typeId: "",
      showBody: true,
      tagId: "",
    };
  },
  components: {
    NavBarPref,
    FooterPref,
  },
  methods: {
    goTo(name, id, title) {
      console.log(title);
      title = title.replace(/[\s$-/:-?{-~!"^_`[\]]/g, "-").toLowerCase();
      title = title
        .normalize("NFD")
        .replace(/[\u0300-\u036f\u00aa\u00ba]/g, "");
      this.$router.push({
        name,
        params: {
          id,
          title,
        },
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.text = this.text == "" ? null : this.text,
      PublicationService.listPaginate({
        page: --value,
        size: this.pageSize,
        type: this.typeId,
        tagId: this.tagId,
        text: this.$route.params.text ? this.$route.params.text : this.text,
      }).then((res) => {
        this.publications = this.getMainImg(res.data.publications);
        this.count = res.data.totalItens;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        this.$router.push({
          name: "list",
          params: {
            tag: this.tagId,
            type: this.typeId,
            page: this.page - 1,
            size: this.pageSize,
            text: this.$route.params.text ? this.$route.params.text : this.text,
          },
        });
      });
    },
    search() {
      PublicationService.listPaginate({
        page: 0,
        size: this.pageSize,
        text: this.text,
        type: this.typeId,
        tagId: this.tagId,
      }).then((res) => {
        this.publications = this.getMainImg(res.data.publications);
        this.count = res.data.totalItens;
        this.$router.push({
          name: "list",
          params: {
            tag: this.tagId,
            type: this.typeId,
            page: 0,
            size: this.pageSize,
            text: this.text == "" ? null : this.text,
          },
        });
      });
    },
    handleResize() {
      window.innerWidth > 768 ? (this.imgLeft = true) : (this.imgLeft = false);
      window.innerWidth > 991
        ? (this.showBody = true)
        : (this.showBody = false);
    },
    formatDate(createdAt, date) {
      if (date) return moment(date).format("DD/MM/YYYY");

      return moment(createdAt).format("DD/MM/YYYY");
    },
    getMainImg(publications) {
      publications.forEach((publication) => {
        publication.PublicationImgs = publication.PublicationImgs.filter(
          (img) => {
            return img.main;
          }
        );
      });
      return publications;
    },
  },
  mounted() {
    this.tagId = this.$route.params.tag;
    this.typeId = this.$route.params.type;
    setTimeout(() => {
      this.page = parseInt(this.$route.params.page) + 1;
    }, 300);
    PublicationService.listPaginate({
      page: this.$route.params.page,
      size: this.pageSize,
      type: this.typeId,
      tagId: this.tagId,
      text: this.$route.params.text ? this.$route.params.text : null,
    }).then((res) => {
      this.publications = this.getMainImg(res.data.publications);
      this.count = res.data.totalItens;
    });
  },
  created() {
    this.text = this.$route.params.text ? this.$route.params.text : null;
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    isMobile() {
      return isMobile;
    },
  },
};
</script>
<style lang="scss">
.content {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  overflow-y: hidden;
  h1 {
    color: black;
    font-family: Raleway;
    font-weight: bold;
    margin-top: 50px;
    @media (max-width: 767px) {
      margin: 30px auto 1% auto;
    }
  }
  input {
    width: 90%;
  }
}
.main-col {
  margin: 1% auto 1% auto;
  @media (max-width: 767px) {
    max-width: 95%;
    margin: auto;
  }
  .header {
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
    .form {
      display: flex;
      width: 30%;
      margin-top: 50px;
      @media (max-width: 767px) {
        width: 90%;
        margin: 0 auto 1% auto;
      }
    }
  }
  .card {
    min-height: 167px;
    img {
      object-fit: cover;
      min-height: 200px;
      min-width: 270px;
      @media (max-width: 768px) {
        min-height: 360px;
      }
    }
    .tag-wrapper {
      margin-bottom: 10px;
      .tag {
        background-color: #3265bf;
        color: white;
        font-weight: bold;
        padding: 2px;
        border-radius: 5px;
        margin-right: 10px;
      }
      .publication-date {
        font-weight: bold;
      }
    }
    .card-body {
      text-align: justify;
      display: flex;
      &:hover {
        cursor: pointer;
      }
      @media (max-width: 1000px) {
        max-width: 75%;
      }
      @media (max-width: 768px) {
        max-width: 100%;
      }
      .icon-right {
        display: block;
        margin: auto;
        text-align: center;
        @media (max-width: 900px) {
          display: none;
        }
      }
      .card-title {
        font-weight: bold;
        text-align: initial;
      }
      .card-text {
        text-align: initial;
        p {
          word-break: break-word;
        }
      }
    }
  }
  .hvr-icon-forward {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(3px) translateZ(0);
    transform: perspective(3px) translateZ(0);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
  }
  .hvr-icon-forward .hvr-icon {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-icon-forward:hover .hvr-icon,
  .hvr-icon-forward:focus .hvr-icon,
  .hvr-icon-forward:active .hvr-icon,
  .card-body:hover .hvr-icon {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  .hvr-shadow {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
  }
  .hvr-shadow:hover,
  .hvr-shadow:focus,
  .hvr-shadow:active {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
}
</style>