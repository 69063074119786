var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-users",class:_vm.collapsed ? 'collapsed' : 'non-collapsed'},[_c('nav-bar-dashboard'),_c('sidebar-dashboard'),_c('b-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"user-title"},[_c('h1',[_vm._v("DASHBOARD")])]),_c('div',{staticClass:"bg-dash",style:(_vm.collapsed ? 'display: none' : 'display: inherit')}),_c('div',{staticClass:"dash-cards-wrap"},[_c('div',{staticClass:"dash-card holerite col-md-4"},[_c('a',{attrs:{"href":"http://45.174.220.242:8056/sipweb/trabalhador/login/login.xhtml","target":"_blank"}},[_c('div',{staticClass:"text-wrap-dash"},[_c('span',{staticClass:"dash-card-title"},[_vm._v("Holerite Web")]),_c('span',{staticClass:"dash-sub-title"},[_vm._v("Clique e acesse")])]),_c('div',{staticClass:"bg-icon-holerite"})])]),_c('div',{staticClass:"dash-card diario col-md-4"},[_c('router-link',{attrs:{"to":"/diribas","target":"_blank"}},[_c('div',{staticClass:"text-wrap-dash"},[_c('span',{staticClass:"dash-card-title"},[_vm._v("Diário Oficial")]),_c('span',{staticClass:"dash-sub-title"},[_vm._v("Clique e acesse")])]),_c('div',{staticClass:"bg-icon-diario"})])],1),_c('div',{staticClass:"dash-card transparencia col-md-4"},[_c('a',{attrs:{"href":"http://45.174.220.242:8079/transparencia","target":"_blank"}},[_c('div',{staticClass:"text-wrap-dash"},[_c('span',{staticClass:"dash-card-title"},[_vm._v("Portal da Transparência")]),_c('span',{staticClass:"dash-sub-title"},[_vm._v("Clique e acesse")])]),_c('div',{staticClass:"bg-icon-transparencia"})])])]),_c('div',{staticClass:"tag-last-label"},[_c('span',[_vm._v(" Usuários ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.openUrl}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v("Novo usuário")])],1),_c('div',{staticClass:"tag-last-pub"},[_c('vue-good-table',{staticClass:"table-bordered table-hover",attrs:{"columns":_vm.columns,"rows":_vm.users,"pagination-options":{
          enabled: true,
          dropdownAllowAll: false,
          nextLabel: 'Próximo',
          prevLabel: 'Anterior',
          rowsPerPageLabel: 'Linhas por página',
          ofLabel: 'de',
          pageLabel: 'página',
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'actions')?_c('span',[(_vm.editPerm)?_c('b-button',{staticClass:"edit-button",style:(_vm.collapsed ? 'margin-right: 5px;' : 'margin-right: 0;'),attrs:{"title":"Editar Usuário","variant":"primary"},on:{"click":function($event){return _vm.goEdit(props.row.id)}}},[_c('i',{staticClass:"far fa-edit"})]):_vm._e(),_c('b-modal',{ref:"my-modal",attrs:{"cancel-title":"Cancelar","ok-title":"Confirmar","ok-variant":"danger","cancel-variant":"outline-secondary","title":"Deseja remover a seguinte publicação?","footer-bg-variant":"light"},on:{"ok":_vm.removePubli,"cancel":_vm.removeDeleteId}},[_c('div',{staticClass:"modal-text"},[_c('span',[_vm._v(_vm._s(_vm.modalText))])])])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('b-modal',{ref:"response-modal",attrs:{"footer-bg-variant":"light","ok-only":"","title":_vm.confirmMessage}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }