import 'core-js';
import { http } from './config'

export default {
    store:(department) => {
        return http.post('sub-departments', {
            ...department
        });
    },

    list:() => {
        return http.get('sub-departments');
    },

    listPaginate:(params) => {
        return http.get('sub-departments-paginate',{
            params
        });
    },

    listById:(department_id) => {
        return http.get(`sub-departments/${department_id}`);
    },

    update:(department) => {
        return http.put(`sub-departments/${department.id}`, {
            ...department
        });
    },

    delete:(id) => {
        return http.delete(`sub-departments/${id}`);
    }

}