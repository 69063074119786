<template>
  <div class="list-container">
    <nav-bar-pref></nav-bar-pref>
    <b-container>
      <b-row>
        <b-col class="main-col">
          <b-row class="header">
            <h1>Galerias de Fotos</h1>
            <b-form @submit.prevent="search" class="form">
              <b-form-input
                v-model="text"
                placeholder="Busque por título"
              ></b-form-input>
              <b-button type="submit" variant="primary">
                <i class="fas fa-search"></i>
              </b-button>
            </b-form>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="main-col">
          <div class="list-container">
            <div
              class="list-wrapper"
              v-for="gallery in galleries"
              :key="gallery.id"
            >
              <div @click="goTo(gallery.id, gallery.title)">
                <div class="gallery-title">
                  <span>{{ gallery.title }}</span>
                  <small>Ver fotos</small>
                </div>
                <img :src="gallery.GalleryImgs[0].url" />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="pageSize"
          align="center"
          @change="handlePageChange"
          style="margin: 15px auto 15px auto"
        ></b-pagination>
      </b-row>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../../components/FooterPref.vue";
import NavBarPref from "../../components/NavBarPref.vue";
import GalleryService from "../../services/GalleryService";

export default {
  data() {
    return {
      galleries: [],
      page: null,
      count: 0,
      pageSize: 10,
      text: null,
      typeId: "",
      tagId: "",
    };
  },
  components: {
    NavBarPref,
    FooterPref,
  },
  methods: {
    goTo(id, title) {
      title = title.replace(/[\s$-/:-?{-~!"^_`[\]]/g, "-").toLowerCase();
      title = title
        .normalize("NFD")
        .replace(/[\u0300-\u036f\u00aa\u00ba]/g, "");
      this.$router.push({
        name: "gallery-view",
        params: {
          id,
          title,
        },
      });
    },
    handlePageChange(value) {
      this.page = value;
      (this.text = this.text == "" ? null : this.text),
        GalleryService.listPaginate({
          page: --value,
          size: this.pageSize,
          text: this.$route.params.text ? this.$route.params.text : this.text,
        }).then((res) => {
          this.galleries = this.getMainImg(res.data.galleries);
          this.count = res.data.totalItens;
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          this.$router.push({
            name: "gallery-list-home",
            params: {
              page: this.page - 1,
              size: this.pageSize,
              text: this.$route.params.text
                ? this.$route.params.text
                : this.text,
            },
          });
        });
    },
    search() {
      GalleryService.listPaginate({
        page: 0,
        size: this.pageSize,
        text: this.text,
      }).then((res) => {
        this.galleries = this.getMainImg(res.data.galleries);
        this.count = res.data.totalItens;
        this.$router.push({
          name: "gallery-list-home",
          params: {
            page: 0,
            size: this.pageSize,
            text: this.text == "" ? null : this.text,
          },
        });
      });
    },
    getMainImg(galleries) {
      galleries.forEach((gallery) => {
        gallery.GalleryImgs = gallery.GalleryImgs.filter((img) => {
          return img.position == 0;
        });
      });
      return galleries;
    },
  },
  mounted() {
    setTimeout(() => {
      this.page = parseInt(this.$route.params.page) + 1;
    }, 300);
    GalleryService.listPaginate({
      page: this.$route.params.page,
      size: this.pageSize,
      text: this.$route.params.text ? this.$route.params.text : null,
    }).then((res) => {
      this.galleries = this.getMainImg(res.data.galleries);
      this.count = res.data.totalItens;
    });
  },
  created() {
    this.text = this.$route.params.text ? this.$route.params.text : null;
  },
};
</script>
<style lang="scss">
.list-container {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Raleway;
  .container {
    @media (max-width: 400px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  overflow-y: hidden;
  h1 {
    color: black;
    font-weight: bold;
    margin-top: 50px;
    @media (max-width: 767px) {
      margin: 30px auto 1% auto;
    }
  }
  input {
    width: 90%;
  }
}
.main-col {
  margin: 1% auto 1% auto;
  @media (max-width: 767px) {
    max-width: 95%;
    margin: auto;
  }
  .list-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    min-height: inherit;
    gap: 10px;
    @media (max-width: 400px) {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
    .list-wrapper {
      position: relative;
      cursor: pointer;
      &:hover .gallery-title {
        opacity: 0.9;
      }
      img {
        width: 100%;
        height: 300px;
        border-radius: 5px;
        object-fit: cover;
      }
      .gallery-title {
        background-color: black;
        opacity: 0.6;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100px;
        display: grid;
        color: white;
        transition: all 0.4s ease;
        align-content: center;
        text-align: initial;
        padding: 10px;
        @media (max-width: 576px) {
          padding: 5px;
        }
        span {
          font-weight: bold;
          line-height: normal;
        }
      }
    }
  }
  .header {
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
    .form {
      display: flex;
      width: 30%;
      margin-top: 50px;
      @media (max-width: 767px) {
        width: 90%;
        margin: 0 auto 1% auto;
      }
    }
  }
}
</style>