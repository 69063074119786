<template>
  <div
    class="main-tags"
    :class="collapsed ? 'collapsed' : 'non-collapsed'"
  >
    <nav-bar-dashboard></nav-bar-dashboard>
    <sidebar-dashboard></sidebar-dashboard>
    <b-container fluid>
      <div class="tag-title">
        <h1>DASHBOARD</h1>
      </div>
      <div
        class="bg-dash"
        :style="collapsed ? 'display: none' : 'display: inherit'"
      ></div>
      <div class="dash-cards-wrap">
        <div class="dash-card holerite col-md-4">
          <a
            href="http://45.174.220.242:8056/sipweb/trabalhador/login/login.xhtml"
            target="_blank"
          >
            <div class="text-wrap-dash">
              <span class="dash-card-title">Holerite Web</span>
              <span class="dash-sub-title">Clique e acesse</span>
            </div>
            <div class="bg-icon-holerite"></div>
          </a>
        </div>
        <div class="dash-card diario col-md-4">
          <router-link to="/diribas" target="_blank">
            <div class="text-wrap-dash">
              <span class="dash-card-title">Diário Oficial</span>
              <span class="dash-sub-title">Clique e acesse</span>
            </div>
            <div class="bg-icon-diario"></div>
          </router-link>
        </div>
        <div class="dash-card transparencia col-md-4">
          <a
            href="http://45.174.220.242:8079/transparencia"
            target="_blank"
          >
            <div class="text-wrap-dash">
              <span class="dash-card-title">Portal da Transparência</span>
              <span class="dash-sub-title">Clique e acesse</span>
            </div>
            <div class="bg-icon-transparencia"></div>
          </a>
        </div>
      </div>
      <div class="tag-last-label">
        <span>
          Tags recentes
          </span
        >
        <b-button @click="openUrl" variant="primary"
          ><i class="fas fa-plus"></i>Nova tag</b-button
        >
      </div>
      <div class="tag-last-pub">
        <vue-good-table
          class="table-bordered table-hover"
          :columns="columns"
          :rows="tags"
          :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
            nextLabel: 'Próximo',
            prevLabel: 'Anterior',
            rowsPerPageLabel: 'Linhas por página',
            ofLabel: 'de',
            pageLabel: 'página',
          }"
        >
          <template class="dash-actions" slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'actions'">
              <b-button
                title="Editar Tag"
                v-if="editPerm"
                class="edit-button"
                variant="primary"
                @click="goEdit(props.row.id)"
                :style="collapsed ? 'margin-right: 5px;' : 'margin-right: 0;'"
              >
                <i class="far fa-edit"></i>
              </b-button>
              <b-modal
                ref="my-modal"
                cancel-title="Cancelar"
                ok-title="Confirmar"
                ok-variant="danger"
                cancel-variant="outline-secondary"
                title="Deseja remover a seguinte publicação?"
                footer-bg-variant="light"
                @ok="removePubli"
                @cancel="removeDeleteId"
              >
                <div class="modal-text">
                  <span>{{ modalText }}</span>
                </div>
              </b-modal>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <b-modal
          ref="response-modal"
          footer-bg-variant="light"
          ok-only
          :title="confirmMessage"
        >
        </b-modal>
      </div>
    </b-container>
  </div>
</template>
<script>
import SidebarDashboard from "../../../components/SidebarDashboard";
import TagService from "../../../services/TagService";
import UserService from "../../../services/UserService";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";
import NavBarDashboard from "../../../components/NavBarDashboard.vue";

export default {
  data() {
    return {
      collapsed: true,
      tags: [],
      modalText: "",
      idToDelete: null,
      confirmMessage: "",
      user: "",
      delPerm: false,
      editPerm: false,
      columns: [
        {
          field: "createdAt",
          label: "Data",
          dateOutputFormat: "DD-MM-YYYY",
          dateInputFormat: "DD-MM-YYYY",
          filterOptions: {
            enabled: true,
            placeholder: "Busque pela data da publicação",
            filterFn(data, filterString) {
              return moment(data)
                .format("DD/MM/YYYY HH:mm")
                .includes(filterString);
            },
          },
          formatFn(date) {
            return moment(date).format("DD/MM/YYYY HH:mm");
          },
        },
        {
          field: "name",
          label: "Nome",
          filterOptions: {
            enabled: true,
            placeholder: "Busque pelo nome",
          },
        },
        {
          field: "actions",
          label: "Ações",
          sortable: false,
          hidden: false,
        },
      ],
    };
  },
  created() {
    if (localStorage.getItem("refresh") == "0") {
      localStorage.setItem("refresh", "1");
      this.$router.go();
    }
  },
  mounted() {
    this.$root.$on("collapse", this.collapse);

    UserService.listById(localStorage.getItem("userId")).then((res) => {
      this.user = res.data;
      this.delPerm = res.data.role.delete;
      this.editPerm = res.data.role.edit;
      TagService.listPaginate({
        userId: res.data.role.name == 'Admin' ? null : localStorage.getItem("userId"),
      }).then((resGallery) => {
        this.tags = resGallery.data.tags;
      });
    });
  },
  components: {
    SidebarDashboard,
    VueGoodTable,
    NavBarDashboard,
  },
  methods: {
    collapse() {
      this.collapsed = !this.collapsed;
    },
    goEdit(tagId) {
      this.$router.push({
        name: "tags",
        params: { id: tagId, title: " " },
      });
    },
    openUrl() {
      this.$router.push({
        name: "tags",
        params: { id: "nova", title: " " },
      });
    },
    showModal(row) {
      this.modalText = row.title;
      this.idToDelete = row.id;
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    removePubli() {
      this.hideModal();
      TagService.delete(this.idToDelete)
        .then(() => {
          this.tags.splice(
            this.tags.findIndex(({ id }) => id == this.idToDelete),
            1
          );
          this.idToDelete = null;
          this.confirmMessage = "Tag removida";
          this.$refs["response-modal"].show();
        })
        .catch(() => {
          this.confirmMessage = "Um erro ocorreu ao remover a tag";
        });
    },
    removeDeleteId() {
      this.idToDelete = null;
    },
  },
};
</script>
<style lang="scss">
.collapsed {
  padding-left: 50px;
  @media (max-width: 1400px) {
    padding-left: 0;
  }
}
.non-collapsed {
  padding-left: 350px;
  @media (max-width: 1400px) {
    padding-left: 0;
  }
}
.main-tags {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Raleway;
  transition: 0.3s ease;
  margin-right: 80px;
  margin-left: 80px;
  z-index: 1;
  @media (max-width: 700px) {
    margin-right: 0;
    margin-left: 60px;
  }

  .tag-title {
    margin-top: 100px;
    margin-left: 0;
    text-align: initial;
    font-family: Ubuntu;
    font-weight: bold;
    color: #707070;
    @media (max-width: 700px) {
      font-size: 2rem;
    }
  }
  .bg-dash {
    @media (max-width: 1400px) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100vw;
      height: 100vh;
      opacity: 0.6;
      background-color: #343a40;
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }
  .dash-cards-wrap {
    display: flex;
    margin-top: 25px;
    justify-content: space-between;
    @media (max-width: 770px) {
      display: grid;
      justify-content: inherit;
    }
    .dash-card {
      height: 230px;
      color: white;
      text-align: initial;
      border-radius: 5px;
      max-width: 32.5%;
      @media (max-width: 770px) {
        max-width: 100%;
        margin-bottom: 10px;
        height: 200px;
      }
      a {
        text-decoration: none;
        color: white;
      }
      .text-wrap-dash {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        .dash-card-title {
          font-size: 40px;
          font-weight: bold;
          @media (max-width: 1100px) {
            font-size: 30px;
          }
          @media (max-width: 900px) {
            font-size: 25px;
          }
          @media (max-width: 770px) {
            font-size: 30px;
          }
        }
        .dash-sub-title {
          font-size: 30px;
          font-weight: bold;
          @media (max-width: 1100px) {
            font-size: 20px;
          }
          @media (max-width: 900px) {
            font-size: 15px;
          }
          @media (max-width: 770px) {
            font-size: 20px;
          }
        }
      }
    }
    .holerite {
      background-image: linear-gradient(to right, #1749ee, #00087b);
      .bg-icon-holerite {
        position: relative;
      }
      .bg-icon-holerite:after {
        position: absolute;
        font-family: FontAwesome;
        font-size: 110px;
        color: white;
        bottom: 0;
        right: 0;
        content: "\f543";
        opacity: 0.5;
      }
    }
    .diario {
      background-image: linear-gradient(to right, #fc5959, #800000);
      .bg-icon-diario {
        position: relative;
      }
      .bg-icon-diario:after {
        position: absolute;
        font-family: FontAwesome;
        font-size: 110px;
        color: white;
        bottom: 0;
        right: 0;
        content: "\f1ea";
        opacity: 0.5;
      }
    }
    .transparencia {
      background-image: linear-gradient(to right, #2ef850, #046e00);
      .bg-icon-transparencia {
        position: relative;
      }
      .bg-icon-transparencia:after {
        position: absolute;
        font-family: FontAwesome;
        font-size: 110px;
        color: white;
        bottom: 0;
        right: 0;
        content: "\f688";
        opacity: 0.5;
      }
    }
  }
  .tag-last-label {
    font-family: Raleway;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-weight: bold;
    }
    button {
      font-weight: bold;
      i {
        margin-right: 5px;
      }
    }
  }
  .tag-last-pub {
    margin-top: 10px;
    margin-bottom: 50px;
    font-weight: bold;
    font-family: Montserrat;
    .edit-button {
      max-width: 40px;
    }
  }
  .modal-dialog {
    max-width: 1000px;
  }
}
</style>