<template>
  <div class="login">
    <b-container-fluid class="login-container">
      <div class="login-main">
        <div class="login-card">
          <div class="info-wrapper">
            <div class="login-warning">
              <small> Área de acesso exclusivo para <b>SERVIDORES</b> </small>
            </div>
            <h3>Entrar</h3>
            <b-form @submit.prevent="send" class="form-login">
              <b-form-input
                id="input-1"
                v-model="email"
                type="email"
                placeholder="Digite seu e-mail"
                autocomplete="email"
                required
              ></b-form-input>
              <b-form-input
                id="input-2"
                v-model="password"
                type="password"
                placeholder="Digite sua senha"
                autocomplete="password"
                class="password"
                required
              ></b-form-input>
              <b-form-checkbox v-model="remember"
                >Manter conectado</b-form-checkbox
              >
              <b-button type="submit">Login</b-button>
              <small class="login-error"> {{ error }} </small>
            </b-form>
          </div>
        </div>
        <b-col lg="9" md="9" class="img-container">
          <div></div>
        </b-col>
        <b-col lg="3" md="3" class="info-login"> </b-col>
      </div>
    </b-container-fluid>
  </div>
</template>
<script>
import AuthenticationService from "../../services/AuthenticationService";

export default {
  data() {
    return {
      email: "",
      password: "",
      error: "",
      remember: false,
    };
  },
  methods: {
    send() {
      this.error = "";
      AuthenticationService.login(this.email, this.password)
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userId", res.data.foundUser.id);
          localStorage.setItem(
            "userName",
            res.data.foundUser.name.split(" ")[0]
          );

          if (this.remember && !this.$cookies.get("remember_id")) {
            AuthenticationService.createSession(
              res.data.foundUser.name,
              res.data.foundUser.id
            ).then((res) => {
              this.$cookies.set("remember_id", res.data.remember_id, "14D");
              this.$cookies.set("remember_name", res.data.remember_name, "14D");

              localStorage.setItem("refresh", "0");
              this.$router.push({ path: "/dashboard" });
            });
          } else {
            localStorage.setItem("refresh", "0");
            this.$router.push({ path: "/dashboard" });
          }
        })
        .catch((error) => {
          if (error.response) this.error = error.response.data.error;
        });
    },
  },
};
</script>
<style lang="scss">
.login {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Raleway;
  .login-container {
    display: flex;
    .login-main {
      display: flex;
      width: 100%;
      .login-card {
        min-width: 450px;
        padding: 40px;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 81%;
        transform: translate(-50%, -50%);
        min-height: 50vh;
        background-color: #fff;
        box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
        @media (max-width: 1260px) {
          transform: translate(-60%, -50%);
        }
        @media (max-width: 998px) {
          transform: translate(-80%, -50%);
        }
        @media (max-width: 520px) {
          transform: translate(-85%, -50%);
        }
        @media (max-width: 480px) {
          min-width: 100vw;
          transform: translate(-81%, -50%);
        }
        .info-wrapper {
          text-align: initial;
          h3 {
            margin-top: 20px;
          }
          .login-warning {
            text-align: center;
            small {
              color: black;
            }
          }
          .form-login {
            input {
              margin-top: 10px;
            }
            .password {
              margin-bottom: 10px;
            }
            .btn {
              width: 100%;
              margin-top: 10px;
              background-color: #204991;
            }
            .login-error {
              color: red;
              font-size: 90%;
            }
          }
        }
      }
      .img-container {
        padding: 0;
        div {
          background-image: url(../../assets/ribas-paisagem.png);
          max-width: 75vw;
          min-width: 75vw;
          max-height: 100vh;
          min-height: 100vh;
          filter: grayscale(10%);
          background-size: 75vw 100vh;
          @media (max-width: 700px) {
            max-width: 100vw;
            min-width: 100vw;
            background-size: 100vw 100vh;
          }
        }
        img {
          object-fit: cover;
          width: 100%;
        }
      }
      h3 {
        font-weight: bold;
        color: black;
        font-size: 1.5rem;
        text-align: initial;
      }
      .info-login {
        display: flex;
        align-items: center;
        background-image: linear-gradient(to left, #0b148a, #00087b);
      }
    }
  }
}
</style>