<template>
  <div class="main-oficina-fotografia">
    <nav-bar-pref></nav-bar-pref>
    <b-container-fluid>
      <b-row class="title">
        <b-col md="3" class="content-col">
          <h1>Saúde Transparente</h1>
        </b-col>
      </b-row>
      <p>
        <strong>Clicando nos botões abaixo, você acessa os nossos serviços.</strong>
      </p>
    <b-container>
      <a
        target="_blank"
        href="https://ribas.esaude.genesiscloud.tec.br/publico/saude-transparente"
        >
          <img src="https://ribas-publications.s3.sa-east-1.amazonaws.com/saude-transparente.png" />
      </a>
    </b-container>
  <div>
    <b-row>
      <p>
        <br>
      </p>
    </b-row>
  </div>
    <b-container>
      <a
        target="_blank"
        href="https://ribas.esaude.genesiscloud.tec.br/publico/saude-transparente/consultar-estoque?departamentosTransparentes%5B0%5D=atendimentos_da_farmacia&departamentosTransparentes%5B1%5D=atendimentos_do_laboratorio&departamentosTransparentes%5B2%5D=atendimentos_da_atencao_basica&departamentosTransparentes%5B3%5D=atendimentos_do_hospital&departamentosTransparentes%5B4%5D=atendimentos_da_especialidade&departamentosTransparentes%5B5%5D=vigilancia_sanitaira_pessoa_fisica_e_juridica&departamentosTransparentes%5B6%5D=vacinas_esus"
        >
          <img src="https://ribas-publications.s3.sa-east-1.amazonaws.com/FARMACIA-BASICA.png" />
      </a>
    </b-container>
  </b-container-fluid>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../../components/FooterPref.vue";
import NavBarPref from "../../components/NavBarPref.vue";

export default {
  components: {
    NavBarPref,
    FooterPref,
  },
};
</script>

<style lang="scss">
.main-oficina-fotografia {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  .title {
    margin: 10px auto 30px auto;
  }
}
</style>