import 'core-js';
import { http } from './config'

export default {
    store:(department) => {
        return http.post('departments', {
            ...department
        });
    },

    list:() => {
        return http.get('departments');
    },

    listByUserId:(userId) => {
        return http.get(`departments-user/${userId}`);
    },

    listPaginate:(params) => {
        return http.get('departments-paginate',{
            params
        });
    },

    listById:(department_id) => {
        return http.get(`departments/${department_id}`);
    },

    update:(department) => {
        return http.put(`departments/${department.id}`, {
            ...department
        });
    },

    delete:(id) => {
        return http.delete(`departments/${id}`);
    }

}