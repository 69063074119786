<template>
  <b-container class="diribas-render-preview">
    <div class="diribas-publications">
      <b-row>
        <b-col class="department-name">
          <span> {{ publication.sub_department ? publication.sub_department.name : publication.department.name }} </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="diribas-title">
          <span> {{ publication.title }} </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="diribas-text">
          <span v-html="publication.text"></span>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>
<script>
import moment from "moment";

export default {
  name: "RenderPreview",
  data() {
    return {};
  },
  props: {
    publication: {
      title: "",
      text: "",
      department_id: "",
      sub_department_id: null,
      user_id: "",
      supplement: false,
      published: false,
      department: '',
      sub_department: '',
    },
  },
  components: {},
  methods: {
    formatDate(date) {
      moment.locale("pt");
      return moment(date).format("dddd, DD [de] MMMM [de] YYYY");
    },
    formatDateHeader(date) {
      moment.locale("pt");
      return moment(date).format("DD [de] MMMM [de] YYYY");
    },
  },
};
</script>
<style lang="scss">
.diribas-render-preview {
  font-family: 'EB Garamond', serif;
  color: black;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 21cm;
  height: 29.7cm;
  text-align: justify;
  h2,h3,h4 {
    font-size: 16px;
  }
  .row {
    margin-right: 0;
    margin-left: 0;
    .department-name {
      margin-top: 20px;
      font-weight: bold;
      background-color: #c5c5c5;
      height: 25px;
      display: flex;
      align-items: center;
      span {
        margin: auto;
        font-size: 16px;
      }
    }
    .diribas-title {
      margin-bottom: 10px;
      font-weight: bold;
      display: flex;
      align-items: center;
      span {
        margin: auto;
      }
    }
    .diribas-text {
      line-height: normal;
      text-align: justify;
      p {
        margin-bottom: 0;
      }
      p:empty::after {
        content: "\00A0";
      }
      img {
        max-width: 100%;
        width: inherit;
      }
    }
  }
}
</style>