<template>
  <div class="main-orcamento-2024">
    <nav-bar-pref></nav-bar-pref>
    <b-container>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSchiWNIc0fjvamx5w_jusHujNer1S5C8ZFyAxtznlc9Qkj7FQ/viewform"
        width="1140"
        height="3145"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        >Carregando…</iframe
      >
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../../components/FooterPref.vue";
import NavBarPref from "../../components/NavBarPref.vue";

export default {
  components: {
    NavBarPref,
    FooterPref,
  },
};
</script>

<style lang="scss">
.main-orcamento-2024 {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Raleway;
  text-align: initial;
}
</style>