<template>
  <div>
    <nav-bar-pref></nav-bar-pref>
    <b-container class="upload-publication-main">
      <link
        rel="stylesheet"
        href="../../node_modules/@fortawesome/fontawesome-free/css/all.css"
      />
      <div class="publication-edit-title col-md-12">
        <h1 v-if="!editMode">NOVA GALERIA</h1>
        <h1 v-else>EDIÇÃO DA GALERIA</h1>
      </div>
      <b-form @submit.prevent="send" class="form-publication">
        <b-form-group label-for="file">
          <div class="edit-wrap">
            <div class="col-md-6">
              <span class="required-mark">* </span
              ><label class="form-label" for="title">Título da galeria</label>
              <b-form-input
                id="title"
                placeholder="Seu título aqui"
                required
                v-model="gallery.title"
              ></b-form-input>
            </div>
            <div class="col-md-6" style="display: grid">
              <div style="display: flex">
                <span class="required-mark">* </span
                ><label class="form-label" for="date">Data</label>
              </div>
              <date-picker
                id="date"
                valueType="format"
                v-model="gallery.date"
                :input-attr="{ required: 'true' }"
                type="datetime"
              ></date-picker>
            </div>
          </div>
          <div class="edit-wrap">
            <div class="col-md-12">
              <label class="form-label" for="description"
                >Descrição da galeria</label
              >
              <b-form-input
                id="description"
                placeholder="Descrição"
                v-model="gallery.description"
              ></b-form-input>
            </div>
          </div>
          <div class="edit-wrap">
            <div class="col-md-12">
              <label class="form-label" for="carrosel"
                >Imagens para a galeria</label
              >
              <b-form-file
                id="carrosel"
                multiple
                placeholder="Arraste as imagens ou clique para selecionar"
                v-model="imgsGallery"
              >
              </b-form-file>
            </div>
          </div>
          <div class="edit-wrap">
            <div class="col-md-12">
              <draggable
                v-model="gallery.GalleryImgs"
                ghost-class="ghost"
                @end="onEnd"
              >
                <transition-group type="transition" name="flip-list">
                  <div
                    class="sortable"
                    v-for="img in gallery.GalleryImgs"
                    :key="img.url"
                  >
                    <div class="drag-wrap">
                      <img :src="img.url" /><span>{{ img.name }}</span>
                    </div>
                    <div>
                      <b-button
                        :disabled="buttonDisabled"
                        @click="removeImg(img)"
                        title="Remover arquivo"
                        variant="outline-danger"
                        ><i class="far fa-trash-alt remove-icon"></i
                      ></b-button>
                    </div>
                  </div>
                </transition-group>
              </draggable>
            </div>
          </div>
          <div class="date-upload"></div>
          <div class="upload-buttons col-md-12">
            <b-button type="submit" variant="primary">Enviar</b-button>
          </div>
        </b-form-group>
      </b-form>
      <b-modal
        ref="response-modal"
        footer-bg-variant="light"
        ok-only
        title="Sessão Expirada"
        @hide="emitUnauthorized"
      >
        Efetue login novamente
      </b-modal>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import Upload from "../../../services/UploadService";
import AuthenticationService from "../../../services/AuthenticationService";
import UserService from "../../../services/UserService";
import FooterPref from "../../../components/FooterPref.vue";
import NavBarPref from "../../../components/NavBarPref.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pt-br";
import "vue2-datepicker/index.css";
import moment from "moment";
import GalleryService from "../../../services/GalleryService";
import GalleryImgService from "../../../services/GalleryImgService";
import draggable from "vuedraggable";
import _ from "lodash";

export default {
  data() {
    return {
      file: null,
      imgsGallery: [],
      gallery: {
        title: "",
        date: "",
        description: "",
        user_id: "",
      },
      user: "",
      gallery_id: 0,
      editMode: false,
      buttonDisabled: false,
      oldIndex: "",
      newIndex: "",
      imgsPosition: [],
    };
  },
  components: {
    NavBarPref,
    FooterPref,
    DatePicker,
    draggable,
  },
  async created() {
    const user = await UserService.listById(localStorage.getItem("userId"));
    this.gallery.user_id = user.data.id;
    if (this.$route.params.id != "nova") {
      this.editMode = true;
      await this.setGallery();
    } else {
      this.gallery.date = moment().format("YYYY-MM-DD HH:mm:ss");
    }
  },
  async mounted() {
    try {
      const validLogin = await AuthenticationService.validateLogin();
      console.log(validLogin.status);
    } catch (error) {
      if (error.response.status == 401) {
        this.$refs["response-modal"].show();
      }
    }
  },
  methods: {
    async setGallery() {
      const foundGallery = await GalleryService.listById(this.$route.params.id);
      this.gallery = foundGallery.data;
      this.gallery.date = moment(this.gallery.date).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.gallery.GalleryImgs = _.orderBy(this.gallery.GalleryImgs, ["position", "desc"]);
      this.gallery.GalleryImgs.forEach((img) => {
          this.imgsPosition.push(img);
      });
    },
    uploadFiles(file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "gallery_id",
        this.editMode ? this.gallery.id : this.gallery_id
      );
      Upload.storeGalleryImgs(formData).then(() => {});
    },
    send() {
      try {
        if (this.editMode) {
          GalleryService.update(this.gallery)
            .then((updated) => {
              if (this.imgsGallery.length > 0) {
                this.imgsGallery.map((img) => {
                  this.uploadFiles(img, "imagem");
                });
              }
              window.alert("Alterado com sucesso");
              setTimeout(() => {
                this.goToDashboard(updated.data);
              }, 600);
            })
            .catch((err) => {
              if (err.response && err.response.status == 401) {
                this.$refs["response-modal"].show();
              } else {
                console.log(err);
                window.alert("Erro ao salvar gleria");
              }
            });
        } else {
          GalleryService.store(this.gallery)
            .then((res) => {
              let createdGallery = res.data;
              this.gallery_id = createdGallery.id;
              if (this.imgsGallery.length > 0) {
                this.imgsGallery.map((img) => {
                  this.uploadFiles(img, "imagem");
                });
              }
              window.alert("Cadastrado com sucesso");
              setTimeout(() => {
                this.goToDashboard(createdGallery);
              }, 600);
            })
            .catch((err) => {
              if (err.response && err.response.status == 401) {
                this.$refs["response-modal"].show();
              } else {
                console.log(err);
                window.alert("Erro ao salvar publicação");
              }
            });
        }
      } catch (e) {
        console.log(e);
        window.alert("Erro ao salvar publicação");
      }
    },
    goToDashboard() {
      this.$router.go(-1);
    },
    emitUnauthorized() {
      this.$root.$emit("unauthorized");
    },
    onEnd(evt) {
      this.oldIndex = evt.oldIndex;
      this.newIndex = evt.newIndex;
      let next = this.imgsPosition;
      let moved = next.splice(this.oldIndex, 1);
      next.splice(this.newIndex, 0, moved[0]);
      this.setPositions(next, true);
    },
    setPositions(arr) {
      arr.map((img, index) => {
        img.position = index;
        GalleryImgService.update(img).then(() => {});
      });
    },
    removeImg(img) {
      this.buttonDisabled = true;
      GalleryImgService.delete(img.id).then(() => {
        this.buttonDisabled = false;
        let indexPosition = this.imgsPosition.indexOf(img);
        if (indexPosition !== -1) {
          this.imgsPosition.splice(indexPosition, 1);
        }
        let index = this.gallery.GalleryImgs.indexOf(img);
        if (index !== -1) {
          this.gallery.GalleryImgs.splice(index, 1);
        }
        this.gallery.GalleryImgs.map((img, index) => {
          img.position = index;
          GalleryImgService.update(img).then(() => {});
        });
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.upload-publication-main {
  min-height: 100vh;
  :disabled {
    cursor: not-allowed;
  }
  .publication-edit-header {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 85%;
    .back-button {
      i {
        margin-right: 5px;
      }
    }
  }
  .publication-edit-title {
    text-align: initial;
    font-family: Ubuntu;
    font-weight: bold;
    color: #707070;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .sortable-drag {
    opacity: 0;
  }
  .sortable {
    cursor: move;
    margin-bottom: 10px;
    background-color: #fbfbfb;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    span {
      font-weight: bold;
    }
    i {
      margin-right: 5px;
    }
    .remove-icon {
      margin-right: 0;
    }
    .drag-wrap {
      display: flex;
      align-items: center;
    }
    img {
      height: 150px;
      width: 180px;
      margin-right: 10px;
      object-fit: cover;
      @media (max-width: 769px) {
        height: 100px;
        width: 130px;
      }
    }
    button {
      margin-right: 10px;
    }
  }
  .attach-move-warp {
    display: flex;
    align-items: center;
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .ghost {
    border-left: 6px solid #204991;
    box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.14);
    opacity: 0.7;
  }
  .form-publication {
    .form-label {
      font-weight: bold;
      margin-bottom: 0;
      font-size: 15px;
      color: #929292;
    }
    .edit-wrap {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      text-align: initial;
      @media (max-width: 768px) {
        display: grid;
        margin-bottom: 10px;
        .form-label {
          margin-top: 10px;
        }
      }
      .required-mark {
        color: #fc2c03;
      }
      .upload-radio {
        display: flex;
        div:first-of-type {
          margin-right: 15px;
        }
      }
    }
    .editor-buttons {
      margin-top: 20px;
      margin-bottom: 3px;
      button {
        margin-right: 1px;
      }
    }
    .col {
      margin-bottom: 10px;
    }
    .editor-box > * {
      border-color: grey;
      border-style: solid;
      border-width: 1px;
      min-height: 100px;
      text-align: initial;
      word-break: break-word;
      padding-left: 10px;
      padding-right: 10px;
      .ProseMirror {
        padding: 5px;
      }
    }
    .is-active {
      border-color: grey;
      border-style: solid;
      border-width: 1px;
    }
    .custom-checkbox {
      text-align: initial;
    }
    .upload-buttons {
      text-align: initial;
      margin-top: 30px;
      .btn {
        margin-right: 10px;
      }
    }
    .date-upload {
      text-align: initial;
      display: grid;
      .mx-datepicker {
        width: 100%;
      }
    }
    .mx-datepicker {
      width: 100%;
    }
    blockquote {
      border-left: 5px solid #ccc;
      margin: 1.5em 10px;
      padding: 0.5em 10px;
      quotes: "\201C""\201D""\2018""\2019";
    }
    blockquote p {
      display: inline;
    }
    .is-active {
      background-color: #545b62;
    }
  }
}
</style>