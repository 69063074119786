<template>
  <div>
    <b-container>
      <b-form @submit.prevent="send">
        <b-form-group label="Upload diribas" label-for="file">
          <b-form-input
            placeholder="Número do documento"
            v-model="display_name"
          ></b-form-input>
          <b-form-datepicker v-model="date" class="mb-2 col-md-4"></b-form-datepicker>
          <p>data {{ this.date }}</p>
          <b-form-file id="file" v-model="file"> </b-form-file>
          <b-button type="submit" variant="primary">Enviar</b-button>
          <b-button @click="clearFile" variant="danger"
            >Cancelar</b-button
          >
        </b-form-group>
      </b-form>
    </b-container>
  </div>
</template>
<script>
import Upload from "../../services/UploadService";

export default {
  data() {
    return {
      file: null,
      display_name: "",
      date: ''
    };
  },
  methods: {
    clearFile() {
      this.file = null;
      this.display_name = '';
      this.date = '';
    },
    send() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("display_name", this.display_name);
      formData.append("date", this.date);
      Upload.storeDiribas(formData)
      .then(() => {
          this.clearFile();
          window.alert('Sucesso');
      })
      .catch((err) => {
        console.log(err)
        window.alert('Erro no upload')
      })
    },
  },
};
</script>
<style lang="scss">
</style>