<template>
  <div id="Request">
    <link
      rel="stylesheet"
      href="node_modules/@fortawesome/fontawesome-free/css/all.css"
    />
    <nav-bar-pref></nav-bar-pref>
    <div class="main-container">
      <div
        style="width: 100%; height: 100%; overflow: hidden; margin-top: -50px"
      >
        <iframe
          src="http://45.174.220.242:8085/requisicao-compra/#/login"
          width="100%"
          height="100%"
          style="border: none"
        >
        </iframe>
      </div>
    </div>
    <footer-pref></footer-pref>
  </div>
</template>

<script>
import FooterPref from "../components/FooterPref";
import NavBarPref from "../components/NavBarPref";

export default {
  name: "Request",
  data() {
    return {};
  },

  components: {
    FooterPref,
    NavBarPref,
  },

  mounted() {},
};
</script>

<style lang="scss">
#Request {
  width: 100%;
  height: 100%;

  .main-container {
    width: 100%;
    height: 100%;
  } 
}
</style>