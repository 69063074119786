<template>
  <div id="Secretariats">
    <link
      rel="stylesheet"
      href="node_modules/@fortawesome/fontawesome-free/css/all.css"
    />
    <nav-bar-pref></nav-bar-pref>
    <footer-pref></footer-pref>
  </div>
</template>

<script>
import FooterPref from "../components/FooterPref";
import NavBarPref from "../components/NavBarPref";

export default {
  name: "Secretariats",
  data() {
    return {};
  },

  components: {
    FooterPref,
    NavBarPref,
  },

  mounted() {},
};
</script>

<style>
</style>