<template>
  <div id="Payslip">
    <link
      rel="stylesheet"
      href="node_modules/@fortawesome/fontawesome-free/css/all.css"
    />
    <nav-bar-pref></nav-bar-pref>
    <div class="main-container">
      <div
        style="width: 100%; height: 100%; overflow: hidden; margin-top: -130px"
      >
        <iframe
          src="http://45.174.220.242/srh/common/login.xhtml;jsessionid=xKcYPs1oKUVJ4WrhHsFwk4VLD-mfx7ghL6yDi43V.serverwb"
          width="100%"
          height="100%"
          style="border: none"
        >
        </iframe>
      </div>
    </div>
    <footer-pref></footer-pref>
  </div>
</template>

<script>
import FooterPref from "../components/FooterPref";
import NavBarPref from "../components/NavBarPref";

export default {
  name: "Payslip",
  data() {
    return {};
  },

  components: {
    FooterPref,
    NavBarPref,
  },

  mounted() {},
};
</script>

<style lang="scss">
#Payslip {
  width: 100%;
  height: 100%;

  .main-container {
    width: 100%;
    height: 100%;
  } 
}
</style>