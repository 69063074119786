<template>
  <div class="laws">
    <nav-bar-pref></nav-bar-pref>
    <b-container>
      <b-col>
        <b-row class="header">
          <h1>{{ this.title }}</h1>
        </b-row>
      </b-col>
      <b-col class="padding-left">
        <b-form
          @submit.prevent="search"
          class="law-search"
          style="padding-left: unset"
        >
          <b-form-group
            label="Você pode buscar por data inicial e/ou final ou pelo título da postagem"
          >
            <div class="input-container">
              <date-picker
                valueType="format"
                v-model="startDate"
                class="col-md-4 m-auto"
              ></date-picker>
              <date-picker
                valueType="format"
                v-model="endDate"
                class="col-md-4 m-auto"
              ></date-picker>
              <b-form-input
                class="col-md-3"
                v-model="text"
                placeholder="Busque pelo título"
              ></b-form-input>
              <b-button type="submit" variant="primary"> Buscar </b-button>
            </div>
          </b-form-group>
        </b-form>
      </b-col>
      <b-col class="padding-left">
        <hr class="laws-separator" />
      </b-col>
      <b-col class="padding-left">
        <div
          v-for="law in laws"
          :key="law.id"
          class="law-list"
          @click="goTo('publication-view', law.id, law.title)"
        >
          <div class="law-info">
            <span>{{
              law.title.length >= 138
                ? law.title.substring(0, 138) + "..."
                : law.title
            }}</span>
            <small>{{ formatDate(law.date) }}</small>
          </div>
          <div class="law-hover">
            <span>Clique para visualizar</span>
          </div>
        </div>
      </b-col>
      <b-col>
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="pageSize"
          align="center"
          @change="handlePageChange"
          style="margin: 15px auto 15px auto"
        ></b-pagination>
      </b-col>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../components/FooterPref.vue";
import NavBarPref from "../components/NavBarPref.vue";
import PublicationService from "../services/PublicationService";
import PublicationTypeService from "../services/PublicationTypeService";
import moment from "moment";
import DatePicker from "vue2-datepicker";

export default {
  data() {
    return {
      laws: [],
      page: null,
      count: 0,
      pageSize: 10,
      typeId: "",
      text: null,
      startDate: "",
      endDate: "",
      title: "",
      tagId: "",
      type: '',
    };
  },
  components: {
    NavBarPref,
    FooterPref,
    DatePicker,
  },
  created() {
    this.tagId = this.$route.params.tag;
    this.type = this.$route.params.type;
    setTimeout(() => {
      this.page = parseInt(this.$route.params.page) + 1;
    }, 300);
    this.title =
      this.$route.params.type.replace(/-/g, " ") == "leis e legislacoes"
        ? "leis e legislações"
        : this.$route.params.type.replace(/-/g, " ");
    this.text = this.$route.params.text ? this.$route.params.text : null;

    PublicationTypeService.listByName(
      this.$route.params.type.replace(/-/g, " ") == "leis e legislacoes"
        ? "leis e legislações"
        : this.$route.params.type.replace(/-/g, " ")
    ).then((res) => {
      this.typeId = res.data.id;
      PublicationService.listPaginate({
        page: this.$route.params.page,
        size: this.pageSize,
        type: this.typeId,
        text: this.$route.params.text ? this.$route.params.text : null,
        startDate: this.startDate
          ? this.startDate
          : moment().subtract(10, "years").format("YYYY-MM-DD"),
        endDate: this.endDate
          ? this.endDate
          : moment().add(10, "years").format("YYYY-MM-DD"),
        tagId: this.tagId,
      }).then((res) => {
        this.laws = res.data.publications;
        this.count = res.data.totalItens;
      });
    });
  },
  mounted() {
    setTimeout(() => {
      this.page = parseInt(this.$route.params.page) + 1;
    }, 300);
  },
  methods: {
    goTo(name, id, title) {
      title = title.replace(/[\s$-/:-?{-~!"^_`[\]]/g, "-").toLowerCase();
      title = title
        .normalize("NFD")
        .replace(/[\u0300-\u036f\u00aa\u00ba]/g, "");
      this.$router.push({
        name,
        params: {
          id,
          title,
        },
      });
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    handlePageChange(value) {
      this.page = value;
      this.text = this.text == "" ? null : this.text,
      PublicationService.listPaginate({
        page: --value,
        size: this.pageSize,
        type: this.typeId,
        text: this.$route.params.text ? this.$route.params.text : this.text,
        startDate: this.startDate
          ? this.startDate
          : moment().subtract(10, "years").format("YYYY-MM-DD"),
        endDate: this.endDate
          ? this.endDate
          : moment().add(10, "years").format("YYYY-MM-DD"),
        tagId: this.tagId,
      }).then((res) => {
        this.laws = res.data.publications;
        this.count = res.data.totalItens;
        this.$router.push({
          name: "law-view",
          params: {
            type: this.type,
            tag: this.tagId,
            page: this.page - 1,
            size: this.pageSize,
            text: this.$route.params.text ? this.$route.params.text : this.text,
          },
        });
      });
    },
    search() {
      PublicationService.listPaginate({
        page: 0,
        size: this.pageSize,
        title: this.text,
        type: this.typeId,
        startDate: this.startDate
          ? this.startDate
          : moment().subtract(10, "years").format("YYYY-MM-DD"),
        endDate: this.endDate
          ? this.endDate
          : moment().add(10, "years").format("YYYY-MM-DD"),
        tagId: this.tagId,
        text: this.text,
      }).then((res) => {
        this.laws = res.data.publications;
        this.count = res.data.totalItens;
        this.$router.push({
          name: "law-view",
          params: {
            type: this.type,
            tag: this.tagId,
            page: 0,
            size: this.pageSize,
            text: this.text == "" ? null : this.text,
          },
        });
      });
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        if(to.path.includes('leis-e-legislacoes') && from.path.includes('decretos') ||  to.path.includes('decretos') && from.path.includes('leis-e-legislacoes'))
        location.reload();
      }
    },
  },
};
</script>
    

<style lang="scss">
.laws {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  .padding-left {
    padding-left: unset;
  }
  .laws-separator {
    border: 0;
    height: 1px;
    background: #ccc;
    width: 100%;
    margin-bottom: 2rem;
  }
  .header {
    justify-content: space-between;
    align-items: center;
    h1 {
      text-transform: capitalize;
    }
  }
  .law-search {
    padding: 5px;
    .input-container {
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        display: grid;
        justify-content: inherit;
        div,
        input,
        button {
          margin-top: 5px;
        }
      }
      .mx-datepicker {
        width: 100%;
        padding: unset;
      }
    }
  }
  .law-list {
    text-align: justify;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    height: 101px;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
    &:hover {
      cursor: pointer;
    }
    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.7);
    }
    .law-info {
      display: grid;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-left: 5px;
      max-width: 80%;
      align-content: center;
      @media (max-width: 992px) {
        max-width: 95%;
      }
    }
    .law-hover {
      display: flex;
      padding: 5px;
      span {
        font-size: 1rem;
        margin: auto;
        @media (max-width: 992px) {
          display: none;
        }
      }
    }
    span {
      font-family: Lato;
      font-size: 1.3rem;
      font-weight: bold;
      @media (max-width: 992px) {
        font-size: 1rem;
      }
    }
  }

  h1 {
    color: black;
    font-family: Raleway;
    font-weight: bold;
    margin-top: 50px;
    text-align: justify;
  }
  .law-hover {
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .law-hover:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #204991;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .law-hover:hover,
  .law-hover:focus,
  .law-hover:active {
    color: white;
  }
  .law-hover:hover:before,
  .law-hover:focus:before,
  .law-hover:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
</style>