import 'core-js';
import { http } from './config'

export default {
    listPaginate: (params) => {
        return http.get('diribas', {
            params
        });
    },

    exportPdf: () => {
        return http.get(`diribas-pdf`);
    },

    listPublications: () => {
        return http.get(`diribas-publications`);
    },

    listPublicationById: (id) => {
        return http.get(`diribas-publications/${id}`);
    },

    listPublicationsByPost: (id) => {
        return http.get(`diribas-publications-post/${id}`);
    },

    storePublication: (publication) => {
        return http.post('diribas-publications', {
            publication
        });
    },

    updatePublication: (publication) => {
        return http.put(`diribas-publications/${publication.id}`, {
            publication
        });
    },

    storeSupplement: (publication) => {
        return http.post('diribas-publications-supplement', {
            publication
        });
    },

    delete: (id) => {
        return http.delete(`diribas-publications/${id}`);
    },

    updateAttachId: (id, diribas_publication_id) => {
        return http.put(`diribas-update-attach-id/${id}`, {
            diribas_publication_id
        });
    },

    getPostById: (id) => {
        return http.get(`diribas-posts/${id}`);
    },

    listPaginatePublications: (params) => {
        return http.get('diribas-publications-paginate', {
            params
        });
    },

    listSupplement: () => {
        return http.get(`diribas-posts-supplement`);
    },

    createSupplement: () => {
        return http.post(`diribas-posts-supplement`);
    },

    publishSupplement: () => {
        return http.post(`diribas-posts-publish-supplement`);
    },

    deletePost: (id) => {
        return http.delete(`diribas-posts-supplement/${id}`);
    },

    listPostsPaginate: (params) => {
        return http.get('diribas-posts-paginate', {
            params
        });
    },

    listPublicationsPaginate: (params) => {
        return http.get('diribas-publications-paginate', {
            params
        });
    },

    listPublicationsPaginateList: (params) => {
        return http.get('diribas-publications-paginate-list', {
            params
        });
    },

    checkSupplement: (id) => {
        return http.get(`diribas-publications-check-supplement/${id}`);
    },

    getPdf: () => {
        return http.get(`diribas-posts-pdf`);
    },

    generateDiribas: () => {
        return http.get(`diribas-posts-generate`);
    },

    removeNotice: (id) => {
        return http.delete(`diribas-notices/${id}`);
    },

    storeNotice: (formData) => {
        return http.post(`diribas-notices`, formData);
    },

    findAllNotice: () => {
        return http.get(`diribas-notices`);
    },

    updateNotice:(notice) => {
        return http.put(`diribas-notices/${notice.id}`, {
            notice
        });
    },

    verifyDup: (publication) => {
        return http.post('diribas-publications-dup', {
            ...publication
        });
    },

    verifyDupSupplement: (publication) => {
        return http.post('diribas-publications-supplement-dup', {
            ...publication
        });
    },
}