import 'core-js';
import { http } from './config'

export default {
    store:(permission) => {
        return http.post('permissions', {
            ...permission
        });
    },

    list:() => {
        return http.get('permissions');
    },

    listById:(permissionId) => {
        return http.get(`permissions/${permissionId}`);
    },

    update:(permission) => {
        return http.put(`permissions/${permission.id}`, {
            ...permission
        });
    },

    listPaginate:(params) => {
        return http.get('permissions-paginate',{
            params
        });
    },

    delete:(id) => {
        return http.delete(`permissions/${id}`);
    }

}