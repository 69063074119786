import 'core-js';
import { http } from './config'

export default {
    store:(user) => {
        return http.post('users', {
            ...user
        });
    },

    list:() => {
        return http.get('users');
    },

    listById:(userId) => {
        return http.get(`users/${userId}`);
    },

    listPaginate:(params) => {
        return http.get('users-paginate', {
            params
        });
    },

    listByName:(name) => {
        return http.get(`publication-types-name/${name}`);
    },

    listRole:(roleId) => {
        return http.get(`roles/${roleId}`);
    },

    update:(user) => {
        return http.put(`users/${user.id}`, {
            ...user
        });
    },

    delete:() => {
        
    }

}