import 'core-js';
import { http } from './config'

export default {
    store: (survey) => {
        return http.post('survey', {
            ...survey
        });
    },

    list: () => {
        return http.get('survey');
    },

    listById: (id) => {
        return http.get(`survey/${id}`);
    },

    listPaginate: (params) => {
        return http.get('survey-paginate', {
            params
        });
    },

    update: (survey) => {
        return http.put(`survey/${survey.id}`, {
            ...survey
        });
    },

    delete: (id) => {
        return http.delete(`survey/${id}`);
    },

    exportGeneralReport: () => {
        return http.get(`survey-export-report-general`);
    },

}