<template>
  <b-container fluid class="main-container">
    <link
      rel="stylesheet"
      href="../../node_modules/@fortawesome/fontawesome-free/css/all.css"
    />
    <nav-bar-pref></nav-bar-pref>
    <b-container class="banner-container">
      <b-carousel
        id="carousel-1"
        v-model="slideBanner"
        :interval="20000"
        controls
        indicators
        background="#ababab"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
            
      <!-- <b-carousel-slide>
          <template #img class="banner">
            <img
              @click="
                openUrl(
                  'https://www.ribasdoriopardo.ms.gov.br/consulta-publica-logradouros-2024'
                )
              "
              width="100%"
              src=".././assets/consulta-publica.gif"
            />
          </template>
      </b-carousel-slide> -->
      <b-carousel-slide>
          <template #img class="banner">
            <img
              @click="
                openUrl(
                  'https://diribas.s3.sa-east-1.amazonaws.com/747+-+SUPLEMENTO.pdf')
              "
              width="100%"
              src=".././assets/sem-marca-concurso-publico---banner.gif"
            />
          </template>
        </b-carousel-slide>
      <b-carousel-slide>
          <template #img class="banner">
            <img
              @click="
                openUrl(
                  'http://45.174.220.245:8056/servicosweb/home.jsf'
                )
              "
              width="100%"
              src=".././assets/Taxa-do-Lixo.gif"
            />
          </template>
        </b-carousel-slide>
       <b-carousel-slide>
          <template #img class="banner">
            <img 
              width="100%" 
              src=".././assets/campanha-dengue---banner.gif"/>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template #img class="banner">
            <img 
            @click="
                openUrl(
                  'https://www.ribasdoriopardo.ms.gov.br/publicacoes/3859/-prefeitura-de-ribas-do-rio-pardo-inicia-campanha-de-vacinacao-contra-gripe-2024')
              "
              width="100%" 
              src=".././assets/banner-campanha-gripe.gif"/>
          </template>
        </b-carousel-slide>
        
      </b-carousel>
    </b-container>
    <b-row class="services-row">
      <b-container fluid class="services-container">
        <VueSlickCarousel v-bind="settings">
          <div
            v-for="service in services"
            :key="service.name"
            class="carousel-item"
          >
            <div class="wrapper">
              <router-link v-if="!service.blank" :to="service.route">
                <i :class="service.icon"></i>
                <p>{{ service.name }}</p>
                <small>Clique e acesse</small>
              </router-link>
              <a v-else :href="service.route" target="_blank">
                <i :class="service.icon"></i>
                <p>{{ service.name }}</p>
                <small>Clique e acesse</small>
              </a>
            </div>
          </div>
        </VueSlickCarousel>
      </b-container>
    </b-row>
    <b-row class="middle-row">

    <b-col md="12" lg="100" sm="12">
      <img
              width= 40% 
              src=".././assets/aviso_restricao_conteudo_site.png"
            />
    </b-col>
       
      <!-- <b-col md="12" lg="5" sm="12" class="carousel-container">
        <p class="label">DESTAQUES</p>
        <div v-if="featuredNews.length <= 0" style="min-height: 450px"></div>
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="200"
          style="text-shadow: 1px 1px 2px #333"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide
            v-for="featured in featuredNews"
            :key="featured.id"
            :caption="featured.title"
          >
            <img
              slot="img"
              class="d-block img-fluid w-100"
              width="480"
              height="480"
              :src="
                featured.PublicationImgs[0] && featured.PublicationImgs[0].url
                  ? featured.PublicationImgs[0].url
                  : require('.././assets/noimg.png')
              "
              alt="image slot"
              v-on:click="goTo('publication-view', featured.id, featured.title)"
            />
          </b-carousel-slide>
        </b-carousel>
      </b-col>
      <b-col md="12" sm="12" lg="5" class="news-container">
        <p class="label">NOTÍCIAS</p>
        <b-card-group>
          <b-card
            v-for="publication in news"
            :key="publication.id"
            :title="
              publication.title.length <= 75
                ? publication.title
                : publication.title.substring(0, 75) + '...'
            "
            :img-src="
              publication.PublicationImgs[0] &&
              publication.PublicationImgs[0].url
                ? publication.PublicationImgs[0].url
                : require('.././assets/noimg.png')
            "
            img-top
            tag="article"
            @click="goTo('publication-view', publication.id, publication.title)"
            class="hvr-shadow"
          >
            <div class="tag-wrapper">
              <hr class="separator" />
              <small
                v-if="publication.tags && publication.tags.length > 0"
                class="tag"
                >{{ publication.tags[0].name }}</small
              >
            </div>
            <b-card-text>
              <span>Saiba mais</span>
            </b-card-text>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col lg="2" md="12" sm="12" class="news-list-container">
        <p class="label"></p>
        <ul class="news-list-ul">
          <li v-for="publication in newsList" :key="publication.id">
            <b-card-group :title="publication.title" class="news-list-group">
              <b-card
                :img-src="
                  publication.PublicationImgs[0] &&
                  publication.PublicationImgs[0].url
                    ? publication.PublicationImgs[0].url
                    : require('.././assets/noimg.png')
                "
                img-left
                @click="
                  goTo('publication-view', publication.id, publication.title)
                "
              >
                <small
                  v-if="publication.tags && publication.tags.length > 0"
                  class="news-list-tag"
                  >{{ publication.tags[0].name }}</small
                >
                <span class="news-list-title">
                  {{ formatTitle(publication.title) }}
                </span>
              </b-card>
            </b-card-group>
            <hr class="news-list-separator" />
          </li>
        </ul>
        <div>
          <p class="label all">
            <router-link
              :to="{
                name: 'list',
                params: { tag: 'todas', type: typeId, page: 0, size: 10 },
              }"
              style="text-decoration: none"
              >VER TODAS</router-link
            >
            <i class="fas fa-angle-right"></i>
          </p>
        </div>
      </b-col> -->
    </b-row> 
    <!-- <b-row class="gallery-row">
      <b-col>
        <div class="gallery-wrapper col-md-12">
          <div class="gallery-link col-md-4">
            <div class="gallery-title-btn">
              <div class="title">
                <span class="first">Galeria</span><br />
                <span>de Fotos</span>
              </div>
              <b-button
                variant="primary"
                :to="{
                  name: 'gallery-list-home',
                  params: { page: 0, size: 10 },
                }"
                >Clique e veja mais</b-button
              >
            </div>
          </div>
          <div id="gallery" class="gallery-rotate col-md-8">
            <ul>
              <li v-for="img in randomImgs" :key="img.id">
                <div class="gallery-label">
                  <span
                    >Ir à galeria <i class="fas fa-arrow-circle-right"></i
                  ></span>
                </div>
                <img
                  @click="
                    goTo(
                      'gallery-view',
                      img.gallery_img.id,
                      img.gallery_img.title
                    )
                  "
                  :src="img.url"
                />
              </li>
            </ul>
          </div>
        </div>
      </b-col>
    </b-row> -->
    <!-- <b-row class="timeline-row">
      <b-col class="pd-title-wrapp" md="4">
        <div class="pd-title">
          <div class="pd-main-title">Linha do Tempo Plano Diretor</div>
          <div>
            <span>Acompanhe aqui</span>
          </div>
        </div>
      </b-col>
      <b-col class="pd-col">
        <div class="pd-wrap">
          <pd-timeline></pd-timeline>
        </div>
      </b-col>
    </b-row> -->
    <!-- <b-modal
      ref="popup-modal"
      footer-bg-variant="light"
      :hide-footer="true"
      :title="lastBoletim.title"
    >
      <img :src="lastBoletim.img" alt="Boletim covid-19" />
    </b-modal> -->
    <footer-pref></footer-pref>
  </b-container>
</template>
    
<script>
import FooterPref from "../components/FooterPref";
import NavBarPref from "../components/NavBarPref";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import PublicationService from "../services/PublicationService";
import GalleryImgService from "../services/GalleryImgService";
import { isMobile } from "mobile-device-detect";
// import PdTimeline from "../components/PdTimeline";

export default {
  name: "Home",
  data() {
    return {
      settings: {
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        touchThreshold: 5,
        autoplay: false,
        autoplaySpeed: 4000,
      },
      news: [],
      newsList: [],
      featuredNews: [],
      window: {
        width: 0,
      },
      removedNews: "",
      removed: false,
      slide: 0,
      slideBanner: 0,
      sliding: null,
      typeId: null,
      lastBoletim: "",
      randomImgs: [],
      horizontalScroll: true,
      services: [
        {
          route: "http://45.174.220.245:8056/issweb/home.jsf",
          icon: "far fa-file-alt",
          name: "NOTA FISCAL ELETRÔNICA",
          blank: true,
        },
        {
          route: "http://45.174.220.245:8079/transparencia/",
          icon: "fas fa-search-dollar",
          name: "PORTAL DA TRANSPARÊNCIA",
          blank: true,
        },
        {
          route: "/diribas",
          icon: "far fa-newspaper",
          name: "DIÁRIO OFICIAL",
        },
        {
          route: "/editais",
          icon: "fas fa-gavel",
          name: "LICITAÇÕES E EDITAIS",
        },
        {
          route: "https://ribasdoriopardo.flowdocs.com.br",
          icon: "fas fa-book-open",
          name: "PROTOCOLO DIGITAL",
          blank: true,
        },
        {
          route: "http://45.174.220.245:8056/servicosweb/home.jsf",
          icon: "fas fa-file-signature",
          name: "EMISSÃO DE CND",
          blank: true,
        },
        {
          route: "/ouvidoria",
          icon: "fas fa-bullhorn",
          name: "OUVIDORIA",
        },
        {
          route: "http://45.174.220.245:8056/servicosweb/home.jsf",
          icon: "fas fa-home",
          name: "IPTU/LIXO 2024",
          blank: true,
        },
      ],
    };
  },
  metaInfo() {
    return {
      title: "Prefeitura Municipal de Ribas do Rio Pardo - MS",
      htmlAttrs: {
        lang: "pt-BR",
        amp: true,
      },
      meta: [{ charset: "utf-8" }],
    };
  },
  computed: {
    isMobile() {
      return isMobile;
    },
  },
  mounted() {
    // PublicationService.listLastBoletim().then((res) => {
    //   this.lastBoletim = res.data;
    //   this.lastBoletim.img = res.data.PublicationImgs[0].url;
    //   setTimeout(() => {
    //     this.$refs["popup-modal"].show();
    //   }, 1500);
    // });
    PublicationService.listCards().then((res) => {
      PublicationService.listFour(res.data[0].id, res.data[1].id).then(
        (resFour) => {
          this.newsList = resFour.data;
          this.newsList.forEach((publication) => {
            publication.PublicationImgs = publication.PublicationImgs.filter(
              (img) => {
                return img.main;
              }
            );
          });
        }
      );
      this.news = res.data;
      if (this.news.length > 0) this.typeId = this.news[0].PublicationTypeId;
      this.news.forEach((publication) => {
        publication.PublicationImgs = publication.PublicationImgs.filter(
          (img) => {
            return img.main;
          }
        );
      });
    }),
      PublicationService.listFeatured().then((res) => {
        this.featuredNews = res.data;
        this.featuredNews.forEach((publication) => {
          publication.PublicationImgs = publication.PublicationImgs.filter(
            (img) => {
              return img.main;
            }
          );
        });
      });
    if (!isMobile) {
      GalleryImgService.listRandom(10).then((res) => {
        this.randomImgs = res.data;
      });
    } else {
      GalleryImgService.listRandom(5).then((res) => {
        this.randomImgs = res.data;
      });
    }
    if (!isMobile) {
      const scrollContainer = document.getElementById("gallery");

      scrollContainer.addEventListener("wheel", (evt) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
      });
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    goTo(name, id, title) {
      if (title) {
        title = title.replace(/[\s$-/:-?{-~!"^_`[\]]/g, "-").toLowerCase();
        title = title
          .normalize("NFD")
          .replace(/[\u0300-\u036f\u00aa\u00ba]/g, "");
      }
      this.$router.push({
        name,
        params: {
          id,
          title,
        },
      });
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    handleResize() {
      if (window.innerWidth >= 1350) {
        this.settings.slidesToShow = 8;
        if (this.removed) {
          this.newsList.push(this.removedNews);
          this.removed = false;
        }
      }
      if (window.innerWidth <= 1360) {
        this.settings.slidesToShow = 3;
        if (!this.removed) {
          this.removedNews = this.newsList.pop();
          this.removed = true;
        }
      }
      if (window.innerWidth <= 992) {
        if (this.removed) {
          this.newsList.push(this.removedNews);
          this.removed = false;
        }
      }
      if (window.innerWidth > 576 && !this.horizontalScroll) {
        this.scroll;
      }
      if (window.innerWidth <= 420) this.settings.slidesToShow = 2;
    },
    formatTitle(title) {
      return title.length <= 75 ? title : title.substring(0, 75) + "...";
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
  },
  components: {
    VueSlickCarousel,
    FooterPref,
    NavBarPref,
    // PdTimeline,
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
::-webkit-scrollbar-thumb {
  background: #b3afb3;
  border-radius: 9px;
}
::-webkit-scrollbar-thumb:hover {
  background: #8f8f8f;
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 9px;
  box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}
.modal-body {
  img {
    height: auto;
    width: 100%;
  }
}
.main-container {
  padding: unset;
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  .banner-container {
    width: 100%;
    margin: 1% auto 1% auto;
    display: flex;
    padding: unset;
    &:hover {
      cursor: pointer;
    }
    .banner {
      margin: auto;
      height: 100%;
      padding: unset;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .services-row {
    background-color: #204991;
    height: 100%;
    max-height: 140px;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    .services-container {
      height: 100%;
      margin: 0 auto 0 auto;
      width: 99%;
      @media (max-width: 1350px) {
        width: 90%;
      }
      @media (max-width: 768px) {
        width: 80%;
      }
      .slick-slider {
        height: 100%;
      }
      .carousel-item {
        margin-top: 20px;
        width: 40%;
        border-right: 1px solid #ccc;
        @media (max-width: 990px) {
          margin-top: 10px;
        }
        @media (max-width: 768px) {
          min-height: 90px;
        }
        @media (max-height: 970px) and (max-height: 1800px) {
          margin: 10px auto 10px auto;
        }
        .wrapper {
          margin: auto;
          a {
            text-decoration: none;
          }
          
          &:hover {
            i,
            p,
            small {
              color: #edf0ee;
              text-shadow: 2px 2px 4px #000000;
            }
          }
        }
        i {
          transition: all 0.4s ease;
          color: white;
          font-size: 40px;
          @media (max-width: 1280px) {
            font-size: 40px;
          }
          @media (max-width: 768px) {
            font-size: 25px;
          }
        }
        p {
          transition: all 0.4s ease;
          color: white;
          font-size: 11px;
          margin-top: 5px;
          margin-bottom: 0;
          @media (max-width: 1400px) {
            font-size: 10px;
          }
          @media (max-width: 1024px) {
            font-size: 12px;
          }
          @media (max-width: 768px) {
            font-size: 10px;
          }
        }
        small {
          transition: all 0.4s ease;
          color: white;
          font-weight: bold;
          @media (max-width: 1400px) {
            font-size: 70%;
          }
        }
      }
    }
  }
  .middle-row {
    margin: 15px auto 5px auto;
    width: 95%;
    @media (max-width: 1450px) {
      margin: 15px 2px 5px 2px;
      width: 100%;
    }
    @media (max-width: 992px) {
      margin: inherit;
    }
         
    .label {
      font-family: Raleway;
      font-weight: bold;
      color: #204991;
      display: flex;
      margin: unset;
      i {
        margin: auto auto auto 5px;
      }
    }
    .all {
      float: right;
    }
    .carousel-container {
      .carousel-item {
        &:hover img {
          filter: brightness(20%);
        }
        &:hover .carousel-caption {
          opacity: 1;
        }
        img {
          cursor: pointer;
          transition: all 0.4s ease;
          filter: brightness(80%);
          min-height: 450px;
          max-height: 450px;
          object-fit: cover;
          @media (max-width: 1660px) {
            min-height: 500px;
          }
        }
        .carousel-caption {
          opacity: 0;
          transition: all 0.4s ease;
          font-family: Raleway;
          font-weight: bolder;
        }
      }
    }
    .news-container {
      @media (max-width: 768px) {
        margin-top: 15px;
      }
      padding-right: 5px;
      .card {
        margin-right: 5px;
        max-height: 450px;
        min-height: 450px;
        @media (max-width: 1660px) {
          min-height: 500px;
        }
        @media (max-width: 880px) {
          flex: 1 0 40%;
        }
        @media (max-width: 768px) {
          max-height: 510px;
          min-height: inherit;
        }
        img {
          object-fit: cover;
          max-height: 60%;
          min-height: 60%;
          @media (max-width: 768px) {
            max-height: 300px;
          }
        }
        .tag-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .tag {
            background-color: #3265bf;
            color: white;
            font-weight: bold;
            padding: 1px;
            border-radius: 5px;
          }
        }
        .card-body {
          box-shadow: 0px 0px 0px grey;
          transition: box-shadow 0.3s ease-out;
          box-shadow: 0.8px 0.9px 3px grey;
          padding: 0.7rem;
          .card-title {
            font-size: 1.1rem;
          }
        }
        &:hover {
          cursor: pointer;
          box-shadow: 1px 8px 20px grey;
          transition: box-shadow 0.3s ease-in;
        }
      }
      .card-title {
        font-family: Raleway;
        font-weight: bold;
        color: black;
        text-align: initial;
      }
      .card-text {
        text-align: initial;
      }
      .separator {
        border: 2px solid #efd02f;
        margin: unset;
        width: 30%;
        border-radius: 5px;
        margin-bottom: 2%;
      }
    }
    .news-list-container {
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 992px) {
        padding-left: 15px;
        padding-right: 15px;
      }
      ul {
        margin-top: 24px;
        list-style-type: none;
        padding-left: 0;
        @media (max-width: 1380px) {
          margin-top: 10px;
        }
      }
      .news-list-separator {
        margin: 10px 0 10px 0;
        border: 1px solid #c3c3c3;
        border-radius: 5px;
      }
      .news-list-group {
        display: flex;
        flex-direction: column;
        .news-list-title {
          font-family: Raleway;
          font-weight: bold;
          color: black;
          text-align: initial;
          font-size: 0.8rem;
          line-height: 1.1rem;
          display: block;
          word-break: break-word;
        }
        .news-list-tag {
          background-color: #3265bf;
          color: white;
          font-weight: bold;
          padding: 1px;
          border-radius: 5px;
        }
        .card {
          background-color: transparent;
          border: none;
          align-items: center;
          img {
            object-fit: cover;
            width: 80px;
            height: 80px;
          }
          .card-body {
            padding: 0;
            margin-left: 10px;
            text-align: initial;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .photo-container {
      img {
        min-height: 450px;
        max-height: 450px;
        max-width: 100%;
        @media (max-width: 420px) {
          min-height: 390px;
          max-height: 390px;
        }
      }
    }
  }
  .gallery-row::after {
    backdrop-filter: blur(5px);
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .gallery-row {
    margin-bottom: 20px;
    margin-top: 40px;
    background-image: url(../assets/ribas-paisagem.jpg);
    background-position: center;
    position: relative;
    @media (max-width: 576px) {
      background-image: linear-gradient(to right, #00087b, #1749ee);
      margin-left: 0;
      margin-right: 0;
    }
    .gallery-wrapper {
      display: flex;
      justify-content: space-around;
      position: relative;
      z-index: 1;
      @media (max-width: 576px) {
        display: grid;
        justify-content: inherit;
      }
      .gallery-link {
        display: grid;
        justify-content: center;
        align-items: center;
        span {
          font-size: 3.5rem;
          font-weight: bold;
          color: white;
        }
        button {
          border-radius: 5px;
        }
        .gallery-title-btn {
          display: grid;
          .title {
            text-shadow: 2px 2px 8px #000000;
            line-height: 2.5rem;
            @media (max-width: 576px) {
              margin-top: 30px;
            }
            .first {
              font-size: 5rem;
            }
          }
        }
      }
      .gallery-rotate {
        overflow-x: auto;
        ul {
          list-style-type: none;
          display: flex;
          margin-top: 1rem;
          @media (max-width: 576px) {
            display: grid;
            padding: 0;
          }
          li {
            position: relative;
            &:hover .gallery-label {
              opacity: 0.9;
            }
            .gallery-label {
              position: absolute;
              width: 100%;
              height: 15%;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all 0.4s ease;
              background-color: black;
              opacity: 0;
              bottom: 0;
              border-radius: 5px;
              margin: 5px;
              span {
                font-weight: bold;
                color: white;
                i {
                  margin-left: 5px;
                }
              }
            }
            img {
              cursor: pointer;
              height: 300px;
              width: 350px;
              object-fit: cover;
              border-radius: 5px;
              margin: 5px;
              box-shadow: 2px 3px 10px 0px #000000;
              @media (max-width: 576px) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .timeline-row {
    .pd-title-wrapp {
      display: flex;
      justify-content: center;
      .pd-title {
        color: #204991;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .pd-main-title {
          font-size: 4rem;
          line-height: 3.5rem;
          @media (max-width: 767.98px) {
            font-size: 3rem;
          }
        }
        span {
          font-size: 2rem;
        }
      }
    }
    .pd-col {
      overflow: scroll;
      height: 450px;
      .pd-wrap {
        display: flex;
      }
    }
    @media (max-width: 576px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>