<template>
  <div class="vacinometro-container">
    <nav-bar-pref></nav-bar-pref>
    <iframe
      src="https://mais.saude.ms.gov.br:444/single/?appid=d3edb27b-8f52-4dff-a2e0-fc82bfc3fb6a&sheet=8cad7a0a-ba50-4760-a72b-f25fa7164022&opt=ctxmenu,currsel&select=$::Munic%C3%ADpios,Ribas%20do%20Rio%20Pardo"
      style="border: none; width: 100%; height: 100%"
    ></iframe>
    <footer-pref></footer-pref>
    <b-modal
      ref="response-modal"
      footer-bg-variant="light"
      ok-only
      title="Atenção!"
    >
    <p>Utilizamos o sistema e-Vacine da Secretaria de Estado de Saúde do Governo do Estado De Mato Grosso do Sul.</p>
    <p>Se o carregamento das informaões demorar aguarde ou tente recarregar a página.</p>
    </b-modal>
  </div>
</template>
<script>
import FooterPref from "../components/FooterPref.vue";
import NavBarPref from "../components/NavBarPref.vue";

export default {
  components: {
    FooterPref,
    NavBarPref,
  },
  mounted() {
    this.$refs["response-modal"].show();
  },
};
</script>
<style lang="scss">
.vacinometro-container {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>