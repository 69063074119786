<template>
  <div class="diribas-list">
    <nav-bar-pref></nav-bar-pref>
    <b-container>
      <b-col>
        <h1>Diário Oficial</h1>
      </b-col>
      <b-col>
        <b-form @submit.prevent="search" class="form">
          <b-form-group
            label="Você pode buscar por termo/frase ou data inicial e/ou final"
          >
            <div class="input-container">
              <b-form-input
                class="col-md-3"
                v-model="text"
                placeholder="Termo ou frase"
              ></b-form-input>
              <date-picker
                valueType="format"
                v-model="startDate"
                class="col-md-4 m-auto"
              ></date-picker>
              <date-picker
                valueType="format"
                v-model="endDate"
                class="col-md-4 m-auto"
              ></date-picker>
              <b-button type="submit" variant="primary"> Buscar </b-button>
            </div>
          </b-form-group>
        </b-form>
      </b-col>
      <b-col>
        <div :class="loading ? 'show' : 'hide'">
          <loader
            object="#ff9633"
            color1="#ffffff"
            color2="#17fd3d"
            size="5"
            speed="2"
            bg="#cccccc"
            objectbg="#999793"
            opacity="80"
            name="dots"
          ></loader>
        </div>

        <ul class="doc-container">
          <li
            class="diribas-item hvr-shadow"
            v-for="diribas in diribasList"
            :key="diribas.id"
            @click="openFile(diribas.url)"
          >
            <small>{{ formatDate(diribas.createdAt, diribas.date) }}</small>
            <p>
              Clique para visualizar o documento nº {{ diribas.display_name }}
            </p>
            <div class="file">
              <i class="far fa-file-pdf fa-3x"></i>
              <div class="file-information">
                <p>{{ diribas.display_name }}.pdf</p>
                <small> {{ formatFileSize(diribas.size) }} </small>
              </div>
            </div>
          </li>
        </ul>
        <span v-if="diribasList.length <= 0"> Nenhum arquivo encontrado </span>
      </b-col>
      <b-row>
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="pageSize"
          align="center"
          @change="handlePageChange"
          style="margin: 15px auto 15px auto"
        ></b-pagination>
      </b-row>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import DiribasService from "../../services/DiribasService";
import moment from "moment";
import NavBarPref from "../../components/NavBarPref.vue";
import FooterPref from "../../components/FooterPref.vue";
import filesize from "filesize";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  data() {
    return {
      diribasList: [],
      page: null,
      count: 0,
      pageSize: 18,
      startDate: "",
      endDate: "",
      text: null,
      loading: false,
    };
  },
  metaInfo() {
    return {
      title: "Diário Oficial",
      htmlAttrs: {
        lang: "pt-BR",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          property: "og:description",
          content: "Diário Oficial do Município Ribas do Rio Pardo - MS",
        },
        {
          name: "description",
          content: "Diário Oficial do Município Ribas do Rio Pardo - MS",
        },
      ],
    };
  },
  components: {
    NavBarPref,
    FooterPref,
    DatePicker,
  },
  mounted() {
    this.loading = true;
    this.text = this.$diribasText ? this.$diribasText : null;
    setTimeout(() => {
      this.page = parseInt(this.$diribasPage) + 1;
    }, 300);
    DiribasService.listPaginate({
      page: this.$diribasPage,
      size: this.pageSize,
      text: this.text,
      startDate: this.startDate
        ? this.startDate
        : moment().subtract(10, "years").format("YYYY-MM-DD"),
      endDate: this.endDate
        ? this.endDate
        : moment().add(10, "years").format("YYYY-MM-DD"),
    }).then((res) => {
      this.diribasList = res.data.diribas;
      this.count = res.data.totalItens;
      this.loading = false;
    });
  },
  methods: {
    formatDate(createdAt, date) {
      if (date) return moment(date).format("DD/MM/YYYY");

      return moment(createdAt).format("DD/MM/YYYY");
    },
    formatFileSize(size) {
      return filesize(size);
    },
    handlePageChange(value) {
      this.page = value;
      (this.text = this.text == "" ? null : this.text),
        DiribasService.listPaginate({
          page: --value,
          size: this.pageSize,
          text: this.$diribasText ? this.$diribasText : this.text,
          startDate: this.startDate
            ? this.startDate
            : moment().subtract(10, "years").format("YYYY-MM-DD"),
          endDate: this.endDate
            ? this.endDate
            : moment().add(10, "years").format("YYYY-MM-DD"),
        }).then((res) => {
          this.diribasList = res.data.diribas;
          this.count = res.data.totalItens;
          this.$router.push({
            name: "diribaslist",
            params: {
              page: this.page - 1,
              size: this.pageSize,
              text: this.$diribasText
                ? this.$diribasText
                : this.text,
            },
          }).catch(() => {});
        });
    },
    search() {
      this.loading = true;
      DiribasService.listPaginate({
        page: 0,
        size: this.pageSize,
        text: this.text,
        startDate: this.startDate
          ? this.startDate
          : moment().subtract(10, "years").format("YYYY-MM-DD"),
        endDate: this.endDate
          ? this.endDate
          : moment().add(10, "years").format("YYYY-MM-DD"),
      }).then((res) => {
        this.diribasList = res.data.diribas;
        this.count = res.data.totalItens;
        this.$router.push({
          name: "diribaslist",
          params: {
            page: 0,
            size: this.pageSize,
            text: this.text == "" ? null : this.text,
          },
        }).catch(() => {});
        this.loading = false;
      });
    },
    openFile(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style lang="scss">
.diribas-list {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  .form {
    padding: 5px;
    .input-container {
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        display: grid;
        justify-content: inherit;
        div,
        input,
        button {
          margin-top: 5px;
        }
      }
      .mx-datepicker {
        width: 100%;
        padding: unset;
      }
    }
  }
  h1 {
    color: black;
    font-family: Raleway;
    font-weight: bold;
    margin-top: 50px;
    text-align: justify;
  }
  .diribas-separator {
    border: 0;
    height: 1px;
    background: #ccc;
    width: 100%;
  }
  .hvr-shadow {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
  }
  .hvr-shadow:hover,
  .hvr-shadow:focus,
  .hvr-shadow:active {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
  .doc-container {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: unset;
    li {
      &:hover {
        cursor: pointer;
      }
      &:hover .file-information p {
        color: #204991;
      }
    }
    .diribas-item {
      flex-grow: 1;
      text-align: justify;
      margin-top: 25px;
      border-top: 1px solid rgb(185, 185, 185);
      margin-right: 1%;
      @media (max-width: 990px) {
        width: calc(100% * (1 / 2) - 10px - 1px);
      }
      @media (max-width: 425px) {
        width: calc(100% * (1 / 1) - 10px - 1px);
      }
      width: calc(100% * (1 / 3) - 10px - 1px);
      small {
        font-weight: bold;
      }
      .file {
        display: flex;
        i {
          color: red;
        }
        p {
          margin-bottom: unset;
        }
        .file-information {
          width: 100%;
          margin-left: 5%;
          p {
            transition: all 0.4s ease;
            text-align: initial;
          }
        }
      }
    }
  }
}
</style>