<template>
  <div
    class="main-dashboard"
    :class="collapsed ? 'collapsed' : 'non-collapsed'"
  >
    <nav-bar-dashboard></nav-bar-dashboard>
    <sidebar-dashboard></sidebar-dashboard>
    <b-container fluid>
      <div class="dash-title">
        <h1>RELATÓRIOS - EDITAIS</h1>
      </div>
      <div
        class="bg-dash"
        :style="collapsed ? 'display: none' : 'display: inherit'"
      ></div>
      <div class="dash-cards-wrap">
        <div class="dash-card edict-report-cards col-md-4">
          <div class="text-wrap-dash-edict">
            <span class="dash-card-title">Total de Visualizações</span>
            <animated-number
              class="edict-count"
              :value="totalData.total"
              :formatValue="formatNumber"
              :duration="800"
            />
          </div>
        </div>
        <div class="dash-card edict-report-cards col-md-4">
          <div class="text-wrap-dash-edict">
            <span class="dash-card-title">Total Pessoa Física</span>
            <animated-number
              class="edict-count"
              :value="totalData.physical"
              :formatValue="formatNumber"
              :duration="800"
            />
          </div>
        </div>
        <div class="dash-card edict-report-cards col-md-4">
          <div class="text-wrap-dash-edict">
            <span class="dash-card-title">Total Pessoa Jurídica</span>
            <animated-number
              class="edict-count"
              :value="totalData.legal"
              :formatValue="formatNumber"
              :duration="800"
            />
          </div>
        </div>
      </div>
      <div class="dash-last-label">
        <span> Listagem de editais </span>
        <b-button @click="exportGeneralData" variant="success"
          ><i class="fas fa-download"></i>Exportar dados</b-button
        >
      </div>
      <div class="dash-last-pub">
        <vue-good-table
          class="table-bordered table-hover"
          :columns="columns"
          :rows="publications"
          :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
            nextLabel: 'Próximo',
            prevLabel: 'Anterior',
            rowsPerPageLabel: 'Linhas por página',
            ofLabel: 'de',
            pageLabel: 'página',
          }"
        >
          <template class="dash-actions" slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'actions'">
              <b-button
                title="Exportar planilha"
                class="edit-button"
                variant="primary"
                @click="exportData(props.row.edict.id)"
                :style="collapsed ? 'margin-right: 5px;' : 'margin-right: 0;'"
              >
                <i class="fas fa-download"></i>
              </b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <b-modal
          ref="response-modal"
          footer-bg-variant="light"
          ok-only
          :title="confirmMessage"
        >
        </b-modal>
      </div>
    </b-container>
  </div>
</template>
<script>
import SidebarDashboard from "../../../components/SidebarDashboard";
// import PublicationService from "../../../services/PublicationService";
import UserService from "../../../services/UserService";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";
import NavBarDashboard from "../../../components/NavBarDashboard.vue";
import EdictLogService from "../../../services/EdictLogService";
import AnimatedNumber from "animated-number-vue";
import _ from "lodash";

export default {
  data() {
    return {
      collapsed: true,
      publications: [],
      modalText: "",
      idToDelete: null,
      confirmMessage: "",
      user: "",
      delPerm: false,
      editPerm: false,
      totalData: {},
      columns: [
        {
          field: "edict.date",
          label: "Data",
          dateOutputFormat: "DD-MM-YYYY",
          dateInputFormat: "DD-MM-YYYY",
          filterOptions: {
            enabled: true,
            placeholder: "Busque pela data da publicação",
            filterFn(data, filterString) {
              return moment(data)
                .format("DD/MM/YYYY HH:mm")
                .includes(filterString);
            },
          },
          formatFn(date) {
            return moment(date).format("DD/MM/YYYY HH:mm");
          },
        },
        {
          field: "edict.title",
          label: "Título",
          filterOptions: {
            enabled: true,
            placeholder: "Busque pelo título da publicação",
          },
        },
        {
          field: "physical",
          label: "Qtd P. Física",
        },
        {
          field: "legal",
          label: "Qtd P. Jurídica",
        },
        {
          field: "total",
          label: "Qtd Total",
        },
        {
          field: "actions",
          label: "Exportar",
          sortable: false,
        },
      ],
    };
  },
  created() {
    EdictLogService.listForTable().then((res) => {
      this.publications = _.orderBy(res.data, ["edict.date"], ["desc"]);
    });
  },
  mounted() {
    this.$root.$on("collapse", this.collapse);

    UserService.listById(localStorage.getItem("userId")).then((res) => {
      this.user = res.data;
      this.delPerm = res.data.role.delete;
      this.editPerm = res.data.role.edit;
    });
    EdictLogService.countAll().then((res) => {
      this.totalData = res.data;
    });
  },
  components: {
    SidebarDashboard,
    VueGoodTable,
    NavBarDashboard,
    AnimatedNumber,
  },
  methods: {
    collapse() {
      this.collapsed = !this.collapsed;
    },
    showModal(row) {
      this.modalText = row.title;
      this.idToDelete = row.id;
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    exportData(publicationId) {
      EdictLogService.exportReport(publicationId).then((res) => {
        window.open(res.data.Location, "_blank");
      });
    },
    exportGeneralData() {
      EdictLogService.exportGeneralReport().then((res) => {
        window.open(res.data.Location, "_blank");
      });
    },
    formatNumber(value) {
      return `<span>${Number(value).toFixed(0)}</span>`;
    },
  },
};
</script>
<style lang="scss">
.collapsed {
  padding-left: 50px;
  @media (max-width: 1400px) {
    padding-left: 0;
  }
}
.non-collapsed {
  padding-left: 350px;
  @media (max-width: 1400px) {
    padding-left: 0;
  }
}
.main-dashboard {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Raleway;
  transition: 0.3s ease;
  margin-right: 80px;
  margin-left: 80px;
  z-index: 1;
  @media (max-width: 700px) {
    margin-right: 0;
    margin-left: 60px;
  }

  .dash-title {
    margin-top: 100px;
    margin-left: 0;
    text-align: initial;
    font-family: Ubuntu;
    font-weight: bolder;
    color: #707070;
    @media (max-width: 700px) {
      font-size: 2rem;
    }
  }
  .bg-dash {
    @media (max-width: 1400px) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100vw;
      height: 100vh;
      opacity: 0.6;
      background-color: #343a40;
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }
  .dash-cards-wrap {
    display: flex;
    margin-top: 25px;
    justify-content: space-between;
    @media (max-width: 770px) {
      display: grid;
      justify-content: inherit;
    }
    .dash-card {
      height: 230px;
      color: white;
      text-align: initial;
      border-radius: 5px;
      max-width: 32.5%;
      @media (max-width: 770px) {
        max-width: 100%;
        margin-bottom: 10px;
        height: 200px;
      }
      a {
        text-decoration: none;
        color: white;
      }
      .text-wrap-dash-edict {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        .dash-card-title {
          text-align: center;
          font-size: 30px;
          font-weight: bold;
          @media (max-width: 1100px) {
            font-size: 20px;
          }
          @media (max-width: 900px) {
            font-size: 15px;
          }
          @media (max-width: 770px) {
            font-size: 20px;
          }
        }
        .edict-count {
          text-align: center;
          font-family: Ubuntu;
          font-size: 75px;
          font-weight: bold;
          color: #204991;
        }
        .dash-sub-title {
          font-size: 30px;
          font-weight: bold;
          @media (max-width: 1100px) {
            font-size: 20px;
          }
          @media (max-width: 900px) {
            font-size: 15px;
          }
          @media (max-width: 770px) {
            font-size: 20px;
          }
        }
      }
    }
    .holerite {
      background-image: linear-gradient(to right, #1749ee, #00087b);
    }
    .edict-report-cards {
      background-color: #ececec;
      color: #808080;
      border-radius: 5px;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
  }
  .dash-last-label {
    font-family: Raleway;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-weight: bold;
    }
    button {
      font-weight: bold;
      i {
        margin-right: 5px;
      }
    }
  }
  .dash-last-pub {
    margin-top: 10px;
    margin-bottom: 50px;
    font-weight: bold;
    font-family: Montserrat;
    .edit-button {
      max-width: 40px;
    }
  }
  .modal-dialog {
    max-width: 1000px;
  }
}
</style>