<template>
  <div>
    <nav-bar-pref></nav-bar-pref>
    <b-container class="upload-publication-main">
      <link
        rel="stylesheet"
        href="../../node_modules/@fortawesome/fontawesome-free/css/all.css"
      />
      <div class="publication-edit-title col-md-12">
        <h1 v-if="editMode">EDIÇÃO DA PUBLICAÇÃO</h1>
        <h1 v-else>NOVA PUBLICAÇÃO</h1>
      </div>
      <b-form @submit.prevent="send" class="form-publication">
        <b-form-group label-for="file">
          <div class="edit-wrap">
            <div class="col-md-12">
              <span class="required-mark">* </span
              ><label class="form-label" for="title"
                >Título da publicação</label
              >
              <b-form-input
                id="title"
                placeholder="Seu título aqui"
                required
                v-model="publication.title"
              ></b-form-input>
            </div>
          </div>
          <div class="edit-wrap">
            <div class="col-md-12">
              <label class="form-label" for="description"
                >Descrição/Subtítulo da publicação</label
              >
              <b-form-input
                id="description"
                placeholder="Descrição"
                v-model="publication.meta_description"
              ></b-form-input>
            </div>
          </div>
          <div class="edit-wrap">
            <div class="col-md-6 col-sm-12">
              <span class="required-mark">* </span
              ><label class="form-label" for="type">Tipo da publicação</label>
              <b-form-select
                :disabled="publicationTypes.length > 1 ? false : true"
                id="type"
                v-model="publication.publication_type"
              >
                <b-form-select-option
                  v-for="type in publicationTypes"
                  :key="type.id"
                  :value="type"
                  >{{ type.name }}</b-form-select-option
                >
              </b-form-select>
            </div>
            <div class="col-md-6 col-sm-12">
              <span class="required-mark">* </span
              ><label class="form-label" for="user">Autor da publicação</label>
              <b-form-select
                :disabled="users.length > 1 ? false : true"
                id="user"
                v-model="publication.user_id"
              >
                <b-form-select-option
                  v-for="user in users"
                  :key="user.id"
                  :value="user.id"
                  >{{ user.name }}</b-form-select-option
                >
              </b-form-select>
            </div>
          </div>
          <div class="edit-wrap">
            <div class="col-md-6 col-sm-12">
              <label class="form-label" for="tags">Tags da publicação</label>
              <multiselect
                id="tags"
                v-model="publication.tags"
                :options="tags"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Palavras-chave da publicação"
                label="name"
                track-by="name"
                :preselect-first="true"
              >
              </multiselect>
            </div>
            <div
              v-if="
                publication.publication_type &&
                publication.publication_type.name == 'Notícia'
              "
              class="col-md-6 col-sm-12"
            >
              <label class="form-label" for="featured"
                >Marque para aparecer nos destaques</label
              >
              <b-form-checkbox id="featured" v-model="publication.featured"
                >Mostrar nos destaques</b-form-checkbox
              >
            </div>
          </div>
          <div
            class="edit-wrap"
            v-if="
              publication.publication_type &&
              (publication.publication_type.name == 'Notícia' ||
                publication.publication_type.name == 'Plano Diretor')
            "
          >
            <div class="col-md-6 col-sm-12">
              <label class="form-label">Arquivo de áudio</label>
              <b-form-file
                placeholder="áudio para a publicação"
                v-model="audioFile"
              >
              </b-form-file>
            </div>
            <div class="col-md-6 col-sm-12">
              <label class="form-label"
                >Escolha entre imagem de destaque ou vídeo</label
              >
              <div class="upload-radio">
                <b-form-radio v-model="radioSelected" value="img"
                  >Imagem</b-form-radio
                >
                <b-form-radio v-model="radioSelected" value="video"
                  >Vídeo</b-form-radio
                >
              </div>
            </div>
          </div>
          <div
            class="edit-wrap"
            v-if="
              publication.publication_type &&
              publication.publication_type.name == 'Notícia' &&
              radioSelected == 'video'
            "
          >
            <div class="col-md-12 col-sm-12">
              <span class="required-mark">* </span>
              <label class="form-label" for="img-description"
                >Url do vídeo</label
              >
              <b-form-input
                required
                id="img-description"
                placeholder="Insira o link do vídeo (youtube)"
                v-model="video_url"
              ></b-form-input>
            </div>
          </div>
          <div
            v-if="
              publication.publication_type &&
              (publication.publication_type.name == 'Notícia') &&
              radioSelected == 'img'
            "
            class="edit-wrap"
          >
            <div class="col-md-6 col-sm-12">
              <span v-if="!editMode" class="required-mark">* </span
              ><label class="form-label" for="file"
                >Imagem de destaque da notícia</label
              >
              <b-form-file
                placeholder="imagem da notícia"
                id="file"
                v-model="file"
                :required="editMode ? false : true"
              >
              </b-form-file>
            </div>
            <div class="col-md-6 col-sm-12">
              <label class="form-label" for="img-description"
                >Descrição da imagem de destaque</label
              >
              <b-form-input
                id="img-description"
                placeholder="Descrição da imagem"
                v-model="mainImg.description"
              ></b-form-input>
            </div>
          </div>
          <div
            v-if="
              publication.publication_type &&
              (publication.publication_type.name == 'Notícia' ||
                publication.publication_type.name == 'Plano Diretor')
            "
            class="edit-wrap"
          >
            <div class="col-md-12">
              <label class="form-label" for="carrosel"
                >Imagens para o carrosel</label
              >
              <b-form-file
                id="carrosel"
                multiple
                placeholder="Arraste as imagens ou clique para selecionar"
                v-model="imgsCarousel"
                @change="carrouselUpload"
              >
              </b-form-file>
            </div>
          </div>
          <div
            v-if="editMode && uploadedImgs.length > 0"
            class="edit-wrap"
            style="margin-bottom: 0"
          >
            <div class="col-md-12">
              <small><b>Clique e arraste para alterar a posição</b></small>
            </div>
          </div>
          <div class="edit-wrap">
            <div class="col-md-12">
              <draggable
                v-model="uploadedImgs"
                ghost-class="ghost"
                @end="onEnd"
                :disabled="buttonDisabled"
              >
                <transition-group type="transition" name="flip-list">
                  <div
                    class="sortable"
                    v-for="img in uploadedImgs"
                    :key="img.url"
                  >
                    <div class="drag-wrap">
                      <img :src="img.url" /><span>{{ img.name }}</span>
                    </div>
                    <div>
                      <b-button
                        @click="removeImg(img)"
                        title="Remover imagem"
                        variant="outline-danger"
                        ><i class="far fa-trash-alt remove-icon"></i
                      ></b-button>
                    </div>
                  </div>
                </transition-group>
              </draggable>
            </div>
          </div>
          <div class="date-upload"></div>
          <div class="col-md-12 sticky-btn">
            <div class="editor-buttons">
              <div>
                <b-button
                  text
                  icon
                  @click="
                    editor.chain().focus().toggleHeading({ level: 1 }).run()
                  "
                  :class="{
                    'is-active': editor.isActive('heading', { level: 1 }),
                  }"
                  v-b-tooltip.hover
                  title="Título"
                >
                  <b> H1 </b>
                </b-button>
                <b-button
                  text
                  icon
                  @click="
                    editor.chain().focus().toggleHeading({ level: 2 }).run()
                  "
                  :class="{
                    'is-active': editor.isActive('heading', { level: 2 }),
                  }"
                  v-b-tooltip.hover
                  title="Subtítulo"
                >
                  <b> H2 </b>
                </b-button>
                <b-button
                  text
                  icon
                  @click="
                    editor.chain().focus().toggleHeading({ level: 3 }).run()
                  "
                  :class="{
                    'is-active': editor.isActive('heading', { level: 3 }),
                  }"
                  v-b-tooltip.hover
                  title="Subtítulo"
                >
                  <b> H3 </b>
                </b-button>
                <b-button
                  text
                  icon
                  @click="editor.chain().focus().toggleBold().run()"
                  :class="{ 'is-active': editor.isActive('bold') }"
                  v-b-tooltip.hover
                  title="Negrito"
                >
                  <i class="fas fa-bold"></i>
                </b-button>
                <b-button
                  text
                  icon
                  @click="editor.chain().focus().toggleItalic().run()"
                  :class="{ 'is-active': editor.isActive('italic') }"
                  v-b-tooltip.hover
                  title="Itálico"
                >
                  <i class="fas fa-italic"></i>
                </b-button>
                <b-button
                  text
                  icon
                  @click="editor.chain().focus().toggleUnderline().run()"
                  :class="{ 'is-active': editor.isActive('underline') }"
                  v-b-tooltip.hover
                  title="Sublinhado"
                >
                  <i class="fas fa-underline"></i>
                </b-button>
                <b-button
                  text
                  icon
                  @click="setLink"
                  v-b-tooltip.hover
                  title="Inserir link"
                >
                  <i class="fas fa-link"></i>
                </b-button>
                <b-button
                  @click="editor.chain().focus().unsetLink().run()"
                  :disabled="!editor.isActive('link')"
                  v-b-tooltip.hover
                  title="Remover link"
                >
                  <i class="fas fa-unlink"></i>
                </b-button>
                <b-button
                  class="menubar__button"
                  @click="editor.chain().focus().toggleBulletList().run()"
                  :class="{ 'is-active': editor.isActive('bulletList') }"
                  v-b-tooltip.hover
                  title="Lista não ordenada"
                >
                  <i class="fas fa-list-ul"></i>
                </b-button>

                <b-button
                  class="menubar__button"
                  @click="editor.chain().focus().toggleOrderedList().run()"
                  :class="{ 'is-active': editor.isActive('orderedList') }"
                  v-b-tooltip.hover
                  title="Lista ordenada"
                >
                  <i class="fas fa-list-ol"></i>
                </b-button>

                <b-button
                  class="menubar__button"
                  @click="editor.chain().focus().toggleBlockquote().run()"
                  :class="{ 'is-active': editor.isActive('blockquote') }"
                  v-b-tooltip.hover
                  title="Citação"
                >
                  <i class="fas fa-quote-right"></i>
                </b-button>
                <b-button
                  class="menubar__button"
                  @click="editor.chain().focus().setHorizontalRule().run()"
                  v-b-tooltip.hover
                  title="Linha horizontal"
                >
                  <b>---</b>
                </b-button>

                <b-button
                  class="menubar__button"
                  @click="editor.chain().focus().undo().run()"
                  v-b-tooltip.hover
                  title="Desfazer"
                >
                  <i class="fas fa-undo"></i>
                </b-button>

                <b-button
                  class="menubar__button"
                  @click="editor.chain().focus().redo().run()"
                  v-b-tooltip.hover
                  title="Refazer"
                >
                  <i class="fas fa-redo"></i>
                </b-button>

                <b-button
                  text
                  icon
                  @click="openUploadModal()"
                  v-b-tooltip.hover
                  title="Inserir imagem"
                >
                  <i class="far fa-file-image"></i>
                </b-button>
              </div>
            </div>
          </div>
          <div>
            <b-col>
              <editor-content class="editor-box" :editor="editor" />
            </b-col>
          </div>
          <div class="edit-wrap">
            <div class="col-md-6" style="display: grid">
              <div style="display: flex">
                <span class="required-mark">* </span
                ><label class="form-label" for="date">Data da publicação</label>
              </div>
              <date-picker
                id="date"
                valueType="format"
                v-model="publication.date"
                :input-attr="{ required: 'true' }"
                type="datetime"
              ></date-picker>
            </div>
            <div class="col-md-6">
              <label class="form-label" for="attachment"
                >Anexos da publicação</label
              >
              <b-form-file
                id="attachment"
                multiple
                placeholder="Arraste os arquivos ou clique"
                v-model="attachments"
                @change="attachUpload"
              ></b-form-file>
            </div>
          </div>
          <div
            v-if="editMode && publication.PublicationAttachments.length > 0"
            class="edit-wrap"
            style="margin-bottom: 0"
          >
            <div class="col-md-12">
              <small><b>Clique e arraste para alterar a posição</b></small>
            </div>
          </div>
          <div class="edit-wrap">
            <div class="col-md-12">
              <draggable
                v-model="publication.PublicationAttachments"
                ghost-class="ghost"
                @end="onEndAttach"
              >
                <transition-group type="transition" name="flip-list">
                  <div
                    class="sortable"
                    v-for="attach in publication.PublicationAttachments"
                    :key="attach.url"
                  >
                    <div class="attach-move-warp">
                      <i class="far fa-file-pdf fa-2x"></i
                      ><span>{{ attach.name }}</span>
                    </div>
                    <div>
                      <b-button
                        :disabled="buttonDisabled"
                        @click="removeAttach(attach)"
                        title="Remover arquivo"
                        variant="outline-danger"
                        ><i class="far fa-trash-alt remove-icon"></i
                      ></b-button>
                    </div>
                  </div>
                </transition-group>
              </draggable>
            </div>
          </div>
          <div class="upload-buttons col-md-12">
            <b-button type="submit" variant="primary">Enviar</b-button>
          </div>
        </b-form-group>
      </b-form>
      <b-modal
        ref="response-modal"
        footer-bg-variant="light"
        ok-only
        title="Sessão Expirada"
        @hide="emitUnauthorized"
      >
        Efetue login novamente
      </b-modal>
      <b-modal
        ref="upload-modal"
        footer-bg-variant="light"
        ok-only
        title="Arraste uma imagem"
        @ok="addImage"
      >
        <b-form-file
          v-model="bodyImg"
          placeholder="Arraste ou escolha a imagem"
        >
        </b-form-file>
      </b-modal>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import Upload from "../../services/UploadService";
import PublicationService from "../../services/PublicationService";
import PublicationImgService from "../../services/PublicationImgService";
import PublicationAttachmentService from "../../services/PublicationAttachmentService";
import PublicationTypeService from "../../services/PublicationTypeService";
import UserService from "../../services/UserService";
import TagService from "../../services/TagService";
import AuthenticationService from "../../services/AuthenticationService";
import FooterPref from "../../components/FooterPref.vue";
import NavBarPref from "../../components/NavBarPref.vue";
import { Editor, EditorContent } from "@tiptap/vue-2";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pt-br";
import "vue2-datepicker/index.css";
import moment from "moment";
import _ from "lodash";
import draggable from "vuedraggable";
import GalleryImgService from "../../services/GalleryImgService";

import Link from "@tiptap/extension-link";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Underline from "@tiptap/extension-underline";

import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
    };
  },
});

export default {
  data() {
    return {
      file: null,
      audioFile: null,
      attachments: [],
      publicationTypes: [],
      users: [],
      imgsCarousel: [],
      publication: {
        title: "",
        body: "",
        user_id: "",
        publication_type_id: "1",
        template_id: "1",
        featured: false,
        date: "",
        tags: [],
      },
      video_url: "",
      publication_id: 0,
      tags: [],
      mainImg: "",
      editMode: false,
      user: "",
      radioSelected: "img",
      oldIndex: "",
      newIndex: "",
      uploadedImgs: [],
      imgsPosition: [],
      attachsPoisition: [],
      attachIndex: "",
      buttonDisabled: false,
      imgIndex: 0,
      bodyImg: "",
      bodyImgs: [],
      imageCmd: "",
      editor: "",
    };
  },
  components: {
    EditorContent,
    Multiselect,
    NavBarPref,
    FooterPref,
    DatePicker,
    draggable,
  },
  async beforeCreate() {
    try {
      const validLogin = await AuthenticationService.validateLogin();
      console.log(validLogin.status);
    } catch (error) {
      if (error.response.status == 401) {
        this.$refs["response-modal"].show();
      }
    }
  },
  async created() {
    const user = await UserService.listById(localStorage.getItem("userId"));
    this.user = user.data;
    const types = await PublicationTypeService.list();
    if (this.user.role.name == "Admin") {
      const userList = await UserService.list();
      this.users = userList.data;
      this.publicationTypes = types.data;
    } else {
      this.users.push(this.user);
      this.publicationTypes = _.intersectionBy(
        types.data,
        this.user.permissions,
        "name"
      );
      if (this.publicationTypes.length == 1)
        this.publication.publication_type = this.publicationTypes[0];
      this.publication.user_id = this.user.id;
    }
    if (this.$route.params.id != "nova") {
      this.editMode = true;
      await this.setPublication();
    } else {
      this.publication.date = moment().format("YYYY-MM-DD HH:mm:ss");
    }
    const tags = await TagService.list();
    this.tags = tags.data;
  },
  mounted() {
    this.editor = new Editor({
      content: ``,
      extensions: [
        StarterKit,
        Link.configure({
          openOnClick: false,
        }),
        Image,
        Underline,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        CustomTableCell,
      ],
    });
  },
  methods: {
    clearFile() {
      this.file = null;
      this.publication.title = "";
      this.publication.body = "";
      this.publication.featured = false;
      this.publication.meta_description = "";
      this.attachments = [];
    },
    async setPublication() {
      const foundPub = await PublicationService.listById(this.$route.params.id);
      this.publication = foundPub.data;
      this.setEditor();
      this.publication.PublicationImgs.forEach((img) => {
        if (img.main) {
          this.mainImg = img;
        } else {
          this.uploadedImgs.push(img);
          this.imgsPosition.push(img);
        }
        return;
      });
      this.publication.PublicationAttachments = _.orderBy(
        this.publication.PublicationAttachments,
        ["position", "desc"]
      );
      foundPub.data.PublicationAttachments.map((attach) => {
        this.attachsPoisition.push(attach);
      });
      this.uploadedImgs = _.orderBy(this.uploadedImgs, ["position", "desc"]);
      this.imgsPosition = _.orderBy(this.imgsPosition, ["position", "desc"]);
      this.publication.date = moment(this.publication.date).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    uploadFile(file = null) {
      const formData = new FormData();
      formData.append(
        "publication_id",
        this.editMode ? this.publication.id : this.publication_id
      );
      if (file) {
        formData.append("file", file);
        if (this.mainImg.description)
          formData.append("description", this.mainImg.description);
      }
      if (this.video_url) {
        formData.append("video_url", this.video_url);
        formData.append(
          "thumb",
          `https://img.youtube.com/vi/${this.$youtube.getIdFromUrl(
            this.video_url
          )}/maxresdefault.jpg`
        );
      }
      formData.append("main", true);
      Upload.store(formData).then(() => {});
    },
    uploadFiles(file, type) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "publication_id",
        this.editMode ? this.publication.id : this.publication_id
      );
      switch (type) {
        case "anexo":
          this.attachIndex = this.attachsPoisition.length;
          formData.append("position", this.attachIndex);
          Upload.storeAttachment(formData).then((res) => {
            if (this.editMode) {
              this.attachsPoisition.push(res.data);
              this.publication.PublicationAttachments.push(res.data);
              res.data.position = this.attachIndex;
              PublicationAttachmentService.update(res.data).then(() => {});
              this.attachIndex++;
            }
          });
          break;
        case "imagem":
          this.imgIndex = this.uploadedImgs.length;
          formData.append("position", this.uploadedImgs.length);
          Upload.store(formData).then((res) => {
            if (this.editMode) {
              this.uploadedImgs.push(res.data);
              this.imgsPosition.push(res.data);

              res.data.position = this.imgIndex;
              PublicationImgService.update(res.data).then(() => {});
              this.imgIndex++;
            }
          });
          break;
        default:
          Upload.storeAttachment(formData).then(() => {});
          break;
      }
    },
    uploadAudio() {
      if (this.audioFile) {
        const audioFormData = new FormData();
        audioFormData.append(
          "publication_id",
          this.editMode ? this.publication.id : this.publication_id
        );
        audioFormData.append("audio", this.audioFile);
        Upload.storeAudio(audioFormData).then(() => {});
      }
    },
    send() {
      try {
        this.publication.body = this.editor.getHTML();
        this.publication.publication_type_id =
          this.publication.publication_type.id;
        if (this.editMode) {
          PublicationService.update(this.publication)
            .then((updated) => {
              PublicationImgService.update({
                id: this.mainImg.id,
                description: this.mainImg.description,
              }).then(() => {
                this.setMainAndAttach();
                this.setBoddyImgs();
                window.alert("Alterado com sucesso");
                setTimeout(() => {
                  this.goToDashboard(updated.data);
                }, 600);
              });
            })
            .catch((err) => {
              if (err.response && err.response.status == 401) {
                this.$refs["response-modal"].show();
              } else {
                console.log(err);
                window.alert("Erro ao salvar publicação");
              }
            });
        } else {
          PublicationService.store(this.publication)
            .then((res) => {
              let createdPubli = res.data;
              this.publication_id = createdPubli.id;
              if (this.imgsCarousel.length > 0) {
                this.imgsCarousel.map((img) => {
                  this.uploadFiles(img, "imagem");
                });
              }
              this.setMainAndAttach();
              this.setBoddyImgs();
              window.alert("Cadastrado com sucesso");
              setTimeout(() => {
                this.goToDashboard(createdPubli);
              }, 600);
            })
            .catch((err) => {
              if (err.response && err.response.status == 401) {
                this.$refs["response-modal"].show();
              } else {
                console.log(err);
                window.alert("Erro ao salvar publicação");
              }
            });
        }
      } catch (e) {
        console.log(e);
        window.alert("Erro ao salvar publicação");
      }
    },
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      if (url === null) {
        return;
      }

      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
    setMainAndAttach() {
      if (this.file || this.video_url) this.uploadFile(this.file);
      if (this.audioFile) this.uploadAudio();
      if (this.attachments.length > 0 && !this.editMode) {
        this.attachments.map((attachment) => {
          this.uploadFiles(attachment, "anexo");
        });
      }
    },
    setEditor() {
      PublicationService.listById(this.$route.params.id).then((res) => {
        this.editor.commands.setContent(res.data.body);
      });
    },
    goToDashboard() {
      this.$router.go(-1);
    },
    emitUnauthorized() {
      this.$root.$emit("unauthorized");
    },
    setPositions(arr, isImg) {
      if (isImg) {
        arr.map((img, index) => {
          img.position = index;
          PublicationImgService.update(img).then(() => {});
        });
      } else {
        arr.map((attach, index) => {
          attach.position = index;
          PublicationAttachmentService.update(attach).then(() => {});
        });
      }
    },
    onEnd(evt) {
      this.oldIndex = evt.oldIndex;
      this.newIndex = evt.newIndex;
      let next = this.imgsPosition;
      let moved = next.splice(this.oldIndex, 1);
      next.splice(this.newIndex, 0, moved[0]);
      this.setPositions(next, true);
    },
    onEndAttach(evt) {
      this.oldIndex = evt.oldIndex;
      this.newIndex = evt.newIndex;
      let next = this.attachsPoisition;
      let moved = next.splice(this.oldIndex, 1);
      next.splice(this.newIndex, 0, moved[0]);
      this.setPositions(next, false);
    },
    removeImg(img) {
      this.buttonDisabled = true;
      PublicationImgService.delete(img.id).then(() => {
        this.buttonDisabled = false;
        let indexPosition = this.imgsPosition.indexOf(img);
        if (indexPosition !== -1) {
          this.imgsPosition.splice(indexPosition, 1);
        }
        let index = this.uploadedImgs.indexOf(img);
        if (index !== -1) {
          this.uploadedImgs.splice(index, 1);
        }
        this.uploadedImgs.map((img, index) => {
          img.position = index;
          PublicationImgService.update(img).then(() => {});
        });
      });
    },
    removeAttach(attach) {
      this.buttonDisabled = true;
      PublicationAttachmentService.delete(attach.id).then(() => {
        this.buttonDisabled = false;
        let indexPosition = this.attachsPoisition.indexOf(attach);
        if (indexPosition !== -1) {
          this.attachsPoisition.splice(indexPosition, 1);
        }
        let index = this.publication.PublicationAttachments.indexOf(attach);
        if (index !== -1) {
          this.publication.PublicationAttachments.splice(index, 1);
        }
        this.publication.PublicationAttachments.map((attach, index) => {
          attach.position = index;
          PublicationAttachmentService.update(attach).then(() => {});
        });
      });
    },
    carrouselUpload(e) {
      if (this.editMode) {
        let fileList = Array.from(e.target.files);
        for (const img of fileList) {
          this.uploadFiles(img, "imagem");
        }
      }
    },
    attachUpload(e) {
      if (this.editMode) {
        let fileList = Array.from(e.target.files);
        for (const attach of fileList) {
          this.uploadFiles(attach, "anexo");
        }
      }
    },
    addImage() {
      const formData = new FormData();
      formData.append("file", this.bodyImg);
      Upload.store(formData).then((res) => {
        this.bodyImgs.push(res.data);
        const src = res.data.url;
        if (src !== null) {
          console.log(src);
          this.editor.chain().focus().setImage({ src }).run();
        }
      });
    },
    openUploadModal() {
      this.$refs["upload-modal"].show();
    },
    setBoddyImgs() {
      if (this.bodyImgs.length > 0) {
        this.bodyImgs.forEach((img) => {
          const galleryImg = {
            id: img.id,
            publication_id: this.publication_id
              ? this.publication_id
              : this.publication.id,
          };
          GalleryImgService.update(galleryImg);
        });
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.upload-publication-main {
  min-height: 100vh;
  :disabled {
    cursor: not-allowed;
  }
  .publication-edit-header {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 85%;
    .back-button {
      i {
        margin-right: 5px;
      }
    }
  }
  .publication-edit-title {
    text-align: initial;
    font-family: Ubuntu;
    font-weight: bold;
    color: #707070;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .sortable-drag {
    opacity: 0;
  }
  .sortable {
    cursor: move;
    margin-bottom: 10px;
    background-color: #fbfbfb;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    span {
      font-weight: bold;
    }
    i {
      margin-right: 5px;
    }
    .remove-icon {
      margin-right: 0;
    }
    .drag-wrap {
      display: flex;
      align-items: center;
    }
    img {
      height: 150px;
      width: 180px;
      margin-right: 10px;
      object-fit: cover;
      @media (max-width: 769px) {
        height: 100px;
        width: 130px;
      }
    }
    button {
      margin-right: 10px;
    }
  }
  .attach-move-warp {
    display: flex;
    align-items: center;
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .ghost {
    border-left: 6px solid #204991;
    box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.14);
    opacity: 0.7;
  }
  .form-publication {
    .form-label {
      font-weight: bold;
      margin-bottom: 0;
      font-size: 15px;
      color: #929292;
    }
    .edit-wrap {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      text-align: initial;
      @media (max-width: 768px) {
        display: grid;
        margin-bottom: 10px;
        .form-label {
          margin-top: 10px;
        }
      }
      .required-mark {
        color: #fc2c03;
      }
      .upload-radio {
        display: flex;
        div:first-of-type {
          margin-right: 15px;
        }
      }
    }
    .sticky-btn {
      position: sticky;
      top: 5px;
      z-index: 1;
      .editor-buttons {
        margin-top: 20px;
        margin-bottom: 3px;
        button {
          margin-right: 1px;
        }
      }
    }
    .col {
      margin-bottom: 10px;
    }
    .editor-box > * {
      border-color: grey;
      border-style: solid;
      border-width: 1px;
      min-height: 100px;
      text-align: initial;
      word-break: break-word;
      padding-left: 10px;
      padding-right: 10px;
      .ProseMirror {
        padding: 5px;
      }

      img {
        display: block;
        margin-bottom: 30px;
        max-height: 600px;
        margin: auto;
        max-width: 100%;
        margin-bottom: 5px;
        width: inherit;
        @media (max-width: 767px) {
          max-height: 300px;
        }
      }
      table {
        border-collapse: collapse;
        width: 100%;
        margin: 0;
        overflow: hidden;

        td,
        th {
          min-width: 1em;
          border: 2px solid #ced4da;
          padding: 3px 5px;
          vertical-align: top;
          box-sizing: border-box;
          position: relative;

          > * {
            margin-bottom: 0;
          }
        }

        th {
          font-weight: bold;
          text-align: left;
          background-color: #f1f3f5;
        }

        .selectedCell:after {
          z-index: 2;
          position: absolute;
          content: "";
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(200, 200, 255, 0.4);
          pointer-events: none;
        }

        .column-resize-handle {
          position: absolute;
          right: -2px;
          top: 0;
          bottom: -2px;
          width: 4px;
          background-color: #adf;
          pointer-events: none;
        }

        p {
          margin: 0;
        }
      }
    }
    .tableWrapper {
      overflow-x: auto;
    }

    .resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }
    .is-active {
      border-color: grey;
      border-style: solid;
      border-width: 1px;
    }
    .custom-checkbox {
      text-align: initial;
    }
    .upload-buttons {
      text-align: initial;
      margin-top: 30px;
      .btn {
        margin-right: 10px;
      }
    }
    .date-upload {
      text-align: initial;
      display: grid;
      .mx-datepicker {
        width: 100%;
      }
    }
    .mx-datepicker {
      width: 100%;
    }
    blockquote {
      border-left: 5px solid #ccc;
      margin: 1.5em 10px;
      padding: 0.5em 10px;
      quotes: "\201C""\201D""\2018""\2019";
    }
    blockquote p {
      display: inline;
    }
    .is-active {
      background-color: #545b62;
    }
  }
}
</style>