import 'core-js';
import { http } from './config'

export default {

    update:(attach) => {
        return http.put(`publication-attachments-update/${attach.id}`, {
            ...attach
        });
    },

    delete:(id) => {
        return http.delete(`publication-attachments/${id}`);
    },

}