import 'core-js';
import { http } from './config'

export default {

    login:(email, password) => {
        return http.post('users/authenticate', {
            email,
            password
        });
    },

    createSession:(userName, user_id) => {
        return http.post('sessions', {
            userName,
            user_id
        });
    },

    removeSession:(user_id) => {
        return http.delete(`sessions/${user_id}`);
    },

    validateLogin:() => {
        return http.get(`sessions/validate`);
    },
}