<template>
  <div class="gallery-view-container">
    <nav-bar-pref></nav-bar-pref>
    <b-container>
      <b-col>
        <h1>Galeria de Fotos - {{ gallery.title }}</h1>
      </b-col>
      <b-col>
        <span>{{ gallery.description }}</span>
      </b-col>
      <b-col>
        <CoolLightBox
          v-if="items.length > 0"
          :items="items"
          :index="index"
          @close="index = null"
        >
        </CoolLightBox>
        <div class="images-wrapper">
          <div
            class="image"
            v-for="(image, imageIndex) in gallery.GalleryImgs"
            :key="imageIndex"
            @click="index = imageIndex"
            :style="{ backgroundImage: 'url(' + image.url + ')' }"
          ></div>
        </div>
      </b-col>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../../../components/FooterPref";
import NavBarPref from "../../../components/NavBarPref";
import GalleryService from "../../../services/GalleryService";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import _ from "lodash";

export default {
  data() {
    return {
      gallery: "",
      index: null,
      items: [],
    };
  },
  components: {
    FooterPref,
    NavBarPref,
    CoolLightBox,
  },
  async created() {
    GalleryService.listById(this.$route.params.id).then((res) => {
      this.gallery = res.data;
      this.gallery.GalleryImgs = _.orderBy(this.gallery.GalleryImgs, ["position", "desc"]);
      this.gallery.GalleryImgs.map((img) => {
        this.items.push(img.url);
      });
    });
  },
  methods: {},
};
</script>
<style lang="scss">
.gallery-view-container {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Raleway;
  .container {
    text-align: initial;
    span {
      font-size: 1.25rem;
      color: #555;
    }
  }
  h1 {
    color: black;
    font-weight: bold;
    margin-top: 50px;
  }
  .images-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    .image {
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
    }
  }
}
</style>