import 'core-js';
import { http } from './config'

export default {
    store:(tag) => {
        return http.post('publication-tags', {
            ...tag
        });
    },

    list:() => {
        return http.get('publication-tags');
    },

    listById:(id) => {
        return http.get(`publication-tags/${id}`);
    },

    listPaginate:(params) => {
        return http.get('publication-tags-paginate', {
            params
        });
    },

    listByName:(name) => {
        return http.get(`publication-tags-name/${name}`);
    },

    update:(tag) => {
        return http.put(`publication-tags/${tag.id}`, {
            ...tag
        });
    },

    delete:(id) => {
        return http.delete(`publication-tags/${id}`);
    }

}