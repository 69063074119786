<template>
  <div id="concurso-ribas">
    <nav-bar-pref></nav-bar-pref>
    <b-container class="concurso-container">
      <b-col>
        <b-row>
          <h1>CONCURSO PÚBLICO MUNICIPAL DE PROVAS E TÍTULOS PARA PROVIMENTO DE CARGOS
            PERTENCENTES AO QUADRO EFETIVO DE PESSOAL DA PREFEITURA MUNICIPAL DE RIBAS DO
            RIO PARDO/MS</h1>
        </b-row>

        <b-row>
          <hr class="concurso-separator" />
        </b-row>

        <b-row>
          <h4><b>EDITAIS:</b></h4>
        </b-row>

        <b-row>
          <span>
            Confira os editais lançados: <a href="https://concurso.fapec.org/single-edital.php?new_id=199"><b>clique
                aqui.</b></a>
          </span>
        </b-row>

        <b-row>
          <hr class="concurso-separator" />
        </b-row>

        <b-row>
          <h4><b>INSCRIÇÕES:</b></h4>
        </b-row>

        <b-row>
          <span>
            Acesse o site da Fapec e realize sua inscrição, <a href="https://concurso.fapec.org/inscricoes.php"><b>clique
                aqui.</b></a>
          </span>
        </b-row>

        <b-row>
          <hr class="concurso-separator" />
        </b-row>

        <b-row>
          <h4><b>LEGISLAÇÕES:</b></h4>
        </b-row>
        <br />

        <b-row>
          <span><b>LEI ORGÂNICA DO MUNICÍPIO</b></span>
        </b-row>

        <b-row>
          <b-col md="22" lg="22" sm="12">
            <ul class="doc-container">
              <li class="diribas-item hvr-shadow" v-for="lom in lomList" :key="lom.name" @click="openFile(lom.url)">
                <p>Clique para visualizar o documento</p>
                <div class="file">
                  <i class="far fa-file-pdf fa-3x"></i>
                  <div class="file-information">
                    <p>{{ lom.name }}</p>
                  </div>
                </div>
              </li>
            </ul>
            <br />
            <br />
          </b-col>
        </b-row>

        <b-row>
          <span><b>CÓDIGO TRIBUTÁRIO</b></span>
        </b-row>

        <b-row>
          <b-col md="22" lg="22" sm="12">
            <ul class="doc-container">
              <li class="diribas-item hvr-shadow" v-for="tbt in tbtList" :key="tbt.name" @click="openFile(tbt.url)">
                <p>Clique para visualizar o documento</p>
                <div class="file">
                  <i class="far fa-file-pdf fa-3x"></i>
                  <div class="file-information">
                    <p>{{ tbt.name }}</p>
                  </div>
                </div>
              </li>
            </ul>
            <br />
            <br />
          </b-col>
        </b-row>

        <b-row>
          <span><b>ESTATUTO DO MAGISTÉRIO</b></span>
        </b-row>

        <b-col md="22" lg="22 " sm="12">
          <ul class="doc-container">
            <li class="diribas-item hvr-shadow" v-for="mag in magList" :key="mag.name" @click="openFile(mag.url)">
              <p>Clique para visualizar o documento</p>
              <div class="file">
                <i class="far fa-file-pdf fa-3x"></i>
                <div class="file-information">
                  <p>{{ mag.name }}</p>
                </div>
              </div>
            </li>
          </ul>
          <br />
          <br />
        </b-col>

        <b-row>
          <span><b>ESTATUTO DOS SERVIDORES PÚBLICOS</b></span>
        </b-row>

        <b-col md="22" lg="22 " sm="12">
          <ul class="doc-container">
            <li class="diribas-item hvr-shadow" v-for="serv in servList" :key="serv.name" @click="openFile(serv.url)">
              <p>Clique para visualizar o documento</p>
              <div class="file">
                <i class="far fa-file-pdf fa-3x"></i>
                <div class="file-information">
                  <p>{{ serv.name }}</p>
                </div>
              </div>
            </li>
          </ul>
          <br />
          <br />
        </b-col>

        <b-row>
          <span><b>LEGISLAÇÃO EDUCAÇÃO</b></span>
        </b-row>

        <b-col md="22" lg="22 " sm="12">
          <ul class="doc-container">
            <li class="diribas-item hvr-shadow" v-for="edu in eduList" :key="edu.name" @click="openFile(edu.url)">
              <p>Clique para visualizar o documento</p>
              <div class="file">
                <i class="far fa-file-pdf fa-3x"></i>
                <div class="file-information">
                  <p>{{ edu.name }}</p>
                </div>
              </div>
            </li>
          </ul>
          <br />
          <br />
        </b-col>

        <b-row>
          <span><b>PLANO DE CARGOS E CARREIRAS</b></span>
        </b-row>

        <b-col md="22" lg="22 " sm="12">
          <ul class="doc-container">
            <li class="diribas-item hvr-shadow" v-for="pcc in pccList" :key="pcc.name" @click="openFile(pcc.url)">
              <p>Clique para visualizar o documento</p>
              <div class="file">
                <i class="far fa-file-pdf fa-3x"></i>
                <div class="file-information">
                  <p>{{ pcc.name }}</p>
                </div>
              </div>
            </li>
          </ul>
          <br />
          <br />
        </b-col>

        <b-row>
          <span><b>PLANO DIRETOR</b></span>
        </b-row>

        <b-col md="22" lg="22 " sm="12">
          <ul class="doc-container">
            <li class="diribas-item hvr-shadow" v-for="pd in pdList" :key="pd.name" @click="openFile(pd.url)">
              <p>Clique para visualizar o documento</p>
              <div class="file">
                <i class="far fa-file-pdf fa-3x"></i>
                <div class="file-information">
                  <p>{{ pd.name }}</p>
                </div>
              </div>
            </li>
          </ul>
          <br />
          <br />
        </b-col>

        <b-row>
          <span><b>SISTEMA MUNICIPAL DE ASSISTENCIA SOCIAL</b></span>
        </b-row>

        <b-col md="22" lg="22 " sm="12">
          <ul class="doc-container">
            <li class="diribas-item hvr-shadow" v-for="sas in sasList" :key="sas.name" @click="openFile(sas.url)">
              <p>Clique para visualizar o documento</p>
              <div class="file">
                <i class="far fa-file-pdf fa-3x"></i>
                <div class="file-information">
                  <p>{{ sas.name }}</p>
                </div>
              </div>
            </li>
          </ul>
          <br />
          <br />
        </b-col>


      </b-col>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../../components/FooterPref.vue";
import NavBarPref from "../../components/NavBarPref.vue";
export default {
  data() {
    return {
      lomList: [
        {
          name: "LEI ORGÂNICA DO MUNICÍPIO",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LOM.pdf",
        },
      ],
      tbtList: [
        {
          name: "LEI COMPLEMENTAR  006 - COD. TRIBUTARIO (Dispõe sistema Tributário Mun. e as normas gerais de Direito Trib. Aplicável.)",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR++006+-+COD.+TRIBUTARIO+(+Dsp%C3%B5e+sistema+Tribut%C3%A1rio+Mun.+e+as+normas+gerais+de+Direito+Trib.+Aplic%C3%A1vel.+).pdf",
        },
        {
          name: "LEI COMPLEMENTAR  007 - Dispõe sobre alterações da Lei Complementar no. 0062010 de 28122010 que instituiu o Código Tributário Municipal",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR++007+-+Disp%C3%B5e+sobre+altera%C3%A7%C3%B5es+da+Lei+Complementar+no.+0062010+de+28122010+que+instituiu+o+C%C3%B3digo+Tribut%C3%A1rio+Municipal.docx.pdf",
        },
        {
          name: "LEI COMPLEMENTAR  008 - Dispõe sobre alterações da Lei Complementar no. 0062010 de 28122010 que instituiu o Código Tributário Municipal",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR++008+-+Disp%C3%B5e+sobre+altera%C3%A7%C3%B5es+da+Lei+Complementar+no.+0062010+de+28122010+que+instituiu+o+C%C3%B3digo+Tribut%C3%A1rio+Municipal.docx.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 016 - ALTERA LEI COMP. 006-10 - CÓDIGO TRIBUTÁRIO",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+016+-+ALTERA+LEI+COMP.+006-10+-+C%C3%93DIGO+TRIBUT%C3%81RIO.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 036 - ALTERAÇÃO DO CÓDIGO TRIBUTÁRIO",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+036+-+ALTERA%C3%87%C3%83O+DO+C%C3%93DIGO+TRIBUT%C3%81RIO.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 043 - ALTERA CÓDIGO TRIBUTÁRIO",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+043+-+ALTERA+C%C3%93DIGO+TRIBUT%C3%81RIO.docx.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 059 - ALTERAÇÃO CÓDIGO TRIBUTÁRIO",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+059+-+ALTERA%C3%87%C3%83O+C%C3%93DIGO+TRIBUT%C3%81RIO.pdf",
        },
        {
          name: "LEI MUNICIPAL 970 - ALTER. LEI 006-2010 (Institui o Código Tributário Municipal)",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+MUNICIPAL+970+-+ALTER.+LEI+006-2010+(+Institui+o+C%C3%B3digo+Tribut%C3%A1rio+Municipal+).pdf",
        },
      ],
      magList: [
        {
          name: "LEI COMPLEMENTAR 040 - 2018",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+040+-+2018.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 045 - ALTERA 976",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+045+-+ALTERA+976.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 069 - AMPLIA VAGAS",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+069+-+AMPLIA+VAGAS.pdf",
        },
        {
          name: "LEI MUNCIPAL 1101 - ALTERA 976",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+MUNCIPAL+1101+-+ALTERA+976.pdf",
        },
        {
          name: "LEI MUNICIPAL 976-2011 -ESTATUTO DOS PROFESSORES",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+MUNICIPAL+976-2011+-ESTATUTO+DOS+PROFESSORES.pdf",
        },
        {
          name: "LEI MUNICIPAL 1144 - 2019",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+MUNICIPAL+1144+-+2019.PDF",
        },
        {
          name: "LEI MUNICIPAL 1172 - ALTERA 976",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+MUNICIPAL+1172+-+ALTERA+976.pdf",
        },
      ],
      servList: [
        {
          name: "686 - Estatuto do Servidor Público Municipal de Ribas do Rio Pardo - MS",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/686+-+Estatuto+do+Servidor+P%C3%BAblico+Municipal+de+Ribas+do+Rio+Pardo+-+MS.pdf",
        },
        {
          name: "LEI COMPLEMENTAR  004 - ALTERA  ( Dispõe sob. a alteração do 3º da Lei Mun. 686-2001 ( Estatuto Servidor )",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR++004+-+ALTERA++(+Disp%C3%B5e+sob.+a+altera%C3%A7%C3%A3o+do+3%C2%BA+da+Lei+Mun.+686-2001+(+Estatuto+Servidor+).pdf",
        },
        {
          name: "LEI COMPLEMENTAR 002-2008",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+002-2008.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 017 - PLANTÕES SAÚDE",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+017+-+PLANT%C3%95ES+SA%C3%9ADE.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 064 - ALTERA 686 E 041-18",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+064+-+ALTERA+686+E+041-18.pdf",
        },
        {
          name: "LEI MUNICIPAL 1124 - 2019",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+MUNICIPAL+1124+-+2019.pdf.pdf",
        },
        {
          name: "LEI MUNICIPAL 1155 - 2019",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+MUNICIPAL+1155+-+2019.pdf",
        },
        {
          name: "LEI MUNICIPAL 1159 - 2019",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+MUNICIPAL+1159+-+2019.pdf",
        },
        {
          name: "Lei Promulgada n° 1.281, de 26 de setembro de 2022  - Estatuto",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/Lei+Promulgada+n%C2%B0+1.281%2C+de+26+de+setembro+de+2022++-+Estatuto+(3)+(2).pdf",
        },
      ],
      eduList: [
        {
          name: "DECRETO 140 - GESTÃO DEMOCRÁTICA NA REDE DE ENSINO MUNICIPAL",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/DECRETO+140+-+GEST%C3%83O+DEMOCR%C3%81TICA+NA+REDE+DE+ENSINO+MUNICIPAL.pdf",
        },
        {
          name: "LEI MUNICIPAL 1.047 - PLANO MUNICIPAL DE EDUCAÇÃO",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+MUNICIPAL+1.047+-+PLANO+MUNICIPAL+DE+EDUCA%C3%87%C3%83O.pdf",
        },
        {
          name: "LEI MUNICIPAL 1199 - DISPÕE SOBRE A REESTRUTURAÇÃO DO CONSELHO MUNICIPAL DE ACOMPANHAMENTO E CONTROLE SOCIAL DO FUNDEB",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+MUNICIPAL+1199+-+DISP%C3%95E+SOBRE+A+REESTRUTURA%C3%87%C3%83O+DO+CONSELHO+MUNICIPAL+DE+ACOMPANHAMENTO+E+CONTROLE+SOCIAL+DO+FUNDEB.pdf",
        },
        {
          name: "LEI MUNICIPAL 1212 - DISPÕE SOBRE A CRIAÇÃO DO PROGRAMA CAPACITAÇÃO EM NOÇÕES BÁSICAS  DE PRIMEIROS SOCORROS",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+MUNICIPAL+1212+-+DISP%C3%95E+SOBRE+A+CRIA%C3%87%C3%83O+DO+PROGRAMA+CAPACITA%C3%87%C3%83O+EM+NO%C3%87%C3%95ES+B%C3%81SICAS++DE+PRIMEIROS+SOCORROS.pdf",
        },
        {
          name: "LEI MUNICIPAL 1268 - CRIA O PROGRAMA DINHEIRO NA ESCOLA",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+MUNICIPAL+1268+-+CRIA+O+PROGRAMA+DINHEIRO+NA+ESCOLA.pdf",
        },
        {
          name: "LEI Nº 1285 - CRIA CARGO DE ASSISTENTE SOCIAL E PSICÓLOGO",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+N%C2%BA+1285+-+CRIA+CARGO+DE+ASSISTENTE+SOCIAL+E+PSIC%C3%93LOGO.pdf",
        },
        {
          name: "RESOLUÇÃO 019 - MATRIZ CURRICULAR 2022",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/Resolu%C3%A7%C3%A3o+019+-+Matriz+Curricular+2022.pdf",
        },
      ],
      pccList: [
        {
          name: "LEI COMPLEMENTAR 011 - 2014 - PLANO DE CARGOS E CARREIRAS",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+011+-+2014+-+PLANO+DE+CARGOS+E+CARREIRAS.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 032 - ALTERA VAGAS",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+032+-+ALTERA+VAGAS.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 033 - AMPLIA VAGAS E CRIA CARGOS",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+033+-+AMPLIA+VAGAS+E+CRIA+CARGOS.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 039 - 2018 - AMPLIA, REENQUADRA E CRIA",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+039+-+2018+-+AMPLIA%2C+REENQUADRA+E+CRIA.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 050 - GRATIFICAÇÃO MOTORISTA",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+050+-+GRATIFICA%C3%87%C3%83O+MOTORISTA.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 058 - ALTERA A LEI COMP. 038-19",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+058+-+ALTERA+A+LEI+COMP.+038-19.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 062 - AMPLIAÇÃO DE VAGAS",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+062+-+AMPLIA%C3%87%C3%83O+DE+VAGAS.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 063 - CRIA ABONO PARA OS CARGOS DE OPERADORES",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+063+-+CRIA+ABONO+PARA+OS+CARGOS+DE+OPERADORES.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 064 - ALTERA 686 E 041-18",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+064+-+ALTERA+686+E+041-18.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 066 - REAJUSTE TABELA SERVIDORES",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+066+-+REAJUSTE+TABELA+SERVIDORES.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 067 - CONCEDE REAJUSTE AOS TÉCNICOS EM ENFERMAGEM",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+067+-+CONCEDE+REAJUSTE+AOS+T%C3%89CNICOS+EM+ENFERMAGEM.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 068 - AMPLIA VAGAS",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+068+-+AMPLIA+VAGAS.pdf",
        },
      ],
      pdList: [
        {
          name: "LEI COMPLEMENTAR 037 - PLANO DIRETOR PARTICIPATIVO",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+037+-+PLANO+DIRETOR+PARTICIPATIVO.pdf",
        },
        {
          name: "LEI COMPLEMENTAR 057 - ALTERA PLANO DIRETOR",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+COMPLEMENTAR+057+-+ALTERA+PLANO+DIRETOR.pdf",
        },
        {
          name: "Anexo I - Áreas de Preservação Permanente",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/Anexo+I+-+%C3%81reas+de+Preserva%C3%A7%C3%A3o+Permanente+1+(1).pdf",
        },
        {
          name: "Anexo II - Perímetro Urbano",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/Anexo+II+-+Per%C3%ADmetro+Urbano+1+(1).pdf",
        },
        {
          name: "Anexo III - Macrozoneamento",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/Anexo+III+-+Macrozoneamento+1+(1).pdf",
        },
        {
          name: "Anexo IV - Áreas Especiais de Interesse",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/Anexo+IV+-+%C3%81reas+Especiais+de+Interesse+1+(1).pdf",
        },
        {
          name: "Anexo V - Zoneamento",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/Anexo+V+-+Zoneamento+1+(1).pdf",
        },
        {
          name: "Anexo VI - Descrição dos Perímetros",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/Anexo+VI+-+Descri%C3%A7%C3%A3o+dos+Per%C3%ADmetros+1+(1).pdf",
        },
        {
          name: "Anexo VII - Tabela de Índices Urbanísticos",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/Anexo+VII+-+Tabela+de+%C3%8Dndices+Urban%C3%ADsticos+(1)+1+(1).pdf",
        },
      ],
      sasList: [
        {
          name: "LEI MUNICIPAL 1202 - DISPÕE SOBRE O SISTEMA MUNICIPAL DE ASSISTÊNCIA SOCIAL",
          url:
            "https://diribas.s3.sa-east-1.amazonaws.com/LEI+MUNICIPAL+1202+-+DISP%C3%95E+SOBRE+O+SISTEMA+MUNICIPAL+DE+ASSIST%C3%8ANCIA+SOCIAL.pdf",
        },
      ]
    };
  },
  components: {
    NavBarPref,
    FooterPref,
  },
  methods: {
    openFile(url) {
      window.open(url, "_blank");
    },
  }
};
</script>
<style lang="scss">
#concurso-ribas {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Raleway;

  .footer {
    margin-top: 20px;
  }

  .concurso-container {
    h1 {
      color: black;
      font-weight: bold;
      margin-top: 50px;
      text-align: justify;
    }

    h2 {
      color: black;
      font-weight: bold;
      margin-top: 20px;
      text-align: justify;
    }

    p {
      text-align: justify;
    }

    .text-image {
      display: flex;

      @media (max-width: 1020px) {
        display: grid;
      }

      div {
        padding: unset;
      }

      img {
        max-width: 300px;
      }
    }

    .carousel-container-concurso {
      .carousel-item {
        img {
          min-height: 450px;
          max-height: 530px;
        }
      }
    }

    .concurso-separator {
      border: 0;
      height: 1px;
      background: #ccc;
      width: 100%;
    }
  }

  .doc-container {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: unset;

    li {
      &:hover {
        cursor: pointer;
      }

      &:hover .file-information p {
        color: #204991;
      }
    }

    .diribas-item {
      flex-grow: 1;
      text-align: justify;
      margin-top: 25px;
      border-top: 1px solid rgb(185, 185, 185);
      margin-right: 1%;

      @media (max-width: 425px) {
        width: calc(100% * (1 / 1) - 10px - 1px);
      }

      width: calc(100% * (1 / 2) - 10px - 1px);

      small {
        font-weight: bold;
      }

      p {
        text-align: initial;
      }

      .file {
        display: flex;

        i {
          color: red;
        }

        p {
          margin-bottom: unset;
          text-align: initial;
        }

        .file-information {
          width: 100%;
          margin: auto auto auto 5%;

          p {
            transition: all 0.4s ease;
          }
        }
      }
    }
  }

  .footer {
    margin-top: 30px;
  }
}
</style>