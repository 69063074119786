<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },

  components: {
  },

  mounted() {},
};
</script>

<style>
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
