<template>
  <div id="Expenses">
    <nav-bar-pref></nav-bar-pref>
    <div class="main-container">
      <div
        style="width: 100%; height: 100%; overflow: hidden; margin-top: -130px"
      >
        <iframe
          src="https://web.neainformatica.com.br/transparencia/publico/linkList.xhtml?cliente=pmrrp&grupo=1748"
          width="100%"
          height="100%"
          style="border: none"
        >
        </iframe>
      </div>
    </div>
    <footer-pref></footer-pref>
  </div>
</template>

<script>
import FooterPref from "../../components/FooterPref";
import NavBarPref from "../../components/NavBarPref";

export default {
  name: "Expenses",
  data() {
    return {};
  },

  components: {
    FooterPref,
    NavBarPref,
  },

  mounted() {},
};
</script>

<style lang="scss">
#Expenses {
  width: 100%;
  height: 95%;

  .main-container {
    width: 100%;
    height: 100%;
  } 
}
</style>