var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-dashboard",class:_vm.collapsed ? 'collapsed' : 'non-collapsed'},[_c('nav-bar-dashboard'),_c('sidebar-dashboard'),_c('b-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"dash-title"},[_c('h1',[_vm._v("RELATÓRIOS - EDITAIS")])]),_c('div',{staticClass:"bg-dash",style:(_vm.collapsed ? 'display: none' : 'display: inherit')}),_c('div',{staticClass:"dash-cards-wrap"},[_c('div',{staticClass:"dash-card edict-report-cards col-md-4"},[_c('div',{staticClass:"text-wrap-dash-edict"},[_c('span',{staticClass:"dash-card-title"},[_vm._v("Total de Visualizações")]),_c('animated-number',{staticClass:"edict-count",attrs:{"value":_vm.totalData.total,"formatValue":_vm.formatNumber,"duration":800}})],1)]),_c('div',{staticClass:"dash-card edict-report-cards col-md-4"},[_c('div',{staticClass:"text-wrap-dash-edict"},[_c('span',{staticClass:"dash-card-title"},[_vm._v("Total Pessoa Física")]),_c('animated-number',{staticClass:"edict-count",attrs:{"value":_vm.totalData.physical,"formatValue":_vm.formatNumber,"duration":800}})],1)]),_c('div',{staticClass:"dash-card edict-report-cards col-md-4"},[_c('div',{staticClass:"text-wrap-dash-edict"},[_c('span',{staticClass:"dash-card-title"},[_vm._v("Total Pessoa Jurídica")]),_c('animated-number',{staticClass:"edict-count",attrs:{"value":_vm.totalData.legal,"formatValue":_vm.formatNumber,"duration":800}})],1)])]),_c('div',{staticClass:"dash-last-label"},[_c('span',[_vm._v(" Listagem de editais ")]),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.exportGeneralData}},[_c('i',{staticClass:"fas fa-download"}),_vm._v("Exportar dados")])],1),_c('div',{staticClass:"dash-last-pub"},[_c('vue-good-table',{staticClass:"table-bordered table-hover",attrs:{"columns":_vm.columns,"rows":_vm.publications,"pagination-options":{
          enabled: true,
          dropdownAllowAll: false,
          nextLabel: 'Próximo',
          prevLabel: 'Anterior',
          rowsPerPageLabel: 'Linhas por página',
          ofLabel: 'de',
          pageLabel: 'página',
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'actions')?_c('span',[_c('b-button',{staticClass:"edit-button",style:(_vm.collapsed ? 'margin-right: 5px;' : 'margin-right: 0;'),attrs:{"title":"Exportar planilha","variant":"primary"},on:{"click":function($event){return _vm.exportData(props.row.edict.id)}}},[_c('i',{staticClass:"fas fa-download"})])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('b-modal',{ref:"response-modal",attrs:{"footer-bg-variant":"light","ok-only":"","title":_vm.confirmMessage}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }