import 'core-js';
import { http } from './config'

export default {
    store:() => {
    },

    list:() => {
        return http.get('roles');
    },

    listById:(roleId) => {
        return http.get(`roles/${roleId}`);
    },

    update:() => {

    },

    delete:() => {
        
    }

}