import 'core-js';
import { http } from './config'

export default {
    store:(gallery) => {
        return http.post('gallery', {
            gallery
        });
    },

    list:() => {
        return http.get('gallery');
    },

    listById:(id) => {
        return http.get(`gallery/${id}`);
    },

    listPaginate:(params) => {
        return http.get('gallery-paginate', {
            params
        });
    },

    update:(gallery) => {
        return http.put(`gallery/${gallery.id}`, {
            gallery
        });
    },
    
    delete:(id) => {
        return http.delete(`gallery/${id}`);
    }

}