import axios from 'axios'
import VueCookies from 'vue-cookies'

const rememberName = VueCookies.get("remember_name");
const rememberId = VueCookies.get("remember_id");
const token = localStorage.getItem('token');
const userId = localStorage.getItem("userId");
export const http = axios.create({
    baseURL: process.env.VUE_APP_PORT_PRODUCTION
})

if (token) {
    http.defaults.headers.common['Authorization'] = `Bearer ${token}`
    http.defaults.headers.common['remember_name'] = rememberName;
    http.defaults.headers.common['remember_id'] = rememberId;
    http.defaults.headers.common['user_id'] = userId;
  }
export const weather = axios.create({
    baseURL: process.env.VUE_APP_CURRENT_WEATHER,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
}) 