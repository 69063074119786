<template>
  <div class="diribas-render-main">
    <b-row class="signature-wrapp">
      <div class="diribas-signature">
        <div class="diribas-logo">
          <img src="@/assets/brasao.png" alt="" />
        </div>
        <div class="diribas-logo">
          <span><h1>DIRIBAS</h1></span>
        </div>
        <div class="signature">
          <div class="signature-box">
            <span>Documento assinado digitalmente por Prefeitura Municipal de Ribas do Rio Pardo</span>
          </div>
        </div>
      </div>
      <div class="info-title">
        <span>Diário Oficial De Ribas Do Rio Pardo-MS</span>
      </div>
      <div class="info-sub-title">
        <span
          >Município de Ribas do Rio Pardo - Rua Conceição do Rio Pardo, 1.725
          Centro - CEP 79180-000</span
        >
      </div>
      <div class="info-list">
        <ul>
          <li><span>Ouvidoria: 67 9 9606-1175</span></li>
          <li><span>diribas@ribasdoriopardo.ms.gov.br</span></li>
          <li><span>licitacao@ribasdoriopardo.ms.gov.br</span></li>
        </ul>
      </div>
      <div class="infos">
        <span
          >Ano IV – Edição Nº {{ post.edition }} - {{ formatDate() }}
          {{ post.supplement ? " - SUPLEMENTO" : "" }}</span
        >
      </div>
    </b-row>
    <div
      class="diribas-publications"
      v-for="(publication, index) in publications"
      :key="publication.id"
    >
      <b-row style="break-inside: avoid;">
        <b-col
          :class="'department-name department-break' + (index == 0 ? ' department-margin' : '')"
        >
          <span>
            {{
              publication.sub_department
                ? publication.sub_department.name
                : publication.department.name
            }}
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="diribas-title">
          <span> {{ publication.title }} </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="diribas-text">
          <span v-html="publication.text"></span>
        </b-col>
      </b-row>
    </div>
    <div class="diribas-publications">
      <!-- <b-row>
        <b-col class="department-name">
          <span> ÚLTIMOS BOLETINS COVID-19 </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="diribas-title">
          <span></span>
        </b-col>
      </b-row>
      <b-row>
        <div class="boletim-list">
          <div
            class="boletim-item"
            v-for="boletin in boletins"
            :key="boletin.id"
          >
            <img :src="boletin.PublicationImgs[0].url" />
          </div>
        </div>
      </b-row> -->
      <b-row>
        <b-col class="department-name">
          <span> AVISOS </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="diribas-title">
          <span></span>
        </b-col>
      </b-row>
      <b-row style="break-inside: avoid;">
        <div class="notice-list">
          <div class="notice-item" v-for="img in warningImgs" :key="img">
            <img :src="img.url" />
          </div>
        </div>
      </b-row>
    </div>
  </div>
</template>
<script>
import DiribasService from "../services/DiribasService";
// import PublicationService from "../services/PublicationService";
import moment from "moment";

export default {
  data() {
    return {
      publications: [],
      post: "",
      // boletins: [],
      warningImgs: [],
    };
  },
  created() {
    DiribasService.getPostById(this.$route.params.id).then((res) => {
      this.post = res.data;
    });
    DiribasService.listPublicationsByPost(this.$route.params.id).then((res) => {
      this.publications = res.data;
    });
    // PublicationService.listBoletins().then((res) => {
    //   this.boletins = res.data.publications;
    // });
    DiribasService.findAllNotice().then((res) => {
      this.warningImgs = res.data;
    });
  },
  components: {},
  methods: {
    formatDate() {
      const supplement = this.post.supplement;
      moment.locale("pt");
      if (supplement) {
        return moment(new Date()).format("dddd, DD [de] MMMM [de] YYYY");
      }
      return moment(new Date()).format("dddd, DD [de] MMMM [de] YYYY");
    },
    formatDateHeader(date) {
      moment.locale("pt");
      return moment(date).format("DD [de] MMMM [de] YYYY");
    },
  },
};
</script>
<style lang="scss">
.diribas-render-main {
  font-family: 'EB Garamond', serif;
  color: black;
  min-height: 100vh;
  display: block;
  grid-template-rows: auto 1fr auto;
  text-align: justify;
  margin-left: 36px;
  margin-right: 36px;
  h2,
  h3,
  h4 {
    font-size: 16px;
  }
  .row {
    margin-right: 0;
    margin-left: 0;
    .diribas-header {
      margin-top: 20px;
      margin-bottom: 10px;
      border: solid 2px;
      border-radius: 5px;
      font-weight: bold;
      background-color: #c5c5c5;
      display: flex;
      align-items: center;
      span {
        margin: auto;
        font-size: 18px;
      }
    }
    .signature-wrapp {
      display: block;
    }
    .diribas-signature {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      align-items: center;
      .diribas-logo {
        text-align: initial;
        display: flex;
        h1 {
          font-weight: bold;
          font-size: 9.8rem;
        }
        img {
          max-height: 130px;
        }
      }
      .signature {
        display: grid;
        text-align: initial;
        line-height: normal;
        span {
          font-weight: bold;
          font-size: 19px;
        }
        .signature-box {
          width: 210px;
          height: 130px;
          border: solid 2px;
          padding: 5px;
          span {
            font-size: 21px;
          }
        }
      }
    }
    .info-title {
      display: flex;
      width: 100%;
      justify-content: center;
      span {
        font-variant: petite-caps;
        font-size: 56px;
        font-weight: bold;
        line-height: 1;
      }
    }
    .info-sub-title {
      font-weight: bold;
      display: flex;
      width: 100%;
      justify-content: center;
      font-size: 24px;
    }
    .info-list {
      font-weight: bold;
      width: 100%;
      ul {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        li {
          font-size: 22px;
          span {
            margin-left: 5px;
          }
        }
        li::marker {
          content: "\2B24 ";
          // font-size: 2.3em;
        }
      }
    }
    .infos {
      font-weight: bold;
      display: flex;
      width: 100%;
      justify-content: center;
      border-top: solid 2px;
      border-bottom: solid 2px;
      padding: 5px;
      font-size: 23px;
    }
    .department-name {
      margin-top: 20px;
      margin-bottom: 0;
      font-weight: bold;
      background-color: #c5c5c5;
      display: flex;
      align-items: center;
      span {
        margin: auto;
        font-size: 26px;
      }
    }
    .department-break {
      break-inside: avoid;
    }
    .department-margin {
      margin-top: 0;
    }
    .diribas-title {
      font-weight: bold;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      text-align: center;
      span {
        margin: auto;
        font-size: 22px;
      }
    }
    .diribas-text {
      line-height: normal;
      text-align: justify;
      p {
        margin-bottom: 0;
        font-size: 22px;
      }
      p:empty::after {
        content: "\00A0";
      }
      img {
        max-width: 100%;
        width: inherit;
      }
    }
    .boletim-list {
      display: flex;
      .boletim-item {
        img {
          max-width: 99%;
        }
      }
    }
    .notice-list { 
      break-inside: avoid;
      display: inline-grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      .notice-item {
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>