import 'core-js';
import { http } from './config'

export default {
    store:(publicationType) => {
        return http.post('publications', {
            publicationType
        });
    },

    list:() => {
        return http.get('publication-types');
    },

    listByName:(name) => {
        return http.get(`publication-types-name/${name}`);
    },

    update:() => {

    },

    delete:() => {
        
    }

}